import { ChangeEvent, useContext } from 'react';

import {
  EnrichmentUploadingContext,
  FileMetadata,
  ModalViewType,
} from '@/features/enrichment/ui/Enrichment/EnrichmentUploadingContext';
import { useUploadEnrichmentFile } from '@/features/enrichment/ui/Enrichment/hooks';

type UseStartViewModelResult = {
  inputRef: React.RefObject<HTMLInputElement | null>;
  currentView: ModalViewType;
  fileMetadata: Nullable<FileMetadata>;
  handleChoseFile(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
};

export const useStartViewModel = (): UseStartViewModelResult => {
  const { currentView, fileMetadata } = useContext(EnrichmentUploadingContext);
  const { onChange, onClick, inputRef } = useUploadEnrichmentFile();

  const handleChoseFile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    onClick();
  };

  return {
    currentView,
    inputRef,
    fileMetadata,
    handleChoseFile,
    handleInputChange: onChange,
  };
};
