import type {
  IIntegrationConfigEntity,
  MappedObjectType,
} from '@/features/integration/domain';

import { useProviderName } from '../../../hooks';

import { useFieldMappingForm } from './useFieldMappingForm';
import { useFieldMappingTabs } from './useFieldMappingTabs';

type UseFieldMappingViewModel = (params: {
  config: IIntegrationConfigEntity;
}) => ReturnType<typeof useFieldMappingForm> & {
  tabs: ReturnType<typeof useFieldMappingTabs>;
  objectType: MappedObjectType;
  providerName: string;
};

export const useFieldMappingViewModel: UseFieldMappingViewModel = ({ config }) => {
  const tabs = useFieldMappingTabs({ settings: config.settings });
  const form = useFieldMappingForm({ config });

  const providerName = useProviderName(config.settings.provider);

  const objectType = tabs.value;

  return {
    providerName,
    tabs,
    objectType,
    ...form,
  };
};
