import { IIntegrationEntity } from '../../domain';
import { IIntegrationsConfigDC } from '../dataContracts';

function resolveStatus(
  providerId: string,
  dc: IIntegrationsConfigDC,
): IIntegrationEntity['status'] {
  if (!dc.providers.find((provider) => provider.name === providerId)) {
    return 'not-available';
  }

  const currentIntegration = dc.integrations.find(
    (integration) => integration.provider === providerId,
  );

  if (currentIntegration?.connection_active) {
    return 'connected';
  }

  if (currentIntegration && currentIntegration.connection_active === false) {
    return 'expired';
  }

  return 'disconnected';
}

export function mapIntegrationsConfigDcToEntities(
  dc: IIntegrationsConfigDC,
  staticProvidersList: Array<
    Omit<IIntegrationEntity, 'status' | 'isConnected' | 'isAvailable' | 'isExpired'>
  >,
): IIntegrationEntity[] {
  return staticProvidersList.map((provider) => {
    const status = resolveStatus(provider.id, dc);

    return {
      id: provider.id,
      name: provider.name,
      icon: provider.icon,
      status,
      isConnected: status === 'connected',
      isExpired: status === 'expired',
      isAvailable: status !== 'not-available',
    };
  });
}
