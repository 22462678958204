import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

export const PrivacyAndTerms: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <Typography variant={'subtitle1'} className={styles.termsSection}>
      <Trans t={t} i18nKey={'privacy'}>
        Powerlead is protected by Google
        <Link to={EXTERNAL_ROUTES.PRIVACY} className={styles.linkCreate}>
          Privacy Policy
        </Link>
        and
        <Link to={EXTERNAL_ROUTES.TERMS} className={styles.linkCreate}>
          Terms of Service
        </Link>
        in minutes apply.
      </Trans>
    </Typography>
  );
};
