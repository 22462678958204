import type {
  IIntegrationMapEntity,
  IIntegrationSettingsEntity,
  IntegrationMapRowEntity,
} from '@/features/integration/domain/entities';

import type {
  IIntegrationMapperDC,
  IIntegrationSettingsDC,
  MapRowDC,
} from '../dataContracts';

const POWERLEAD_PREDEFINED_FIELD = 'predefined_value';
const POWERLEAD_PREDEFINED_FIELD_DISPLAY = 'Predefined Value';

// settings
export const mapIntegrationSettingsDCtoEntity = (
  dc: IIntegrationSettingsDC,
): IIntegrationSettingsEntity => ({
  provider: dc.provider,
  userEmail: dc.user_email,
  enabledObjects: dc.enabled_objects,
  supportedObjects: dc.supported_objects,
  connectionActive: dc.connection_active,
});

//mapping
export const mapIntegrationMapDCtoEntity = (
  dc: IIntegrationMapperDC,
): IIntegrationMapEntity => ({
  powerleadFields: {
    ...dc.powerlead_fields,
    // add virtual predefined field
    [POWERLEAD_PREDEFINED_FIELD]: {
      display: POWERLEAD_PREDEFINED_FIELD_DISPLAY,
      example: null,
    },
  },
  providerFields: dc.provider_fields,
  mapping: dc.mapping.map((row) => ({
    powerleadField: row.predefined_value
      ? POWERLEAD_PREDEFINED_FIELD
      : row.powerlead_field,
    providerField: row.provider_field,
    predefinedValue: row.predefined_value,
    allowOverride: row.allow_overwrite,
  })),
  requiredProviderFields: dc.required_provider_fields,
});

export const mapIntegrationMapEntityToDC = (
  entity: IntegrationMapRowEntity,
): MapRowDC => ({
  powerlead_field:
    entity.powerleadField === POWERLEAD_PREDEFINED_FIELD ? null : entity.powerleadField,
  provider_field: entity.providerField,
  predefined_value: entity.predefinedValue,
  allow_overwrite: entity.allowOverride,
});
