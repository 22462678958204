import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import NoResults from './assets/noResults.svg?react';

import styles from './styles.module.scss';

type NoResultsOverlayPropsType = {
  defaultContactListId: string;
};

export const NoResultsOverlay: FC<NoResultsOverlayPropsType> = ({
  defaultContactListId,
}) => {
  const { t } = useTranslation('contacts');
  const navigate = useNavigate();
  const goToDefaultList = (): void => {
    const path = ROUTES.CONTACTS_INNER.replace(':id', defaultContactListId);
    navigate(path);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.image}>
        <NoResults />
      </Box>
      <Box className={styles.title}>
        <Typography variant="h1">{t('table.noResultsOverlay.title')}</Typography>
      </Box>
      <Box className={styles.subTitle}>
        <Typography variant="subtitle1">
          <Trans
            i18nKey={'table.noResultsOverlay.subTitle'}
            ns="contacts"
            components={{ b: <b />, br: <br /> }}
          />
        </Typography>
      </Box>
      <Button className={styles.button} variant="contained" onClick={goToDefaultList}>
        {t('table.noResultsOverlay.button')}
      </Button>
    </Box>
  );
};
