import { createContext, FC } from 'react';
import { matchPath, PathMatch, useLocation, useNavigate } from 'react-router';

import { ROUTES } from '@/router/routes';

interface ILocationRestoreContext {
  onRestoreLocation(): void;
}

export const LocationRestoreContext = createContext<ILocationRestoreContext>(
  {} as ILocationRestoreContext,
);

const AVAILABLE_ROUTES_TO_REDIRECT = [ROUTES.CONTACTS_ENTRY, ROUTES.BILLING.PLANS];
const STORAGE_RESTORE_LOCATION_KEY = 'locationToRestore';

const getLocationToRestore = (): PathMatch | undefined => {
  let locationToRestore;
  for (let index = 0; index < AVAILABLE_ROUTES_TO_REDIRECT.length; index++) {
    const route = AVAILABLE_ROUTES_TO_REDIRECT[index];
    const matchResult = matchPath(route, window.location.pathname);
    if (matchResult) {
      locationToRestore = matchResult;
      break;
    }
  }
  return locationToRestore;
};

export const withLocationRestore = (Component: FC): FC => {
  const locationToRestore = getLocationToRestore();
  if (locationToRestore) {
    localStorage.setItem(STORAGE_RESTORE_LOCATION_KEY, locationToRestore.pathname);
  }

  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onRestoreLocation = (): void => {
      const storageLocationToRestore = localStorage.getItem(STORAGE_RESTORE_LOCATION_KEY);
      localStorage.removeItem(STORAGE_RESTORE_LOCATION_KEY);
      if (!storageLocationToRestore || location.pathname === storageLocationToRestore)
        return;

      navigate(storageLocationToRestore);
    };

    return (
      <LocationRestoreContext.Provider value={{ onRestoreLocation }}>
        <Component {...props} />
      </LocationRestoreContext.Provider>
    );
  };
};
