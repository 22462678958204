import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BroadcastChannel } from 'broadcast-channel';

import { ROUTES } from '@/router/routes';

import { useAccount } from '@/features/common/account';
import { useExtensionState } from '@/features/common/extension';
import { useOnboardingUseCase } from '@/features/common/onboarding';

export const useInstallExtensionPageViewModel = (): {
  name: Nullable<string>;
  onSkip: () => void;
  onInstall: () => void;
} => {
  const navigate = useNavigate();
  const { account } = useAccount();
  const onboardingUseCase = useOnboardingUseCase();

  const [bc] = useState(
    () => new BroadcastChannel<{ done: boolean }>('install-extension'),
  );

  const { isInstalled: isExtensionInstalled } = useExtensionState();

  useEffect(() => {
    onboardingUseCase.completeViewInstallExtensionPageStep();
    bc.addEventListener('message', ({ done }) => {
      if (done) {
        navigate(ROUTES.DASHBOARD);
      }
    });

    return (): void => {
      bc.close();
    };
  }, []);

  const sendDoneMessage = (): void => {
    bc.postMessage({ done: true });
  };

  useEffect(() => {
    if (isExtensionInstalled) {
      sendDoneMessage();
      navigate(ROUTES.DASHBOARD);
    }
  }, [isExtensionInstalled]);

  return {
    name: account?.fullName,
    onSkip: (): void => {
      sendDoneMessage();
      navigate(ROUTES.DASHBOARD);
    },
    onInstall: (): void => {
      onboardingUseCase.completeClickDownloadExtensionStep();
      sendDoneMessage();
      navigate(ROUTES.DASHBOARD);
    },
  };
};
