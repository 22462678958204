import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Button, List, Popover, Portal, Stack, Typography } from '@mui/material';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { ArrowDownIcon } from '@/components/Icons';

import { OnboardingWidgetStepItem } from './components/OnboardingWidgetStepItem';
import { useOnboardingWidgetViewModel } from './useOnboardingWidgetViewModel';

import styles from './styles.module.scss';

export const OnboardingWidget: FC = () => {
  const { t } = useTranslation();
  const {
    anchorEl,
    onClose,
    onClickButton,
    onClickInstallExtension,
    renderStep,
    isVisible,
    isOpen,
  } = useOnboardingWidgetViewModel();

  if (!isVisible) return null;

  return (
    <Portal>
      <Button
        variant="contained"
        color="primary"
        onClick={onClickButton}
        className={styles.button}
        startIcon={<CheckCircleOutline />}
      >
        {t('onboarding.widget.buttonText')}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          paper: styles.paper,
        }}
        disableScrollLock
      >
        <Stack direction="column" className={styles.header}>
          <Typography className={styles.title}>{t('onboarding.widget.title')}</Typography>
          <Typography className={styles.subtitle}>
            {t('onboarding.widget.subtitle')}
          </Typography>
          <Box className={styles.closeButton} onClick={onClose}>
            <ArrowDownIcon />
          </Box>
        </Stack>
        <List className={styles.list}>
          {renderStep('installExtension', (item) => {
            return (
              <OnboardingWidgetStepItem
                status={item.status}
                text={
                  <Trans
                    t={t}
                    i18nKey="onboarding.widget.steps.installExtension"
                    components={{
                      Link: (
                        <a
                          href={EXTERNAL_ROUTES.EXTENSION_URL}
                          target="_blank"
                          onClick={onClickInstallExtension}
                        />
                      ),
                    }}
                  />
                }
              />
            );
          })}
          {renderStep('revealContact', (item) => (
            <OnboardingWidgetStepItem
              status={item.status}
              text={
                <Trans
                  t={t}
                  i18nKey="onboarding.widget.steps.revealContact"
                  components={{
                    Link: (
                      <a
                        href={EXTERNAL_ROUTES.LINKEDIN_PROFILE_TO_REVEAL}
                        target="_blank"
                        onClick={onClose}
                      />
                    ),
                  }}
                />
              }
            />
          ))}
          {renderStep('inviteTeamMember', (item) => (
            <OnboardingWidgetStepItem
              status={item.status}
              text={
                <Trans
                  t={t}
                  i18nKey="onboarding.widget.steps.inviteTeamMember"
                  // components={{
                  //   Link: isFreePlan ? (
                  //     <Link to={ROUTES.SETTINGS.REFERRAL} onClick={onClose} />
                  //   ) : (
                  //     <Link
                  //       to="/account/team-management"
                  //       state={{ isInviteTeamMemberModalOpen: true }}
                  //       onClick={onClose}
                  //     />
                  //   ),
                  // }}
                  components={{
                    Link: (
                      <Link
                        to="/account/team-management"
                        state={{ isInviteTeamMemberModalOpen: true }}
                        onClick={onClose}
                      />
                    ),
                  }}
                />
              }
            />
          ))}
          {renderStep('exportContact', (item) => (
            <OnboardingWidgetStepItem
              status={item.status}
              text={
                <Trans
                  t={t}
                  i18nKey="onboarding.widget.steps.exportContacts"
                  components={{
                    Link: <Link to="/saved-contacts" onClick={onClose} />,
                  }}
                />
              }
            />
          ))}
        </List>
      </Popover>
    </Portal>
  );
};
