import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { useIsOnline } from '../../hooks';

export const OnlineRequiredAction: FC<PropsWithChildren> = ({ children }) => {
  const isOnline = useIsOnline();

  if (isOnline) {
    return children;
  }

  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<{ disabled: boolean }>, {
        disabled: true,
      });
    }

    return child;
  });
};
