import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { UnauthenticatedLayout } from '@/router/layouts';
import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { SignupError } from '../../useSignUpViewModel';

export const SignUpError: React.FC<{ error: SignupError | null }> = ({ error }) => {
  const { t } = useTranslation('auth');
  const { t: validationTranslate } = useTranslation('validation');

  switch (error) {
    case SignupError.InvalidEmail:
    case SignupError.InvalidCaptcha:
      return (
        <UnauthenticatedLayout.ErrorAlert>{t(error)}</UnauthenticatedLayout.ErrorAlert>
      );

    case SignupError.UserExists:
      return (
        <UnauthenticatedLayout.ErrorAlert>
          <Trans t={t} i18nKey={'signup.errors.exist'}>
            This email is already exist.
            <Link to={ROUTES.LOGIN}>Sign In now</Link>
          </Trans>
        </UnauthenticatedLayout.ErrorAlert>
      );

    case SignupError.InvalidWorkEmail:
      return (
        <UnauthenticatedLayout.ErrorAlert>
          <Trans t={validationTranslate} key="workEmailInvalidContactSales">
            Enter a valid work email or{' '}
            <Link to={EXTERNAL_ROUTES.TALK_TO_SALES} target="_blank">
              Contact sales
            </Link>
          </Trans>
        </UnauthenticatedLayout.ErrorAlert>
      );
    default:
      return null;
  }
};
