import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const SubmitButton: FC<
  PropsWithChildren<{
    onClick: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    disabled?: boolean;
  }>
> = ({ children, onClick, className, disabled }) => {
  return (
    <Button
      onClick={onClick}
      variant={'contained'}
      type="submit"
      disabled={disabled}
      className={clsx(styles.button, className)}
    >
      {children}
    </Button>
  );
};
