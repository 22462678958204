import { FC, ReactElement } from 'react';

import { ActionDisableGuard } from '@/features/common/permissions';
import { TagApplier } from '@/features/common/tag';

import { DefaultAnchor } from './components/DefaultAnchor';
import { MultipleAnchor } from './components/MultipleAnchor';
import { SingleAnchor } from './components/SingleAnchor';
import { useTagsCellViewModel } from './useTagsCellViewModel';

export const TagsCell: FC<{
  contactId: string;
  value?: string[];
}> = ({ value = [], contactId }) => {
  const { anchorType, onApply, isNotAvailable, selectedTags } = useTagsCellViewModel({
    value,
    id: contactId,
  });

  if (isNotAvailable) return <>-</>;

  return (
    <TagApplier
      onApply={onApply}
      defaultValue={value}
      renderAnchor={({ setAnchorElement }): ReactElement => {
        return (
          <ActionDisableGuard>
            {anchorType === 'default' ? (
              <DefaultAnchor
                onClick={(e): void =>
                  setAnchorElement((prev) => {
                    return prev ? null : e.currentTarget;
                  })
                }
              />
            ) : anchorType === 'single' ? (
              <SingleAnchor
                label={selectedTags[0]?.name ?? ''}
                color={selectedTags[0]?.color ?? ''}
                onClick={(e): void =>
                  setAnchorElement((prev) => {
                    return prev ? null : e.currentTarget;
                  })
                }
              />
            ) : (
              <MultipleAnchor
                selectedTags={selectedTags}
                onClick={(e): void =>
                  setAnchorElement((prev) => {
                    return prev ? null : e.currentTarget;
                  })
                }
              />
            )}
          </ActionDisableGuard>
        );
      }}
    />
  );
};
