import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { CustomSelectV2 } from '@/components';

import { AddToListButton } from './components/AddToListButton';
import { useListCellViewModel } from './useListCellViewModel';

import styles from './styles.module.scss';

export const ListCell: FC<{ contactId: string; contactListId: string }> = ({
  contactListId,
  contactId,
}) => {
  const { t } = useTranslation('contactsTable');
  const { options, addToList, search, changeSearch, createNewList, searchIsMatched } =
    useListCellViewModel({
      value: contactListId,
      contactId,
    });

  return (
    <Box className={styles.wrapperListCell}>
      <ActionDisableGuard>
        <CustomSelectV2
          value={contactListId}
          options={options}
          onChange={addToList}
          size="small"
          {...(!Boolean(contactListId) && {
            InputProps: {
              render: ({ setAnchorElement }): ReactElement => {
                return (
                  <Button
                    onClick={(e): void => {
                      setAnchorElement(e.currentTarget);
                    }}
                    className={styles.cellAnchorButton}
                  >
                    {t('listCell.addListButton')}
                  </Button>
                );
              },
            },
          })}
          MenuProps={{
            PopoverProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            },
            SearchProps: {
              value: search,
              variant: 'outlined',
              onChange: changeSearch,
            },
            ListProps: {
              classes: { root: styles.Menu },
              emptyState: null,
            },
            ...(!searchIsMatched &&
              search && {
                renderFooter: ({ closeMenu }): ReactElement => {
                  return (
                    <AddToListButton
                      onClick={() => {
                        createNewList(search);
                        closeMenu();
                      }}
                      inputValue={search}
                    />
                  );
                },
              }),
          }}
          searchable
        />
      </ActionDisableGuard>
    </Box>
  );
};
