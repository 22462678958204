import { cloneElement, ForwardedRef, forwardRef, ReactElement } from 'react';

import { usePermissions } from '../hooks/usePermissions';

type ChildrenCastedType = ReactElement<{
  disabled?: boolean;
  ref?: ForwardedRef<unknown>;
}>;

export const ActionDisableGuard = forwardRef<unknown, { children: ReactElement }>(
  ({ children, ...props }, ref) => {
    const { isSuperAdmin } = usePermissions();

    return cloneElement(children as ChildrenCastedType, {
      ...props,
      disabled:
        isSuperAdmin || ((children as ChildrenCastedType).props.disabled ?? false),
      ref: ref,
    });
  },
);
