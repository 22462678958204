import { loadStripe, Stripe } from '@stripe/stripe-js';
import { defer, from, Observable, of, retry, tap, timer } from 'rxjs';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import { useAppConfig } from '@/config';

let stripeInstance: Stripe | null = null;

function getStripeInstance(key: string): Observable<Stripe | null> {
  if (stripeInstance) {
    return of(stripeInstance);
  }

  return defer(() => from(loadStripe(key))).pipe(
    tap((stripe) => {
      stripeInstance = stripe;
    }),
    retry({
      delay: (_, retryCount) => {
        return timer(retryCount * 1_000);
      },
      resetOnSuccess: true,
      count: 5,
    }),
  );
}

export const useStripeInstance = (): ObservableResult<Stripe | null> => {
  const appConfig = useAppConfig();
  return useObservableResult(() => getStripeInstance(appConfig.stripe.publishableKey));
};
