import { FC } from 'react';
import { Box, Link, Tooltip } from '@mui/material';

import { ICompanyEntity, useCompanyById } from '@/features/common/contact';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { InfoTooltip } from './components/InfoTooltip';
import { Logo } from './components/Logo';

import styles from './styles.module.scss';

const isCompanyDataAvailable = (companyData: ICompanyEntity): boolean =>
  Boolean(
    companyData.address ||
      companyData.description ||
      companyData.employeesMax ||
      companyData.employeesMin ||
      companyData.industry ||
      companyData.phone ||
      (companyData.socialNetworks?.length !== 0 && companyData.socialNetworks),
  );

export const CompanyCell: FC<{ companyId: string }> = ({ companyId }) => {
  const { data } = useCompanyById(companyId);
  const { trackEvent } = useAnalytics();

  if (!data) return <Box component="span">-</Box>;

  const shouldRenderTooltip = isCompanyDataAvailable(data);

  return (
    <>
      <Box className={styles.companyCell}>
        <Logo key={data.uuid} images={data.images} name={data.name} image={data.image} />
        {shouldRenderTooltip ? (
          <Tooltip
            classes={{
              tooltip: styles.infoBoxTooltip,
              arrow: styles.infoBoxTooltipArrow,
            }}
            title={<InfoTooltip data={data} key={data.uuid} />}
            placement="right-start"
            onOpen={(): void => {
              trackEvent(ANALYTICS_EVENTS.COMPANY_FIELD_CLICK);
            }}
            enterNextDelay={300}
            leaveDelay={300}
          >
            <Link
              href="#"
              onClick={(e): void => {
                e.preventDefault();
              }}
            >
              {data?.name}
            </Link>
          </Tooltip>
        ) : (
          <Box>{data?.name}</Box>
        )}
      </Box>
    </>
  );
};
