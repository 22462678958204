import { FC } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type PlayerProps = {
  url: string;
  className?: string;
  onFirstStartPlay?: () => void;
};
export const Player: FC<PlayerProps> = ({ url, className }) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <video src={url} width={'100%'} height={'100%'} autoPlay controls />
    </div>
  );
};
