import { inject, injectable } from 'inversify';
import { first, firstValueFrom, map, Observable, switchMap } from 'rxjs';

import { CONTACT_TYPES, INTEGRATION_TYPES, PERMISSIONS_TYPES } from '@/ioc/types';

import { type IIntegrationRepository } from '@/features/integration';

import { type IPermissionsRepository, Permission } from '../../permissions';

import type { IContactRepository, IContactUseCase } from './abstractions';
import type { IContactEntity } from './entities';
import { ContactExportNotAllowedError } from './errors';
import type { IContactDC } from '../data';

type ProviderData =
  | { provider: string; ids: string[] }
  | { provider: string; searchParams: URLSearchParams };

@injectable()
export class ContactUseCase implements IContactUseCase {
  @inject(CONTACT_TYPES.ContactRepository)
  private readonly contactRepository: IContactRepository;

  @inject(INTEGRATION_TYPES.IntegrationRepository)
  private integrationRepository: IIntegrationRepository;

  @inject(PERMISSIONS_TYPES.PermissionsRepository)
  private permissionsRepository: IPermissionsRepository;

  private async assertPermissions(): Promise<void> {
    const hasPermiossion = await firstValueFrom(
      this.permissionsRepository.hasPermissions(Permission.CanAccessDataExport),
    );

    if (!hasPermiossion) throw new ContactExportNotAllowedError();
  }

  public getByFilters(params: { queryString: string; autoUpdate?: boolean }): Observable<{
    entities: IContactEntity[];
    totalCount: number;
  }> {
    return this.contactRepository.getByFilters(params);
  }

  public moveToList(listId: string, ids: string[]): Promise<IContactEntity[]> {
    return this.contactRepository.moveToList(listId, ids);
  }

  public updateById(
    id: string,
    payload: Partial<
      Pick<IContactDC, 'contact_list_id' | 'assigned_to' | 'updated_at' | 'tags'>
    >,
  ): Promise<IContactEntity> {
    return this.contactRepository.updateById(id, payload);
  }

  public upsertPatch(patch: IContactEntity[]): Promise<IContactEntity[]> {
    return this.contactRepository.upsertPatch(patch);
  }

  public async exportToCsvAll(): Promise<{ linkToDownload: string }> {
    await this.assertPermissions();
    return this.contactRepository.exportToCsvAll();
  }

  public async exportToCsvByFilters(
    queryParams: URLSearchParams,
  ): Promise<{ linkToDownload: string }> {
    await this.assertPermissions();
    return this.contactRepository.exportToCsvByFilters(queryParams);
  }

  public async exportToCsvByIds(ids: string[]): Promise<{ linkToDownload: string }> {
    await this.assertPermissions();
    return this.contactRepository.exportToCsvByIds(ids);
  }

  public wrongInfoReport(dto: {
    value: string;
    contact_uuid: string;
    entity_type: string;
  }): Promise<boolean> {
    return this.contactRepository.wrongInfoReport(dto);
  }

  public async exportToIntegration(data: ProviderData): Promise<void> {
    await this.assertPermissions();

    if ('ids' in data) {
      return this.integrationRepository.exportEntitiesToProvider(
        data.provider,
        data.ids,
        'contact',
      );
    } else {
      const searchParams = new URLSearchParams(data.searchParams);

      return firstValueFrom(
        this.contactRepository.getByQuery(searchParams).pipe(
          first(),
          map((contacts) => {
            return contacts.map((entity) => entity.uuid);
          }),
          switchMap((ids) =>
            this.integrationRepository.exportEntitiesToProvider(
              data.provider,
              ids,
              'contact',
            ),
          ),
        ),
      );
    }
  }
}
