import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { IntegrationCard } from './components/IntegrationCard';
import { IntegrationDialog } from './components/IntegrationDialog';
import { useIntegrationsViewModel } from './useIntegrationsViewModel';

import styles from './styles.module.scss';

export const Integrations: FC = () => {
  const { t } = useTranslation('integrations');
  const {
    integrations,
    handleConnect,
    integrationDialogConfig,
    resetIntegrationDialogConfig,
  } = useIntegrationsViewModel();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.header}>
        <Typography className={styles.title}>{t('pageTitle')}</Typography>
        <Typography className={styles.description}>{t('pageDescription')}</Typography>
      </Box>
      <Box className={styles.gridWrapper}>
        <Box className={styles.grid}>
          {integrations.isLoading ? (
            <>
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
              <IntegrationCard.Preview />
            </>
          ) : (
            integrations.data.map((integration) => (
              <IntegrationCard
                key={integration.id}
                integration={integration}
                onConnect={handleConnect}
              />
            ))
          )}
        </Box>
      </Box>
      <IntegrationDialog
        config={integrationDialogConfig}
        onClose={resetIntegrationDialogConfig}
      />
    </Box>
  );
};
