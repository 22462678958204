import type { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { catchError, map, Observable, throwError } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { IHttpClient, NetworkError } from '@/features/system/network';

import {
  IIntegrationApiService,
  IntegrationNotFoundError,
  SalesforceApiDisabledError,
} from '../../domain';
import type { IIntegrationMapperDC, IIntegrationSettingsDC } from '../dataContracts';

const integrationEndpoints = {
  getMapping: (integrationName: string, objectType: string): string =>
    `/api/v1/integrations/get-mapping/${integrationName}/${objectType}`,
  saveMapping: (integrationName: string, objectType: string): string =>
    `/api/v1/integrations/save-mapping/${integrationName}/${objectType}`,
  updateEnabledObjects: (integrationName: string): string =>
    `/api/v1/integrations/update-enabled-objects/${integrationName}`,
  disconnectUser: (integrationName: string): string =>
    `/api/v1/integrations/disconnect-user/${integrationName}`,
  getSettings: (integrationName: string): string =>
    `/api/v1/integrations/get-integration/${integrationName}`,
  exportEntitiesToProvider: (integrationName: string, objectType: string): string =>
    `/api/v1/integrations/export-items/async/${integrationName}/${objectType}`,
};

@injectable()
export default class IntegrationApiService implements IIntegrationApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  private transformNetworkError(provider: string, error: Error): Error {
    if (error instanceof NetworkError && error.response) {
      if (error.response.data?.name === 'SalesforceApiDisabled') {
        return new SalesforceApiDisabledError();
      }

      if (error.response.status === 404) {
        return new IntegrationNotFoundError(`${provider} integration not found`);
      }
    }

    return error;
  }

  private handleRequest<T>(observable: Observable<T>, provider: string): Observable<T> {
    return observable.pipe(
      catchError((error) =>
        throwError(() => this.transformNetworkError(provider, error)),
      ),
    );
  }

  exportEntitiesToProvider(
    provider: string,
    ids: string[],
    entityType: 'contact',
  ): Observable<{ task_id: string }> {
    return this.handleRequest(
      this.httpClient
        .post<{
          task_id: string;
        }>(integrationEndpoints.exportEntitiesToProvider(provider, entityType), {
          ids,
        })
        .pipe(map((response) => response.data)),
      provider,
    );
  }

  getMapping(provider: string, objectType: string): Observable<IIntegrationMapperDC> {
    return this.handleRequest(
      this.httpClient
        .get<IIntegrationMapperDC>(
          integrationEndpoints.getMapping(provider, objectType),
          {
            cachePolicy: 'network-first',
            revalidateAfter: 60 * 1000,
            cacheKey: `integration-mapping-${provider}-${objectType}`,
          },
        )
        .pipe(map((response) => response.data)),
      provider,
    );
  }

  saveMapping(
    provider: string,
    objectType: string,
    data: IIntegrationMapperDC['mapping'],
  ): Observable<unknown> {
    return this.handleRequest(
      this.httpClient.post(integrationEndpoints.saveMapping(provider, objectType), {
        mapping: data,
      }),
      provider,
    );
  }

  disconnectUser(provider: string): Observable<unknown> {
    return this.handleRequest(
      this.httpClient.post(integrationEndpoints.disconnectUser(provider), {}),
      provider,
    );
  }

  getSettings(provider: string): Observable<IIntegrationSettingsDC> {
    return this.handleRequest(
      this.httpClient
        .get<IIntegrationSettingsDC>(integrationEndpoints.getSettings(provider), {
          cachePolicy: 'network-first',
          revalidateAfter: 60 * 1000,
          cacheKey: `integration-${provider}`,
        })
        .pipe(map((response) => response.data)),
      provider,
    );
  }

  updateSettings(
    provider: string,
    enabled_objects: string[],
  ): Observable<IIntegrationSettingsDC> {
    return this.handleRequest(
      this.httpClient
        .post(integrationEndpoints.updateEnabledObjects(provider), {
          enabled_objects,
        })
        .pipe(map((response: AxiosResponse<IIntegrationSettingsDC>) => response.data)),
      provider,
    );
  }
}
