import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { array, object, string } from 'yup';

import type {
  IIntegrationConfigEntity,
  MappedObjectType,
} from '@/features/integration/domain';
import { useAppLogger } from '@/features/system/logger';

import { useFormWithSchema } from '@/utils/validation';

import { useIntegrationUseCase } from '../../../hooks';

import { FieldMappingFormValues, FieldMappingOptions } from './types';

type UseFieldMappingForm = (params: { config: IIntegrationConfigEntity }) => {
  form: UseFormReturn<FieldMappingFormValues>;
  options: FieldMappingOptions;
  handleSubmit: () => void;
};

export const useFieldMappingForm: UseFieldMappingForm = ({ config }) => {
  const appLogger = useAppLogger();
  const { enqueueSnackbar } = useSnackbar();
  const integrationUseCase = useIntegrationUseCase();
  const { t: tSaving } = useTranslation('integrations', {
    keyPrefix: 'integration.tabs.fieldMapping',
  });

  const formValues: FieldMappingFormValues = useMemo(() => {
    return Object.entries(config.mappers).reduce((acc, [objectType, { mapping }]) => {
      acc[objectType] = mapping;
      return acc;
    }, {} satisfies FieldMappingFormValues);
  }, [config.mappers]);

  const schema = useMemo(() => {
    const mappingSchema = object().shape({
      powerleadField: string().required(),
      providerField: string().required('Provider field is required'),
      predefinedValue: string().when('powerleadField', {
        is: 'predefined_value',
        then: (b) => b.required('Predefined value is required'),
        otherwise: (b) => b.notRequired(),
      }),
    });

    return object().shape(
      Object.keys(config.mappers).reduce((acc, objectType) => {
        acc[objectType] = array().of(mappingSchema);
        return acc;
      }, {}),
    );
  }, [config.mappers]);

  const form: UseFormReturn<FieldMappingFormValues> = useFormWithSchema(schema, {
    defaultValues: formValues,
  });

  const formState = form.watch();

  const options = useMemo(() => {
    return Object.entries(config.mappers).reduce(
      (acc, [oT, { powerleadFields, providerFields }]) => {
        const objectType = oT as MappedObjectType;

        const powerleadFieldValues = new Set(
          formState[objectType]?.map((m) => m.powerleadField),
        );

        const providerFieldValues = new Set(
          formState[objectType]?.map((m) => m.providerField),
        );

        acc[objectType] = {
          powerleadFields: Object.entries(powerleadFields).map(
            ([value, { example, display: label }]) => ({
              value,
              label,
              disabled: value && powerleadFieldValues.has(value),
              example,
            }),
          ),
          providerFields: Object.entries(providerFields).map(([value, label]) => ({
            value,
            label,
            disabled: value && providerFieldValues.has(value),
            example: null,
          })),
        };
        return acc;
      },
      {},
    );
  }, [formState, config.mappers]);

  const submitValues = structuredClone(formState);

  const handleSubmit = form.handleSubmit(
    async () => {
      const { provider } = config.settings;
      try {
        await Promise.all(
          Object.entries(submitValues).map(([objectType, mapping]) =>
            integrationUseCase.saveMapping(provider, objectType, mapping),
          ),
        );
        form.reset(submitValues);
        enqueueSnackbar({
          message: tSaving('savingSuccess'),
          variant: 'success',
        });
      } catch (e) {
        appLogger.error(e);
        enqueueSnackbar({
          variant: 'error',
          message: tSaving('savingError', { name: provider }),
        });
      }
    },
    () => {
      enqueueSnackbar({
        variant: 'error',
        message: tSaving('validationError'),
      });
    },
  );

  return {
    form,
    options,
    handleSubmit,
  };
};
