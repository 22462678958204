import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import { Box, Button } from '@mui/material';

import { LaunchIcon } from '@/components/Icons';

import { OverlayLogoutButton } from '../OverlayLogoutButton';
import { OverlayLayout } from '..';

import styles from './styles.module.scss';

type AccountLockedUserOverlayProps = {
  onContactsExport: () => void;
  ownerEmail: string;
};

export const AccountLockedUserOverlay: FC<AccountLockedUserOverlayProps> = ({
  ownerEmail,
  onContactsExport,
}) => {
  const { t } = useTranslation('billing', {
    keyPrefix: 'overlays.accountOnHold',
  });

  return (
    <OverlayLayout
      title={t('title')}
      description={
        <Trans
          i18nKey="description"
          t={t}
          defaultValue="There was an issue with your account’s payment. Please contact your admin (
          <a>{{email}}</a>) to resolve this issue."
          components={{
            a: <a href={`mailto:${ownerEmail}`} />,
          }}
          values={{ email: ownerEmail }}
        />
      }
      footer={
        <Box className={styles.footer}>
          <NavLink to="" className={styles.link}>
            <Button
              variant="text"
              className={styles.link}
              endIcon={<LaunchIcon className={styles.icon} />}
              onClick={onContactsExport}
            >
              {t('export')}
            </Button>
          </NavLink>
          <OverlayLogoutButton />
        </Box>
      }
    />
  );
};
