import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type LinearProgressProps = {
  value: number;
  orientation?: 'horizontal' | 'vertical';
};

const Vertical = styled(Box)(() => ({
  width: '8px',
  height: '100%',
  borderRadius: '4px',
  backgroundColor: '#e4e6e8',
  position: 'relative',
  overflow: 'hidden',
  '& > div': {
    background: `linear-gradient(to bottom, #feb100, #ff2264)`,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '4px',
  },
}));

const Horizontal = styled(Box)(() => ({
  height: '8px',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: '#e4e6e8',
  position: 'relative',
  overflow: 'hidden',
  '& > div': {
    background: `linear-gradient(to right, #feb100, #ff2264)`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: '4px',
  },
}));

export const LinearProgress: React.FC<BoxProps & LinearProgressProps> = ({
  value,
  orientation = 'horizontal',
  ...wrapperProps
}) => {
  if (orientation === 'vertical') {
    return (
      <Vertical {...wrapperProps}>
        <Box height={`${value}%`} />
      </Vertical>
    );
  }

  return (
    <Horizontal {...wrapperProps}>
      <Box width={`${value}%`} />
    </Horizontal>
  );
};
