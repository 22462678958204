import { inject, injectable } from 'inversify';
import { combineLatest, map, type Observable, switchMap } from 'rxjs';

import { INTEGRATION_TYPES } from '@/ioc/types';

import type { IIntegrationRepository, IIntegrationUseCase } from './abstractions';
import type { IIntegrationConfigEntity, MappedObjectType } from './entities';

@injectable()
export default class IntegrationUseCase implements IIntegrationUseCase {
  @inject(INTEGRATION_TYPES.IntegrationRepository)
  private repository: IIntegrationRepository;

  saveMapping(provider: string, objectType: string, data: []): Promise<void> {
    return this.repository.saveMapping(provider, objectType, data);
  }

  disconnectUser(provider: string): Promise<void> {
    return this.repository.disconnectUser(provider);
  }

  updateSettings(provider: string, enabledObjects: MappedObjectType[]): Promise<void> {
    return this.repository.updateSettings(provider, enabledObjects);
  }

  getIntegrationConfig(provider: string): Observable<IIntegrationConfigEntity> {
    return this.repository.getSettings(provider).pipe(
      switchMap((settings) =>
        combineLatest(
          settings.supportedObjects.reduce((acc, objectType) => {
            acc[objectType] = this.repository.getMapping(provider, objectType);
            return acc;
          }, {}),
        ).pipe(
          map((mappers) => ({
            settings,
            mappers,
          })),
        ),
      ),
    );
  }
}
