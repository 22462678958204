import { inject, injectable } from 'inversify';
import { filter, firstValueFrom, from, map, Observable, switchMap } from 'rxjs';

import { INTEGRATIONS_TYPES } from '@/ioc/types';

import IIntegrationsApiService from '@/features/integrations/domain/abstractions/IIntegrationsApiService';
import IIntegrationsRepository from '@/features/integrations/domain/abstractions/IIntegrationsRepository';

import iconCustom from '@/assets/icons/integrations/custom.png';
import iconHubspot from '@/assets/icons/integrations/hubspot.png';
import iconOutreach from '@/assets/icons/integrations/outreach.png';
import iconPipedrive from '@/assets/icons/integrations/pipedrive.png';
import iconSalesforce from '@/assets/icons/integrations/salesforce.png';
import iconZapier from '@/assets/icons/integrations/zapier.png';
import iconZoho from '@/assets/icons/integrations/zoho.png';

import type { IIntegrationEntity } from '../domain';

import type { IIntegrationsState } from './db';
import { mapIntegrationsConfigDcToEntities } from './mappers';

@injectable()
export default class IntegrationsRepository implements IIntegrationsRepository {
  @inject(INTEGRATIONS_TYPES.IntegrationsApiService)
  private api: IIntegrationsApiService;

  @inject(INTEGRATIONS_TYPES.IntegrationsState)
  private state: IIntegrationsState;

  private static readonly staticProviders: Array<
    Omit<IIntegrationEntity, 'status' | 'isConnected' | 'isAvailable' | 'isExpired'>
  > = [
    {
      icon: iconSalesforce,
      id: 'salesforce',
      name: 'Salesforce',
    },
    {
      icon: iconHubspot,
      id: 'hubspot',
      name: 'HubSpot',
    },
    {
      icon: iconZoho,
      id: 'zoho',
      name: 'Zoho CRM',
    },
    {
      icon: iconPipedrive,
      id: 'pipedrive',
      name: 'Pipedrive',
    },
    {
      icon: iconZapier,
      id: 'zapier',
      name: 'Zapier',
    },
    {
      icon: iconOutreach,
      id: 'outreach',
      name: 'Outreach',
    },
    {
      id: 'custom',
      icon: iconCustom,
      name: 'Custom integration',
    },
  ];

  private async loadRemoteIntegrationsConfig(): Promise<void> {
    const state = await firstValueFrom(this.api.getIntegrationsConfig());
    await this.state.setState(state);
  }

  private getLocalIntegrationsConfig(): Observable<IIntegrationEntity[]> {
    return this.state.getState().pipe(
      filter((state) => !!state),
      map((data) =>
        mapIntegrationsConfigDcToEntities(data, IntegrationsRepository.staticProviders),
      ),
    );
  }

  public sendIntegrationRequest(params: {
    title: string;
    message: string;
  }): Promise<void> {
    return this.api.sendIntegrationRequest(params);
  }

  public sendIntegrationRequestToAdmin(provider: string): Promise<string> {
    return this.api.sendIntegrationRequestToAdmin(provider);
  }

  public async connectUser(providerName: string, code: string): Promise<void> {
    await this.api.connectUser(providerName, code);
    await this.loadRemoteIntegrationsConfig(); // refresh integrations
  }

  public getAuthUrl(provider: string): Promise<{ url: string }> {
    return firstValueFrom(this.api.getAuthUrl(provider));
  }

  public getIntegrations(
    isStatic: true,
  ): Array<
    Omit<IIntegrationEntity, 'status' | 'isConnected' | 'isAvailable' | 'isExpired'>
  >;
  public getIntegrations(): Observable<IIntegrationEntity[]>;
  public getIntegrations(
    isStatic?: true,
  ):
    | Array<
        Omit<IIntegrationEntity, 'status' | 'isConnected' | 'isAvailable' | 'isExpired'>
      >
    | Observable<IIntegrationEntity[]> {
    if (isStatic) return IntegrationsRepository.staticProviders;

    return from(this.loadRemoteIntegrationsConfig()).pipe(
      switchMap(() => this.getLocalIntegrationsConfig()),
    );
  }
}
