import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

import 'reflect-metadata';

import { bindModules } from '@/ioc/index';

import { initSentry } from '@/features/system/sentry';

import { appConfig } from './config/AppConfig';
import App from './App';

bindModules();
initSentry();

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found. Pls add <div id="root"></div> to your html');
}

console.log(
  `%cVersion: ${appConfig.version}`,
  `font-weight:bold;font-size:32px;color:green;text-decoration:underline;`,
);

const root = createRoot(rootElement);
root.render(createElement(App));
