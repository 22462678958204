import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Icon404 from '@/assets/icons/icon404.svg?react';

import { ROUTES } from '../routes';

import { ErrorLayout } from './components';

export const ErrorPage404: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  /* TODO: Implement conditional redirect to dashboard/login page
           when loginUseCases.isLoggedIn() will be ready. */
  const onHomeButtonClick = (): void => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <ErrorLayout
      icon={<Icon404 />}
      title={'404'}
      description={t('404PageDescription')}
      buttonText={t('404PageButton')}
      onButtonClick={onHomeButtonClick}
    />
  );
};
