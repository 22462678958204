import { inject, injectable, postConstruct } from 'inversify';
import { BehaviorSubject, filter, first, Observable } from 'rxjs';

import { APP_CONFIG_TYPES, LEADER_ELECTION_TYPES } from '@/ioc/types';

import type { ILeaderElectionRepository } from '@/features/system/leaderElection';

import { IExtensionMessageEntity, PingExtensionMessage } from '../domain';

import type { IExtensionRepository } from './abstractions';

import type { AppConfig } from '@/config';

@injectable()
export class ExtensionRepository implements IExtensionRepository {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  private isExtensionInstalled$ = new BehaviorSubject<boolean>(false);
  private extensionInfo$ = new BehaviorSubject<Nullable<chrome.management.ExtensionInfo>>(
    null,
  );

  @inject(LEADER_ELECTION_TYPES.LeaderElectionRepository)
  private leader: ILeaderElectionRepository;

  @postConstruct()
  init(): void {
    this.leader
      .getIsLeaderSubject()
      .pipe(
        filter((isLeader) => isLeader),
        first(),
      )
      .subscribe(() => {
        this.sendMessage(new PingExtensionMessage(), (res: unknown) => {
          if (!res) return;
          this.isExtensionInstalled$.next(true);
          if (typeof res === 'object') {
            this.extensionInfo$.next(res as chrome.management.ExtensionInfo);
          }
        });
      });
  }

  isExtensionInstalled(): Observable<boolean> {
    return this.isExtensionInstalled$.asObservable();
  }

  sendMessage(message: IExtensionMessageEntity, callback?: (res: unknown) => void): void {
    // @ts-ignore
    void window?.chrome?.runtime?.sendMessage(
      this.appConfig.extension.id,
      message,
      callback,
    );
  }

  getExtensionInfo(): Observable<Nullable<chrome.management.ExtensionInfo>> {
    return this.extensionInfo$.asObservable();
  }
}
