import { FC } from 'react';
import { Box } from '@mui/material';

import type { IContactEntity } from '@/features/common/contact/domain';

import { EmailMenu } from './components/EmailMenu';
import { IntegrationsMenu } from './components/IntegrationsMenu';
import { PhoneMenu } from './components/PhoneMenu';
import { Preview } from './components/Preview';
import { SocialLinksMenu } from './components/SocialLinksMenu';
import { useContactInfoViewModel } from './useContactInfoViewModel';

import styles from './styles.module.scss';

export const ContactInfoCell: FC<{ contact: IContactEntity }> & {
  Preview: FC;
} = ({ contact }) => {
  const value = useContactInfoViewModel(contact);

  if (!value) {
    return <Box>-</Box>;
  }

  return (
    <Box className={styles.contactsCell}>
      {Boolean(value.emails?.length) && (
        <EmailMenu contactId={contact.uuid} emails={value.emails} />
      )}
      {Boolean(value.phoneNumbers?.length) && (
        <PhoneMenu contactId={contact.uuid} items={value.phoneNumbers} />
      )}
      {Boolean(value.socialLinks?.length) && (
        <SocialLinksMenu links={value.socialLinks} />
      )}
      {Boolean(value.exportedLinks?.length) && (
        <IntegrationsMenu links={value.exportedLinks} />
      )}
    </Box>
  );
};

ContactInfoCell.Preview = Preview;
