import { type FC, useEffect } from 'react';
import HotjarLib from '@hotjar/browser';
import { filter } from 'rxjs';

import { type IAccountEntity, useAccountUseCase } from '@/features/common/account';

import { distinctUntilKeysChanged } from '@/utils/rx';

import { useAppConfig } from '@/config';

export const Hotjar: FC = () => {
  const accountUseCase = useAccountUseCase();
  const { version, hotjar } = useAppConfig();

  useEffect(() => {
    if (!hotjar.enabled) {
      return;
    }

    HotjarLib.init(hotjar.id, hotjar.version);

    const sub = accountUseCase
      .getAccount()
      .pipe(
        filter((account): account is IAccountEntity => !!account),
        distinctUntilKeysChanged('uuid', 'email', 'personalWorkspaceId'),
      )
      .subscribe((account) => {
        HotjarLib.identify(account.uuid, {
          email: account.email,
          version,
          workspace_id: account.personalWorkspaceId || '',
        });
      });

    return () => sub.unsubscribe();
  }, []);

  return null;
};
