import { useCurrentWorkspace } from '@/features/common/workspace';

import { useObservableResult } from '@/utils/rx';

import { useContactUseCase } from './useContactUseCase';

export const useContacts = (queryString: string, cacheResult = false) => {
  const contactUseCase = useContactUseCase();
  const { data: workspace } = useCurrentWorkspace();
  const autoUpdate = workspace?.membersCount ? workspace.membersCount <= 1 : false;

  return useObservableResult(
    () =>
      contactUseCase.getByFilters({
        queryString,
        autoUpdate,
      }),
    {
      defaultData: {
        entities: [],
        totalCount: 0,
      },
      deps: [queryString, autoUpdate],
      ...(cacheResult && { cacheKey: queryString }),
    },
  );
};
