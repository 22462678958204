import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { indexBy, prop } from 'ramda';

import { ROUTES } from '@/router/routes';

import { ITagEntity } from '@/features/common/tag';

import { useSearch } from '@/hooks';

import { useTagEntities } from '../../../../hooks/useTagEntities';

export const usePopoverBodyViewModel = ({ defaultValue }: { defaultValue: string[] }) => {
  const [value, setValue] = useState(defaultValue);
  const { value: search, handleSearchChange, handleSearchClear } = useSearch();
  const { data: allTags, isLoading: allTagsIsLoading } = useTagEntities();
  const { data: filteredTags } = useTagEntities({
    nameRegex: search,
  });

  const navigate = useNavigate();

  const tagMapById: Record<string, ITagEntity> = useMemo(() => {
    return indexBy(prop('uuid'), allTags);
  }, [allTags]);

  const isEmpty = !allTags.length || !filteredTags.length;

  const handleListItemClick = (tagId: string): void => {
    if (value.includes(tagId)) {
      setValue(value.filter((v) => v !== tagId));
    } else {
      setValue([...value, tagId]);
    }
  };

  const isChecked = (tagId: string): boolean => {
    return value.includes(tagId);
  };

  const toTagManager = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };

  useEffect(() => {
    const needToReApply = value.some((v) => !tagMapById[v]) && !allTagsIsLoading;

    if (!needToReApply) return;

    setValue(value.filter((v) => !!tagMapById[v]));
  }, [tagMapById, allTagsIsLoading]);

  const getValuesIsMatched = (): boolean => {
    if (!defaultValue.length && !value.length) return true;

    if (defaultValue.length !== value.length) return false;

    return value.every((v) => defaultValue.includes(v));
  };

  return {
    toTagManager,
    tags: filteredTags,
    search,
    handleSearchChange,
    handleSearchClear,
    handleListItemClick,
    isChecked,
    valuesIsMatched: getValuesIsMatched(),
    value,
    isEmpty,
  };
};
