import { inject, injectable } from 'inversify';
import { catchError, firstValueFrom, map, Observable, throwError } from 'rxjs';

import { NETWORK_TYPES } from '@/ioc/types';

import { SalesforceApiDisabledError } from '@/features/integration/domain';
import { IHttpClient, NetworkError } from '@/features/system/network';

import type IIntegrationsApiService from '../../domain/abstractions/IIntegrationsApiService';
import type { IIntegrationsConfigDC } from '../dataContracts';

const integrationEndpoints = {
  requestIntegration: '/api/v1/account/request-integration',
  getIntegrations: '/api/v1/integrations/get-integrations',
  getAuthUrl: (provider: string): string =>
    `/api/v1/integrations/get-authorization-url/${provider}`,
  connectUser: (provider: string): string =>
    `/api/v1/integrations/connect-user/${provider}`,
  sendIntegrationRequestToAdmin: (provider: string): string =>
    `/api/v1/integrations/email-connection-request/${provider}`,
};

@injectable()
export default class IntegrationsApiService implements IIntegrationsApiService {
  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  private transformNetworkError(error: Error): Error {
    if (error instanceof NetworkError && error.response) {
      if (error.response.data?.name === 'SalesforceApiDisabled') {
        return new SalesforceApiDisabledError();
      }
    }

    return error;
  }

  private handleRequest<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
      catchError((error) => throwError(() => this.transformNetworkError(error))),
    );
  }

  sendIntegrationRequest(params: { title: string; message: string }): Promise<void> {
    return firstValueFrom(
      this.handleRequest(
        this.httpClient
          .post<void>(integrationEndpoints.requestIntegration, params)
          .pipe(map(({ data }) => data)),
      ),
    );
  }

  connectUser(provider: string, code: string): Promise<{ user_email: string }> {
    return firstValueFrom(
      this.handleRequest(
        this.httpClient
          .post<{ user_email: string }>(integrationEndpoints.connectUser(provider), {
            code,
          })
          .pipe(map(({ data }) => data)),
      ),
    );
  }

  sendIntegrationRequestToAdmin(provider: string): Promise<string> {
    return firstValueFrom(
      this.httpClient
        .post<string>(integrationEndpoints.sendIntegrationRequestToAdmin(provider), {
          provider_id: provider,
        })
        .pipe(map(({ data }) => data)),
    );
  }
  getIntegrationsConfig(): Observable<IIntegrationsConfigDC> {
    return this.handleRequest(
      this.httpClient
        .get<IIntegrationsConfigDC>(integrationEndpoints.getIntegrations, {
          cachePolicy: 'network-first',
          revalidateAfter: 60 * 1000,
        })
        .pipe(map(({ data }) => data)),
    );
  }

  getAuthUrl(provider: string): Observable<{ url: string }> {
    return this.handleRequest(
      this.httpClient
        .get<{ url: string }>(integrationEndpoints.getAuthUrl(provider))
        .pipe(map(({ data }) => data)),
    );
  }
}
