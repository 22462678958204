import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Paper, Preview, PreviewVariant } from '@/components';

import styles from './styles.module.scss';

export const IntegrationCardPreview: FC = () => {
  return (
    <Paper className={styles.Card}>
      <Box className={styles.CardInfoWrapper}>
        <Box className={clsx(styles.CardIcon, styles.CardIconPreview)}>
          <Preview variant={PreviewVariant.MEDIUM} />
        </Box>
        <Box className={clsx(styles.CardInfo, styles.CardInfoPreview)}>
          <Box className={clsx(styles.CardTitlePreview, styles.CardTitle)}>
            <Preview variant={PreviewVariant.MEDIUM} />
          </Box>
          <Box className={clsx(styles.CardDescription, styles.CardDescriptionPreview)}>
            <Preview variant={PreviewVariant.LIGHT} />
          </Box>
          <Box
            className={clsx(
              styles.CardDescriptionAdditional,
              styles.CardDescriptionAdditionalPreview,
            )}
          >
            <Preview variant={PreviewVariant.LIGHT} />
          </Box>
          <Box className={clsx(styles.CardButton, styles.CardButtonPreview)}>
            <Preview />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
