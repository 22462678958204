import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import type { IIntegrationEntity } from '@/features/integrations/domain';
import { useIntegrationsUseCase } from '@/features/integrations/ui/hooks';

import { useAsyncExecutor } from '@/hooks';

export const useIntegrationConnectDialogViewModel = (
  integration: IIntegrationEntity,
  options: {
    openRequestDialog: (integration: IIntegrationEntity) => void;
    closeDialog(): void;
  },
) => {
  const { t } = useTranslation(['integrations', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const integrationsUseCase = useIntegrationsUseCase();

  return useAsyncExecutor(
    async () => {
      if (!integration.isAvailable) {
        return options.openRequestDialog(integration);
      }
      const { url } = await integrationsUseCase.getAuthUrl(integration.id);
      window.open(url, '_blank');
      options.closeDialog();
    },
    {
      onError: () => {
        enqueueSnackbar({
          variant: 'error',
          message: t('errors.errorOccurred', {
            ns: 'common',
          }),
        });
      },
    },
  );
};
