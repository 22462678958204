import { FC, PropsWithChildren } from 'react';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { clsx } from 'clsx';

import { Checkbox } from '@/components';

import { Pagination, PaginationProps } from './components';

import styles from './styles.module.scss';

type ContactsTableProps = Omit<DataGridProProps, 'variant'> & {
  pageSize?: number;
  loading?: boolean;
};

export const permanentScrollbar = (): string | undefined => {
  if ('platform' in navigator) {
    if (navigator.platform.toLowerCase().includes('mac')) {
      return 'pw-permanent-scrollbar-mac';
    }

    return 'pw-permanent-scrollbar';
  }
};

export const ContactsTable: FC<PropsWithChildren<ContactsTableProps>> & {
  Pagination: FC<PaginationProps>;
} = ({
  pageSize = 0,
  checkboxSelection = true,
  slots = {},
  slotProps = {},
  loading,
  rows,
  classes,
  children,
  initialState,
  ...props
}) => {
  return (
    <DataGridPro
      columnHeaderHeight={60}
      variant="basic"
      editMode="cell"
      checkboxSelection={checkboxSelection}
      classes={{
        columnHeaders: styles.columnHeaders,
        root: clsx(styles.tableRoot, permanentScrollbar(), {
          [styles.isEmpty]: !rows?.length,
        }),
        ...classes,
      }}
      rowHeight={60}
      rowSelection
      disableRowSelectionOnClick
      hideFooter
      disableColumnMenu
      disableColumnFilter
      rowCount={pageSize}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      hideFooterPagination
      disableColumnReorder
      rows={rows}
      loading={loading}
      slots={{
        baseCheckbox: Checkbox,
        ...slots,
      }}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
        baseCheckbox: {
          disabled: loading,
        },
        ...slotProps,
      }}
      initialState={{
        pinnedColumns: {
          left: ['desk'],
        },
        ...initialState,
      }}
      {...props}
    />
  );
};

ContactsTable.Pagination = Pagination;
