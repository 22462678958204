import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';

import ModalIcon from './assets/ModalIcon.svg?react';
import { useStartViewCreditsLimitErrorViewModel } from './useStartViewCreditsLimitErrorViewModel';

import styles from './styles.module.scss';

export const StartViewCreditsLimitError: FC = () => {
  const { t } = useTranslation('enrichment', {
    keyPrefix: 'uploadingCreditsLimitErrorModal',
  });
  const { requireCredits, needToChargeCredits } =
    useStartViewCreditsLimitErrorViewModel();

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Icon}>
        <ModalIcon />
      </Box>
      <Typography variant="h3" className={styles.Title}>
        {t('title')}
      </Typography>
      <Typography variant="subtitle1" className={styles.Text}>
        {t('info', {
          require: requireCredits,
          needToCharge: needToChargeCredits,
        })}
      </Typography>
      <CallToAction
        withIcon={false}
        show="getCredits"
        textOverrides={{
          getCredits: t('button'),
        }}
        variant="contained"
        color="gradientOrangeToRed"
        className={styles.Button}
      />
    </Box>
  );
};
