import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import styles from './styles.module.scss';

export const Toolbar: FC = () => {
  const { t } = useTranslation('dashboard');

  return (
    <Box className={styles.toolbar}>
      <Typography variant="h1">{t('recentContacts')}</Typography>
      <Link to={ROUTES.CONTACTS_ENTRY} className={styles.link}>
        {t('viewAll')}
      </Link>
    </Box>
  );
};
