import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { CallToAction } from '@/features/callToAction';

import { Statistic } from '../Statistic';

import { CreditsOverviewLoadingView } from './components/LoadingView';
import { useCreditsOverviewVideModel } from './useCreditsOverviewViewModel';

import styles from './styles.module.scss';

export const CreditsOverview: FC = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoaded, data, hasError } = useCreditsOverviewVideModel();
  const showLoadingView = !isLoaded || hasError;

  return (
    <>
      <Box className={styles.table} sx={{ marginTop: '20px' }}>
        <Box className={styles.tableHead}>
          <Box className={clsx(styles.tableCol, styles.tableColHead)}>
            <Box component="span" className={styles.tableColHeadText}>
              {t('bySources.columns.creditType')}
            </Box>
          </Box>
          <Box className={styles.tableCol}>
            <Box component="span" className={styles.tableColHeadText}>
              {t('bySources.columns.creditsUsage')}
            </Box>
          </Box>
          <Box className={styles.tableCol} />
        </Box>
        {showLoadingView ? (
          <CreditsOverviewLoadingView />
        ) : (
          <>
            {data.bySources.planFull && (
              <Box className={styles.tableRow}>
                <Box className={styles.tableCol}>
                  <Statistic
                    value={data.bySources.planFull.title}
                    description={data.bySources.planFull.description}
                  />
                </Box>
                <Box className={styles.tableCol}>
                  <Statistic
                    value={data.bySources.planFull.credits}
                    variant={
                      data.bySources.planFull.isOutOfCredits ? 'danger' : 'default'
                    }
                  />
                </Box>
                <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}>
                  {!data.bySources.planBulk && data.bySources.planFull.buttonType && (
                    <CallToAction
                      show={'getCredits'}
                      textOverrides={{
                        getCredits:
                          data.bySources.planFull.buttonType === 'getMoreCresits'
                            ? t('bySources.planFull.getMoreCresits')
                            : t('bySources.planFull.upgrade'),
                      }}
                      variant="outlined"
                      size="small"
                      color="gradientOrangeToRed"
                      withIcon={false}
                      className={styles.button}
                    />
                  )}
                </Box>
              </Box>
            )}
            {data.bySources.planBulk && (
              <Box className={styles.tableRow}>
                <Box className={styles.tableCol}>
                  <Statistic
                    value={data.bySources.planBulk.title}
                    description={data.bySources.planBulk.description}
                  />
                </Box>
                <Box className={styles.tableCol}>
                  <Statistic
                    value={data.bySources.planBulk.credits}
                    variant={
                      data.bySources.planBulk.isOutOfCredits ? 'danger' : 'default'
                    }
                  />
                </Box>
                <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}>
                  <CallToAction
                    show="getCredits"
                    textOverrides={{
                      getCredits: t('bySources.planFull.getMoreCresits'),
                    }}
                    variant="outlined"
                    color="gradientOrangeToRed"
                    size="small"
                    className={styles.button}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
        {data?.bySources.referral && (
          <Box className={styles.tableRow}>
            <Box className={styles.tableCol}>
              <Statistic value={data.bySources.referral.title} />
            </Box>
            <Box className={styles.tableCol}>
              <Statistic
                value={data.bySources.referral.credits}
                variant={data.bySources.referral.isOutOfCredits ? 'danger' : 'default'}
              />
            </Box>
            <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
          </Box>
        )}
        {data?.bySources.gift && (
          <Box className={styles.tableRow}>
            <Box className={styles.tableCol}>
              <Statistic value={data.bySources.gift.title} />
            </Box>
            <Box className={styles.tableCol}>
              <Statistic
                value={data.bySources.gift.credits}
                variant={data.bySources.gift.isOutOfCredits ? 'danger' : 'default'}
              />
            </Box>
            <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
          </Box>
        )}
        {data?.bySources.unknown && (
          <Box className={styles.tableRow}>
            <Box className={styles.tableCol}>
              <Statistic value={data.bySources.unknown.title} />
            </Box>
            <Box className={styles.tableCol}>
              <Statistic
                value={data.bySources.unknown.credits}
                variant={data.bySources.unknown.isOutOfCredits ? 'danger' : 'default'}
              />
            </Box>
            <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
          </Box>
        )}
        {data?.bySources.rolledUp && (
          <Box className={styles.tableRow}>
            <Box className={styles.tableCol}>
              <Statistic value={data.bySources.rolledUp.title} />
            </Box>
            <Box className={styles.tableCol}>
              <Statistic
                value={data.bySources.rolledUp.credits}
                variant={data.bySources.rolledUp.isOutOfCredits ? 'danger' : 'default'}
              />
            </Box>
            <Box className={clsx(styles.tableCol, styles.justifyFlexEnd)}></Box>
          </Box>
        )}
      </Box>
    </>
  );
};
