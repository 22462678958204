import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, TablePagination } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { NoRowsOverlay } from './components/NoRowsOverlay';
import { useBillingHistoryViewModel } from './hooks/useBillingHistoryViewModel';

import styles from './styles.module.scss';

export const BillingHistory: FC = () => {
  const { rows, pagination, columns } = useBillingHistoryViewModel();

  const { t } = useTranslation('settings', {
    keyPrefix: 'subscriptions.billingHistory',
  });

  return (
    <Card className={styles.card}>
      <CardHeader title={t('title')} classes={{ title: styles.title }} />
      <CardContent className={styles.content}>
        <DataGridPro
          rows={rows}
          columns={columns}
          getRowId={(row): string => row.transactionId}
          classes={{
            root: styles.table,
            columnHeader: styles.tableHeader,
            columnHeaders: styles.tableHeader,
            columnHeaderTitle: styles.tableHeaderTitle,
            row: styles.tableRow,
            cell: styles.tableCell,
          }}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          disableColumnResize
          disableColumnReorder
          disableColumnMenu
          columnHeaderHeight={36}
          rowHeight={40}
          variant="basic"
          hideFooter
        />
        <TablePagination
          component="div"
          classes={{
            root: styles.tablePagination,
            toolbar: styles.tablePaginationToolbar,
            actions: styles.tablePaginationActions,
          }}
          page={pagination.page}
          count={pagination.total}
          rowsPerPage={pagination.pageSize}
          rowsPerPageOptions={[pagination.pageSize]}
          backIconButtonProps={{ color: 'info', size: 'small', disableRipple: true }}
          nextIconButtonProps={{ color: 'info', size: 'small', disableRipple: true }}
          onPageChange={(_e, page): void => pagination.changePage(page)}
        />
      </CardContent>
    </Card>
  );
};
