import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { CustomSelectV2, Preview } from '@/components';

import { useContactOwnerCellViewModel } from './useContactOwnerCellViewModel';

import styles from './styles.module.scss';

const ContactOwnerCellPreview = (): ReactElement => {
  return (
    <Box className={styles.cellPreview}>
      <Preview />
    </Box>
  );
};

export const ContactOwnerCell: FC<{ assignedTo: string; contactId: string }> & {
  Preview: FC;
} = ({ assignedTo, contactId }) => {
  const { options, handleApply, canChangeContactsOwner } = useContactOwnerCellViewModel({
    assignedTo,
    contactId,
  });

  return (
    <Box className={styles.cell}>
      <ActionDisableGuard>
        <CustomSelectV2
          size="small"
          value={assignedTo}
          options={options}
          onChange={handleApply}
          disabled={!canChangeContactsOwner}
          searchable
        />
      </ActionDisableGuard>
    </Box>
  );
};

ContactOwnerCell.Preview = ContactOwnerCellPreview;
