import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import {
  useAggregatedCreditsProgress,
  useFullAggregatedCreditsInfo,
} from '@/features/common/workspace';

import { LinearProgress } from '@/components/LinearProgress';

import styles from './styles.module.scss';

export const CreditBasedView: FC = () => {
  const { data: fullAggregatedCreditsInfo } = useFullAggregatedCreditsInfo();
  const { data: aggregatedCreditsProgress } = useAggregatedCreditsProgress();
  const { t } = useTranslation('common', {
    keyPrefix: 'header.creditUsage.tootlip.creditBased',
  });

  if (!fullAggregatedCreditsInfo) {
    return null;
  }

  return (
    <Stack alignItems="flex-start" className={styles.wrapper}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.item}
      >
        <Box component="span" className={styles.label}>
          {t('label')}
        </Box>
        <Box component="span" className={styles.value}>
          {fullAggregatedCreditsInfo.isUnlimited
            ? t('unlimited')
            : `${fullAggregatedCreditsInfo.used}/${fullAggregatedCreditsInfo.limit}`}
        </Box>
      </Stack>
      <LinearProgress value={aggregatedCreditsProgress} />
      {fullAggregatedCreditsInfo.isUnlimited ? null : (
        <CallToAction
          show="getCredits"
          textOverrides={{
            getCredits: t('link'),
          }}
          withIcon={false}
          className={styles.link}
          variant="text"
          color="gradientOrangeToRed"
        />
      )}
    </Stack>
  );
};
