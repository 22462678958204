import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { ROUTES } from '@/router/routes';

import { useAuthUseCase } from '@/features/common/auth';

import { SplashScreen } from '@/components';

export const Logout: FC = () => {
  const authUseCase = useAuthUseCase();
  const navigate = useNavigate();

  useEffect(() => {
    (async (): Promise<void> => {
      await authUseCase.signOut();
      navigate(ROUTES.LOGIN);
    })();
  }, []);

  return <SplashScreen />;
};
