import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button, Stack, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { Modal } from '@/components';

type ReduceActiveUsersDialogProps = {
  isOpened: boolean;
  onClose: () => void;
  activeUsers: number;
  usersToReduce: number;
};

export { useReduceActiveUsersDialog } from './useReduceActiveUsersDialog';

export const ReduceActiveUsersDialog: FC<ReduceActiveUsersDialogProps> = ({
  isOpened,
  onClose,
  activeUsers,
  usersToReduce,
}) => {
  const { t } = useTranslation('billing', { keyPrefix: 'reduceActiveUsersDialog' });

  const navigate = useNavigate();

  function handleReduceUsers(): void {
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT);
  }

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack direction="column" gap="25px" maxWidth="340px">
        <Stack direction="column" gap="15px">
          <Typography variant="h2">{t('title')}</Typography>
          <Typography fontSize="14px" lineHeight="20px">
            {t('description', { active: activeUsers, count: usersToReduce })}
          </Typography>
        </Stack>
        <Stack direction="row" gap="35px" alignSelf="flex-end">
          <Button variant="text" color="info" size="small" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleReduceUsers}
          >
            {t('submit')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
