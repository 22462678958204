import { convertDateSelectValueToDateFormat } from '@/components';

import { ContactInfoFilterType } from '../../../common/contact/domain';
import { ContactsTableSortItem } from '../components';

type QuerySortParams = {
  field:
    | 'name'
    | 'job_title'
    | 'company_name'
    | 'company_industry'
    | 'created_at'
    | 'assigned_to';
  sort: 'asc' | 'desc';
};

const mapContactTableSortItemToQuerySortParams = (
  sortItem: ContactsTableSortItem,
): QuerySortParams => {
  const sortFieldsMap: {
    [key in ContactsTableSortItem['field']]?: QuerySortParams['field'];
  } = {
    name: 'name',
    jobTitle: 'job_title',
    company: 'company_name',
    industry: 'company_industry',
    date: 'created_at',
    contactOwner: 'assigned_to',
  };

  return {
    field: sortFieldsMap[sortItem.field] || 'created_at',
    sort: sortItem.sort || 'asc',
  };
};

export const createSearchParameters = (contextState: {
  contactListId?: string;
  search: string;
  filterByUser: string | null;
  filterByDate: string;
  filterByTags: string[];
  filterByExport: string;
  page: number;
  sort: ContactsTableSortItem;
  filterByContactInfo: ContactInfoFilterType[];
  pageSize: number;
}) => {
  const {
    page,
    sort,
    search,
    contactListId,
    filterByTags,
    filterByUser,
    filterByExport,
    filterByDate,
    filterByContactInfo,
    pageSize,
  } = contextState;

  const { from, to } = convertDateSelectValueToDateFormat({
    value: filterByDate,
    unix: true,
  });
  const sortParams = mapContactTableSortItemToQuerySortParams(sort);

  const params = new URLSearchParams();
  params.set('start', String((page - 1) * pageSize || 0));
  params.set('length', String(pageSize));
  params.set('sort_by', sortParams.field);
  params.set('sort_dir', sortParams.sort);
  if (search) {
    params.set('search', search);
  }
  if (contactListId) {
    params.set('contact_list_id', contactListId);
  }
  if (filterByTags.length) {
    params.set('tag_id', filterByTags.join(','));
  }
  if (filterByUser) {
    params.set('assigned_to', filterByUser);
  }
  if (filterByExport) {
    params.set('is_exported', filterByExport);
  }
  if (from && to) {
    params.set('from_dt', String(from));
    params.set('to_dt', String(to));
  }
  params.set('stream', '1');
  filterByContactInfo.forEach((item) => {
    params.append('contact_info', item);
  });

  return params;
};
