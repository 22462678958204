import { FC } from 'react';
import { Navigate } from 'react-router';

import { ErrorPage404, ErrorPage500 } from '@/router/error';
import { ROUTES } from '@/router/routes';

import { SplashScreen } from '@/components/SplashScreen';

import { IntegrationNotFoundError, SalesforceApiDisabledError } from '../domain';

import { SalesforceApiDisabledErrorDialog } from './components/SalesforceApiDisabledErrorDialog';
import { SubHeadIntegration, TabName } from './components/Subhead';
import { FieldMappingsTab } from './components/Tabs/FieldMappingTab';
import { SettingsTab } from './components/Tabs/SettingsTab';
import { useIntegrationViewModel } from './IntegrationViewModel';

export const Integration: FC = () => {
  const { data, handleTabChange, tab, providerName } = useIntegrationViewModel();

  if (data.hasError) {
    switch (true) {
      case data.error instanceof IntegrationNotFoundError:
        return <ErrorPage404 />;
      case data.error instanceof SalesforceApiDisabledError:
        return <SalesforceApiDisabledErrorDialog isOpen />;
      default:
        return <ErrorPage500 />;
    }
  }

  if (data.isLoading) {
    return <SplashScreen />;
  }

  const { data: integrationConfig } = data;

  if (!integrationConfig.settings.connectionActive) {
    return <Navigate to={ROUTES.INTEGRATIONS} />;
  }

  return (
    <>
      <SubHeadIntegration
        providerName={providerName}
        handleTabChange={handleTabChange}
        currentTab={tab}
      />
      {tab === TabName.Settings && <SettingsTab settings={integrationConfig.settings} />}
      {tab === TabName.FieldMapping && <FieldMappingsTab config={integrationConfig} />}
    </>
  );
};
