import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button } from '@mui/material';
import { Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { ActionDisableGuard } from '@/features/common/permissions';

import { Preview } from '@/components';

import { CommonActionProps } from '../types';

import styles from './styles.module.scss';

type ManageTagsActionProps = CommonActionProps;

export const ManageTagsAction: FC<ManageTagsActionProps> = ({ isLoading }) => {
  const { t } = useTranslation('lists');
  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
  };

  return (
    <Box className={styles.manageTags}>
      {isLoading ? (
        <Preview />
      ) : (
        <ActionDisableGuard>
          <Button
            color="info"
            title={t('subhead.tagsManager.button')}
            onClick={handleClick}
            variant="outlined"
          >
            <Typography variant="textInButton">
              {t('subhead.tagsManager.button')}
            </Typography>
          </Button>
        </ActionDisableGuard>
      )}
    </Box>
  );
};
