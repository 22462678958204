import { FC } from 'react';
import {
  Box,
  Modal as MuiModal,
  ModalOwnProps,
  ModalProps as MuiModalProps,
} from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export type ModalProps = MuiModalProps & {
  containerClass?: string;
  onClose: ModalOwnProps['onClose'];
  open: boolean;
};

export const Modal: FC<ModalProps> = ({
  open,
  onClose,
  children,
  id,
  containerClass,
  ...props
}) => {
  return (
    <MuiModal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      id={id}
      open={open}
      onClose={onClose}
      {...props}
    >
      <Box className={clsx(styles.popUpContainer, containerClass)}>{children}</Box>
    </MuiModal>
  );
};
