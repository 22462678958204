import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Box, OutlinedInput, Typography } from '@mui/material';

import { UnauthenticatedLayout } from '@/router/layouts';

import { AuthIdentityProvider } from '@/features/common/auth';

import { PasswordInput } from '@/components';

import { useDocumentMeta } from '@/hooks';

import { useLoginViewModel } from './useLoginViewModel';

import styles from './styles.module.scss';

export const LoginForm: React.FC = () => {
  const {
    loginWithProvider,
    loginWithEmailAndPass,
    error,
    form,
    signUpRoute,
    forgotPasswordRoute,
  } = useLoginViewModel();
  const { t } = useTranslation('auth');
  const { t: validationTranslate } = useTranslation('validation');

  useDocumentMeta({ title: 'signIn.title', description: 'signIn.description' });

  return (
    <UnauthenticatedLayout.Container>
      <UnauthenticatedLayout.Title>{t('signin.title')}</UnauthenticatedLayout.Title>
      <UnauthenticatedLayout.Subtitle color="info" fontSize="13px">
        {t('signin.description')}
      </UnauthenticatedLayout.Subtitle>
      <UnauthenticatedLayout.SocialButton
        variant="google"
        onClick={(): void => void loginWithProvider(AuthIdentityProvider.google)}
      >
        {t('signin.buttonTitle', { provider: 'Google' })}
      </UnauthenticatedLayout.SocialButton>
      <UnauthenticatedLayout.SocialButton
        variant="microsoft"
        onClick={(): void => void loginWithProvider(AuthIdentityProvider.microsoft)}
      >
        {t('signin.buttonTitle', { provider: 'Microsoft' })}
      </UnauthenticatedLayout.SocialButton>

      <UnauthenticatedLayout.Divider />

      <UnauthenticatedLayout.Form>
        <UnauthenticatedLayout.FormField
          label={t('signin.emailFieldLabel')}
          error={validationTranslate(form.formState.errors.email?.message || '')}
          required
        >
          <OutlinedInput
            placeholder={t('common:emailPlaceholder')}
            {...form.register('email')}
          />
        </UnauthenticatedLayout.FormField>

        <UnauthenticatedLayout.FormField
          label={t('signin.passwordFieldLabel')}
          error={validationTranslate(form.formState.errors.password?.message || '')}
          required
        >
          <PasswordInput
            type={'password'}
            placeholder={'Password'}
            {...form.register('password')}
          />
        </UnauthenticatedLayout.FormField>

        <Box className={styles.passwordAdditionalContainer}>
          <Link to={forgotPasswordRoute} className={styles.linkPassword}>
            {t('signin.forgotPassword')}
          </Link>
        </Box>

        <UnauthenticatedLayout.SubmitButton
          onClick={loginWithEmailAndPass}
          disabled={form.formState.isSubmitting}
        >
          {t('signin.submitButton')}
        </UnauthenticatedLayout.SubmitButton>

        <Typography
          variant={'subtitle1'}
          className={styles.dontHaveAccount}
          fontSize="15px"
        >
          <Trans t={t} i18nKey={'signin.createAccount'}>
            Don’t have an account yet?
            <Link to={signUpRoute} className={styles.linkCreate}>
              Sign up
            </Link>
          </Trans>
        </Typography>
        {error && (
          <UnauthenticatedLayout.ErrorAlert>
            {error.text}
          </UnauthenticatedLayout.ErrorAlert>
        )}
      </UnauthenticatedLayout.Form>
    </UnauthenticatedLayout.Container>
  );
};
