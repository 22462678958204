import React from 'react';
import { Box } from '@mui/material';

import styles from './styles.module.scss';

export const SplashScreen: React.FC = () => {
  return (
    <Box className={styles.Wrapper}>
      <img alt="Loading..." src="/images/splash.gif" width="300px" />
    </Box>
  );
};
