import { browserTracingIntegration, init, replayIntegration } from '@sentry/react';

import { appConfig } from '@/config/AppConfig';

export const initSentry = (): void => {
  init({
    dsn: appConfig.sentry.dsn,
    integrations: [browserTracingIntegration(), replayIntegration()],
    enabled: appConfig.sentry.enabled,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: appConfig.env,
    release: `powerlead-web@${appConfig.version}`,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Request failed with status code 401',
      'Network Error',
    ],
    // beforeSend is needed for Sentry.captureException correct work
    beforeSend(err) {
      return err;
    },
  });
};
