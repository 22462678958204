import { FC } from 'react';
import { Box } from '@mui/material';

import iconHubspot from '@/assets/icons/integrations/hubspot.png';
import iconSalesforce from '@/assets/icons/integrations/salesforce.png';
import iconZoho from '@/assets/icons/integrations/zoho.png';

import styles from './styles.module.scss';

type IntegrationsMenuProps = { links: { link: string; provider: string }[] };

const ProviderIconMap = {
  hubspot: iconHubspot,
  salesforce: iconSalesforce,
  zoho: iconZoho,
};

export const IntegrationsMenu: FC<IntegrationsMenuProps> = ({ links }) => {
  const handleClick = (link: string): void => {
    window.open(link, '_blank');
  };

  return (
    <>
      {links.map(({ link, provider }) => {
        return (
          <Box key={link} className={styles.link} onClick={(): void => handleClick(link)}>
            <img
              src={ProviderIconMap[provider] || ''}
              alt={provider}
              className={styles.icon}
            />
          </Box>
        );
      })}
    </>
  );
};
