import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { clsx } from 'clsx';

import { UnauthenticatedLayout } from '@/router/layouts';
import { EXTERNAL_ROUTES } from '@/router/routes';

import {
  FormField,
  PasswordInput,
  PasswordValidationTips,
  PhoneInput,
} from '@/components';

import { trackGtmEvent } from '@/utils/gtm';

import useCreateAccountViewModel from './useCreateAccountViewModel';

import styles from './styles.module.scss';

export const CreateAccount: FC = () => {
  const {
    onSubmit,
    onEmailChange,
    onPhoneChange,
    error,
    form,
    disabled,
    onCountryChange,
  } = useCreateAccountViewModel();
  const { t } = useTranslation('auth');
  const { t: validationTranslate } = useTranslation('validation');
  const password = form.watch('password');
  const country = form.watch('country');

  return (
    <Box className={styles.wrapper}>
      <Typography className={styles.title} variant={'h1'}>
        {t('signup.title')}
      </Typography>
      <Typography variant={'subtitle3'} className={styles.description}>
        {t('signup.description')}
      </Typography>
      <Box className={styles.inputsWrapper}>
        <Box className={styles.inputContainer}>
          <FormField
            error={!!form.formState.errors.email}
            errorText={validationTranslate(form.formState.errors.email?.message || '')}
            labelText={t('createAccount.emailFieldLabel')}
            disabled
          >
            <OutlinedInput
              {...form.register('email')}
              className={clsx(styles.input, styles.disabledInput)}
              disabled
              endAdornment={
                <Typography className={styles.edit} onClick={onEmailChange}>
                  {t('createAccount.changeEmail')}
                </Typography>
              }
            />
          </FormField>
        </Box>
        <Box className={styles.inputContainer}>
          <FormField
            error={!!form.formState.errors.fullName}
            errorText={validationTranslate(form.formState.errors.fullName?.message || '')}
            labelText={t('createAccount.fullNameLabel')}
            required
          >
            <OutlinedInput
              {...form.register('fullName')}
              className={styles.input}
              placeholder={t('createAccount.fullNamePlaceholder')}
              autoFocus
            />
          </FormField>
        </Box>
        <Box className={styles.inputContainer}>
          <FormField
            error={!!form.formState.errors.phone}
            errorText={validationTranslate(form.formState.errors.phone?.message || '')}
            labelText={t('createAccount.phoneNumberLabel')}
            required
            disabled={disabled}
          >
            <PhoneInput
              placeholder={t('createAccount.phoneCountryPlaceholder')}
              hasError={!!form.formState.errors.phone}
              country={country}
              phone={form.getValues().phone}
              onPhoneChange={onPhoneChange}
              onCountryChange={onCountryChange}
            />
          </FormField>
        </Box>
        {typeof password === 'string' && (
          <Box className={styles.inputContainer}>
            <FormField
              error={!!form.formState.errors.password}
              labelText={t('createAccount.passwordLabel')}
              required
              disabled={disabled}
            >
              <PasswordInput
                {...form.register('password')}
                className={styles.input}
                type={'password'}
                placeholder={t('createAccount.passwordLabel')}
              />
            </FormField>
            <PasswordValidationTips password={password} />
          </Box>
        )}
        {error && (
          <UnauthenticatedLayout.ErrorAlert>
            {error.text}
          </UnauthenticatedLayout.ErrorAlert>
        )}
        <Button
          onClick={(): void => {
            trackGtmEvent({ event: 'get started' });
            onSubmit();
          }}
          variant={'contained'}
          disabled={disabled}
          className={styles.submit}
        >
          {t('createAccount.SubmitButton')}
        </Button>
        <Box className={styles.privscyPolicy}>
          <Typography variant={'subtitle1'} className={styles.privscyPolicyText}>
            <Trans t={t} i18nKey={'createAccount.privacyAndTerms'}>
              By signing up you agree to the
              <Link to={EXTERNAL_ROUTES.TERMS}>Terms of Service</Link>&
              <Link to={EXTERNAL_ROUTES.PRIVACY}>Privacy Policy</Link>
            </Trans>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
