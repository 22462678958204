import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import type { MappedObjectType } from '@/features/integration/domain/entities';

import { FieldOption } from '../types';

import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { useTableViewModel } from './useTableViewModel';

import styles from './styles.module.scss';

type TableProps = {
  objectType: MappedObjectType;
  providerName: string;
  providerFieldOptions: FieldOption[];
  powerleadFieldOptions: FieldOption[];
  requiredProviderFields?: string[];
};

export const Table: FC<TableProps> = ({
  objectType,
  providerName,
  providerFieldOptions = [],
  powerleadFieldOptions = [],
  requiredProviderFields = [],
}) => {
  const { addButtonDisabled, fields, handleAppendField, handleRemoveField, control } =
    useTableViewModel({
      objectType,
      providerOptionsCount: providerFieldOptions.length,
    });

  const { t } = useTranslation('integrations');
  return (
    <Box className={styles.table}>
      <TableHeader provider={providerName} />
      <Box className={styles.tableRowsWrapper}>
        {fields.map((field, index) => {
          return (
            <TableRow
              name={`${objectType}.${index}`}
              canBeDeleted={
                fields.length > 1 &&
                !requiredProviderFields.includes(field.providerField ?? '')
              }
              providerFieldOptions={providerFieldOptions}
              powerleadFieldOptions={powerleadFieldOptions}
              key={field.id}
              control={control}
              onDelete={(): void => handleRemoveField(index)}
            />
          );
        })}
      </Box>
      <Button
        variant="text"
        disabled={addButtonDisabled}
        className={styles.addFieldAction}
        onClick={handleAppendField}
      >
        {t('integration.tabs.fieldMapping.addValue')}
      </Button>
    </Box>
  );
};
