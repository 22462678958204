import { DependencyList, useMemo } from 'react';
import debounce from 'lodash/debounce';

export const useDebounceCallback = <T extends (...args: any) => ReturnType<T>>(
  callback: T,
  wait: number,
  deps: DependencyList,
): T => {
  return useMemo(() => {
    return debounce(callback, wait, {
      leading: false,
      trailing: true,
    }) as unknown as T;
  }, deps);
};
