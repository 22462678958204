import { version } from '../../package.json' with { type: 'json' };

export const appConfig = {
  version,
  env: import.meta.env.REACT_APP_ENVIRONMENT as string,
  domain: import.meta.env.REACT_APP_SUBDOMAIN as string,
  api: {
    get url(): string {
      const envApiUrl = import.meta.env.REACT_APP_API;
      try {
        return localStorage.getItem('API_URL') ?? envApiUrl;
      } catch {
        return envApiUrl;
      }
    },
  },
  firebase: {
    apiKey: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
  stripe: {
    publishableKey: import.meta.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
  },
  extension: {
    id: import.meta.env.REACT_APP_EXTENSION_ID as string,
    url: import.meta.env.REACT_APP_EXTENSION_URL as string,
  },
  hotjar: {
    id: parseInt(import.meta.env.REACT_APP_HOTJAR_SITE_ID),
    version: parseInt(import.meta.env.REACT_APP_HOTJAR_VERSION),
    enabled: import.meta.env.REACT_APP_HOTJAR_ENABLED === 'true',
  },
  sentry: {
    dsn: import.meta.env.REACT_APP_POWERLEAD_SENTRY_DSN as string,
    enabled: import.meta.env.PROD,
  },
  amplitude: {
    apiKey: import.meta.env.REACT_APP_AMPLITUDE_API_KEY as string,
  },
  intercom: {
    appId: import.meta.env.REACT_APP_HELP_CENTER_APP_ID as string,
  },
  userPilot: {
    appToken: import.meta.env.REACT_APP_USERPILOT_APP_TOKEN as string,
  },
  marketingSite: {
    url: import.meta.env.REACT_APP_POWERLEAD_MARKETING_SITE_DOMAIN as string,
  },
  recaptcha: {
    siteKey: import.meta.env.REACT_APP_RECAPTCHA_KEY as string,
  },
  featureRequest: {
    url: import.meta.env.REACT_APP_SUGGEST_FEATURE_URL as string,
  },
  hubspot: {
    syncAccountEnabled: import.meta.env.REACT_APP_FEATURE_HUBSPOT_SUGNUP === 'true',
    accountRegisteredForm: import.meta.env
      .REACT_APP_HUBSPOT_ACCOUNT_REGISTERED_FORM as string,
  },
  // TODO: this conflicts with Feature Flags module in app, should find a way to merge them
  featureFlags: {
    taggedEmailsEnabled: import.meta.env.REACT_APP_ALLOW_TAGGED_EMAILS === 'true',
  },
};

export type AppConfig = typeof appConfig;
