import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useSubscriptionUseCase } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, Platform, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useConfirmationModal } from '@/components';

import { downloadFileByUrl } from '@/utils/downloadFileByUrl';
import { useObservableResult } from '@/utils/rx';

import { ContactExportNotAllowedError } from '../../domain';

import { useContactUseCase } from './useContactUseCase';

type ProviderData =
  | { provider: string; ids: string[] }
  | { provider: string; searchParams: URLSearchParams };

export const useExportContacts = (): {
  onExportToCsvAll: () => Promise<void>;
  onExportToCsvByFilters: (queryParams: URLSearchParams) => Promise<void>;
  onExportToCsvByIds: (ids: string[]) => Promise<void>;
  onExportToIntegration: (
    data:
      | { provider: string; ids: string[] }
      | { provider: string; searchParams: URLSearchParams },
  ) => Promise<void>;
} => {
  const { openModal } = useConfirmationModal();
  const contactUseCase = useContactUseCase();
  const { t } = useTranslation('contacts');
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const appLogger = useAppLogger();
  const { enqueueSnackbar } = useSnackbar();
  const onboardingUseCase = useOnboardingUseCase();
  const subscriptionUseCase = useSubscriptionUseCase();

  const { data: isCustomPlan } = useObservableResult(() =>
    subscriptionUseCase.getIsCustomPlan(),
  );

  const handleExportError = (error: Error): void => {
    switch (true) {
      case error instanceof ContactExportNotAllowedError:
        openModal({
          title: t('exportGuardModal.title'),
          description: t('exportGuardModal.description'),
          buttonText: t('exportGuardModal.upgradeButton'),
          handleAccept: () => {
            if (isCustomPlan) {
              window.open(EXTERNAL_ROUTES.TALK_TO_SALES);
              return;
            }

            navigate(ROUTES.BILLING.PLANS);
          },
          acceptButtonProps: {
            color: 'primary',
          },
        });
        break;
      default:
        enqueueSnackbar(t('csvExportErrorMessage'), {
          variant: 'error',
        });
        appLogger.error(error);
    }
  };

  const onExportToCsvAll = async (): Promise<void> => {
    try {
      const { linkToDownload } = await contactUseCase.exportToCsvAll();
      downloadFileByUrl(linkToDownload, 'allContacts.csv');
      analytics.trackEvent(ANALYTICS_EVENTS.EXPORT_COMPLETED, {
        platform: Platform.Web,
      });
      await onboardingUseCase.completeExportContactStep();
    } catch (error) {
      handleExportError(error);
      throw error;
    }
  };

  const onExportToCsvByFilters = async (queryParams: URLSearchParams): Promise<void> => {
    try {
      const { linkToDownload } = await contactUseCase.exportToCsvByFilters(queryParams);
      downloadFileByUrl(linkToDownload, 'contacts.csv');
      analytics.trackEvent(ANALYTICS_EVENTS.EXPORT_COMPLETED, {
        platform: Platform.Web,
      });
      await onboardingUseCase.completeExportContactStep();
    } catch (error) {
      handleExportError(error);
      throw error;
    }
  };

  const onExportToCsvByIds = async (ids: string[]): Promise<void> => {
    try {
      const { linkToDownload } = await contactUseCase.exportToCsvByIds(ids);
      downloadFileByUrl(linkToDownload, 'contacts.csv');
      analytics.trackEvent(ANALYTICS_EVENTS.EXPORT_COMPLETED, {
        platform: Platform.Web,
      });
      await onboardingUseCase.completeExportContactStep();
    } catch (error) {
      handleExportError(error);
      throw error;
    }
  };

  const onExportToIntegration = async (data: ProviderData): Promise<void> => {
    try {
      const result = await contactUseCase.exportToIntegration(data);
      await onboardingUseCase.completeExportContactStep();
      return result;
    } catch (error) {
      handleExportError(error);
      throw error;
    }
  };

  return {
    onExportToCsvAll,
    onExportToCsvByFilters,
    onExportToCsvByIds,
    onExportToIntegration,
  };
};
