import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  IIntegrationSettingsEntity,
  MappedObjectType,
} from '@/features/integration/domain';

type TabOption = {
  value: MappedObjectType;
  label: string;
};

type UseFieldMappingTabs = (params: { settings: IIntegrationSettingsEntity }) => {
  value: MappedObjectType;
  handleChange: (tab: MappedObjectType) => void;
  options: TabOption[];
};

export const useFieldMappingTabs: UseFieldMappingTabs = ({ settings }) => {
  const { t } = useTranslation('integrations', {
    keyPrefix: 'integration.tabs.settings.preferences',
  });

  const [value, setValue] = useState(settings.supportedObjects[0]);

  const options = useMemo<TabOption[]>(
    () =>
      settings.supportedObjects.map((objectType) => ({
        value: objectType,
        label: t(`entities.${objectType}`),
      })),
    [settings.supportedObjects],
  );

  return {
    value,
    handleChange: setValue,
    options,
  };
};
