import { useState } from 'react';
import { useParams } from 'react-router';

import { ObservableResult, useObservableResult } from '@/utils/rx';

import type { IIntegrationConfigEntity } from '../domain';

import { TabName } from './components';
import { useIntegrationUseCase, useProviderName } from './hooks';

export const useIntegrationViewModel = (): {
  data: ObservableResult<IIntegrationConfigEntity>;
  tab: TabName;
  handleTabChange: (newTab: TabName) => void;
  providerName: ReturnType<typeof useProviderName>;
} => {
  const { id: providerId = '' } = useParams();
  const integrationUseCase = useIntegrationUseCase();

  const data = useObservableResult(() =>
    integrationUseCase.getIntegrationConfig(providerId),
  );

  const [tab, setTab] = useState<TabName>(TabName.Settings);

  const providerName = useProviderName(providerId);

  const handleTabChange = (newTab: TabName): void => {
    setTab(newTab);
  };

  return {
    data,
    tab,
    handleTabChange,
    providerName,
  };
};
