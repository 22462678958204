import type { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const { variant, color, size } = ownerState as {
        variant: string;
        color: string;
        size: string;
      };

      let sizeStyle = {};

      switch (size) {
        case 'small':
          sizeStyle = {
            minHeight: 28,
            fontSize: 11,
            padding: '0 12px',
          };
          break;
        case 'medium':
          sizeStyle = {
            minHeight: 34,
            fontSize: 13,
            padding: '0 14px',
          };
          break;
        case 'large':
          sizeStyle = {
            minHeight: 40,
            fontSize: 14,
            padding: '0 16px',
          };
          break;
      }

      const commonStyle = {
        '.MuiButton-startIcon': {
          margin: '0 4px 0 0',
        },
        '&.MuiButton-root': {
          lineHeight: 1,
          textTransform: 'none',
          boxShadow: 'none',
          ...sizeStyle,
        },
      };

      if (color === 'gradientOrangeToRed') {
        switch (variant) {
          case 'contained':
            return {
              ...commonStyle,
              backgroundImage: `linear-gradient(to right, #feb100, #ff2264)`,
              color: 'white',
              transition: 'none',
              '&:hover:not(.Mui-disabled)': {
                backgroundColor: theme.palette.grey700,
                backgroundImage: 'none',
              },
              '&.Mui-disabled': {
                opacity: 0.5,
                color: 'white',
              },
            };
          case 'outlined':
            return {
              ...commonStyle,
              backgroundImage: `linear-gradient(to right, #feb100, #ff2264)`,
              border: `1px solid ${theme.palette.grey300}`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              '.MuiButton-startIcon': {
                color: '#feb100',
              },
              '.MuiButton-endIcon': {
                color: '#ff2264',
              },
              '&:hover:not(.Mui-disabled)': {
                background: theme.palette.grey700,
                borderColor: 'transparent',
                WebkitBackgroundClip: 'initial',
                WebkitTextFillColor: 'initial',
                color: 'white',
                '.MuiButton-startIcon': {
                  color: 'white',
                },
                '.MuiButton-endIcon': {
                  color: 'white',
                },
              },
              '&.Mui-disabled': {
                opacity: 0.5,
              },
            };
          case 'text':
            return {
              ...commonStyle,
              background: '-webkit-linear-gradient(60deg, #feb100, #ff2264)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              '&:hover': {
                textDecoration: 'none',
              },
              '&.Mui-disabled': {
                background:
                  '-webkit-linear-gradient(60deg, rgba(254, 177, 0, 0.5), rgba(255, 34, 100, 0.5))',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              },
              '.MuiTouchRipple-root': {
                display: 'none',
              },
            };
        }
      }

      switch (variant) {
        case 'contained':
          return {
            ...commonStyle,
            '&.Mui-disabled': {
              backgroundColor: theme.palette[color]?.disabled,
              color: 'white',
            },
            '&:hover': {
              boxShadow: 'none',
            },
          };
        case 'outlined':
          return {
            ...commonStyle,
            '&:hover': {
              backgroundColor: theme.palette[color]?.dark,
              borderColor: theme.palette[color]?.dark,
              color: 'white',
            },
            '&.Mui-disabled': {
              borderColor: theme.palette[color]?.disabled,
              color: theme.palette[color]?.disabled,
            },
          };
        case 'text':
          return {
            ...commonStyle,
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
            '&.Mui-disabled': {
              background: 'none',
              color: theme.palette[color]?.disabled,
            },
            '.MuiTouchRipple-root': {
              display: 'none',
            },
          };
      }
    },
  },
};
