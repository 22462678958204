import { MouseEvent, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import { ROUTES } from '@/router/routes';

import { useInjection } from '@/ioc/ioc.react';
import { CONTACT_LIST_TYPES } from '@/ioc/types';

import { useAccount } from '@/features/common/account';
import {
  ContactExportNotAllowedError,
  useContactExportProgress,
  useExportContacts,
} from '@/features/common/contact';
import { IContactListUseCase } from '@/features/common/contactList';
import { IContactListEntityUI } from '@/features/common/contactList';
import { Permission, usePermissions } from '@/features/common/permissions';
import { useIntegrations } from '@/features/integrations';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useConfirmationModal } from '@/components';

import { useObservableResult } from '@/utils/rx/hooks';

type ExportOption = {
  label: string;
  value: string;
  icon?: string;
};

type UseActionsCellResult = {
  menuOpen: boolean;
  anchorEl?: Element;
  exportDisabled: boolean;
  exportIntegrationOptions: ExportOption[];
  exportProgress: ReturnType<typeof useContactExportProgress>;
  isAccessableToEdit: boolean;
  isAccessableToDelete: boolean;
  onOpenDeleteModal: () => void;
  onOpenMenu: (e: MouseEvent<HTMLButtonElement>) => void;
  onCloseMenu: () => void;
  onStartRenameList: () => void;
  onRetry: () => Promise<void>;
  onExportToCsv: () => Promise<void>;
  onExportToIntegration: (option: ExportOption) => Promise<void>;
  onManageIntegrations: () => void;
};

export const useActionsCellViewModel = (
  row: IContactListEntityUI,
): UseActionsCellResult => {
  const retryFunction = useRef<(() => Promise<void>) | null>(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>();
  const [menuOpen, setMenuOpen] = useState(false);
  const contactListUseCase = useInjection<IContactListUseCase>(
    CONTACT_LIST_TYPES.ContactListUseCase,
  );
  const { t } = useTranslation(['lists', 'contacts']);
  const apiRef = useGridApiContext();
  const { openModal } = useConfirmationModal();
  const {
    onExportToCsvByFilters: exportToCsv,
    onExportToIntegration: exportToIntegration,
  } = useExportContacts();
  const { trackEvent } = useAnalytics();
  const { hasPermissions } = usePermissions();
  const { account } = useAccount();
  const { data: currentList } = useObservableResult(
    () => contactListUseCase.getContactListById(row.id),
    { deps: [row.id] },
  );
  const isCurrentUserCreator = account?.uuid === currentList?.createdBy;
  const { data: integrations } = useIntegrations();
  const exportProgress = useContactExportProgress();
  const exportDisabled = row.contactsAmount <= 0;

  const exportIntegrationOptions = useMemo<ExportOption[]>(() => {
    return integrations
      .filter((i) => i.status === 'connected')
      .map((integration) => ({
        label: t('table.rows.actions.integrationExport', {
          label: integration.name,
          ns: 'contacts',
        }),
        icon: integration.icon,
        value: integration.id,
      }));
  }, [integrations]);

  const close = (): void => {
    setAnchorEl(undefined);
    setMenuOpen(false);
  };

  const onExportToCsv = async (): Promise<void> => {
    try {
      setMenuOpen(false);
      retryFunction.current = (): Promise<void> => onExportToCsv();
      exportProgress.setConfig(() => ({
        open: true,
        status: 'pending',
        provider: { key: 'csv', label: t('table.rows.actions.csvExport') },
      }));
      await exportToCsv(new URLSearchParams({ contact_list_id: row.id }));
      exportProgress.setConfig(() => ({
        status: 'finished',
      }));
    } catch (error) {
      if (error instanceof ContactExportNotAllowedError) {
        exportProgress.setConfig(() => ({
          open: false,
        }));
        throw error;
      }
      exportProgress.setConfig(() => ({ status: 'failed' }));
      throw error;
    }
  };

  const onExportToIntegration = async (option: ExportOption): Promise<void> => {
    try {
      setMenuOpen(false);
      retryFunction.current = (): Promise<void> => onExportToIntegration(option);
      exportProgress.setConfig(() => ({
        open: true,
        status: 'pending',
        provider: { key: 'integration', label: option.label },
      }));
      await exportToIntegration({
        provider: option.value,
        searchParams: new URLSearchParams({ contact_list_id: row.id }),
      });
      exportProgress.setConfig(() => ({
        status: 'finished',
      }));
    } catch (error) {
      if (error instanceof ContactExportNotAllowedError) {
        exportProgress.setConfig(() => ({
          open: false,
        }));
        throw error;
      }

      exportProgress.setConfig(() => ({
        status: 'failed',
      }));
      throw error;
    }
  };

  const onRetry = async (): Promise<void> => {
    if (!retryFunction.current) return;

    await retryFunction.current();
  };

  const onOpenMenu = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setMenuOpen(true);
  };

  const onStartRenameList = (): void => {
    setMenuOpen(false);
    apiRef.current.startCellEditMode({
      id: row.id,
      field: 'name',
    });
  };

  const onConfirmDeleteContactList = async (): Promise<void> => {
    await contactListUseCase.deleteContactList(row.id);
    trackEvent(ANALYTICS_EVENTS.DELETE_LIST);
  };

  const onOpenDeleteModal = (): void => {
    openModal({
      title: t('deleteModal.title'),
      description: (
        <Typography color="common" variant="h3">
          <Trans
            t={t}
            i18nKey={'deleteModal.description'}
            values={{ list: row.name.replace('<', '&#60;').replace('>', '&#62;') }}
            components={{ b: <b /> }}
            shouldUnescape
          />
        </Typography>
      ),
      buttonText: t('deleteModal.confirmButton'),
      handleAccept: onConfirmDeleteContactList,
    });
  };

  const onManageIntegrations = (): void => {
    navigate(ROUTES.INTEGRATIONS);
  };

  return {
    exportDisabled,
    exportIntegrationOptions,
    menuOpen,
    onStartRenameList,
    anchorEl,
    isAccessableToDelete:
      (isCurrentUserCreator || hasPermissions(Permission.CanManageContactLists)) &&
      row.specialType !== 'all_contacts',
    isAccessableToEdit:
      (isCurrentUserCreator || hasPermissions(Permission.CanManageContactLists)) &&
      row.specialType !== 'all_contacts',
    exportProgress,
    onExportToCsv,
    onExportToIntegration,
    onManageIntegrations,
    onRetry,
    onCloseMenu: close,
    onOpenDeleteModal,
    onOpenMenu,
  };
};
