import { FC, SVGAttributes, useId } from 'react';

export const LogoBase: FC<SVGAttributes<SVGSVGElement>> = (props) => {
  const idClipPath = useId();
  const idGradient = useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37.45"
      height="37.45"
      {...props}
      viewBox="0 0 37.45 37.45"
    >
      <defs>
        <linearGradient
          id={idGradient}
          x1="1"
          y1="1"
          x2="0"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff2264" />
          <stop offset="1" stopColor="#feb100" />
        </linearGradient>
        <clipPath id={idClipPath}>
          <rect width="15.601" height="21.208" fill="#fff" />
        </clipPath>
      </defs>
      <g transform="translate(15751 17648)">
        <rect
          width="37.45"
          height="37.45"
          rx="5"
          transform="translate(-15751 -17648)"
          fill={`url(#${idGradient})`}
        />
        <g clipPath={`url(#${idClipPath})`} transform="translate(-15740.075 -17639.4)">
          <path
            d="M14.865 1.85A4.735 4.735 0 0 0 10.893 0H2.222L.763 7.218l1.873.042L.713 17.844v.009L.34 20.267l.036.007v.065L6.248 5.424H4.7l.314-1.947h2.36v.007h2.844a1.888 1.888 0 0 1 1.588.7 2.17 2.17 0 0 1 .174 1.975 4.729 4.729 0 0 1-4.092 3.108h-.855l-1.354 3.484H7.21A9.3 9.3 0 0 0 15.4 6.59a5.232 5.232 0 0 0-.532-4.74"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
