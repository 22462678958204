import { FC, ReactNode } from 'react';
import { Link } from 'react-router';
import { Box } from '@mui/material';

import { Logo } from '@/components/Icons';

import {
  Container,
  Divider,
  ErrorAlert,
  Form,
  FormField,
  PrivacyAndTerms,
  RightContent,
  SocialButton,
  SubmitButton,
  Subtitle,
  Title,
} from './components';

import styles from './styles.module.scss';

import { useAppConfig } from '@/config';

type UnauthenticatedLayout = {
  leftSlot: ReactNode;
  privacyAndTerms?: boolean;
};

type UnauthenticatedLayoutFC = FC<UnauthenticatedLayout> & {
  Title: typeof Title;
  Subtitle: typeof Subtitle;
  SocialButton: typeof SocialButton;
  Divider: typeof Divider;
  ErrorAlert: typeof ErrorAlert;
  Container: typeof Container;
  Form: typeof Form;
  FormField: typeof FormField;
  SubmitButton: typeof SubmitButton;
};

export const UnauthenticatedLayout: UnauthenticatedLayoutFC = ({
  leftSlot,
  privacyAndTerms = false,
}) => {
  const { marketingSite } = useAppConfig();

  return (
    <Box className={styles.wrapperLayout}>
      <Box className={styles.left}>
        <Box className={styles.logoWrapper}>
          <Link target={'_blank'} to={marketingSite.url}>
            <Logo />
          </Link>
        </Box>
        <Box className={styles.content}>
          {leftSlot}
          {privacyAndTerms ? <PrivacyAndTerms /> : null}
        </Box>
      </Box>
      <Box className={styles.right}>
        <Box className={styles.content}>
          <RightContent />
        </Box>
      </Box>
    </Box>
  );
};

UnauthenticatedLayout.Title = Title;
UnauthenticatedLayout.Subtitle = Subtitle;
UnauthenticatedLayout.SocialButton = SocialButton;
UnauthenticatedLayout.Divider = Divider;
UnauthenticatedLayout.ErrorAlert = ErrorAlert;
UnauthenticatedLayout.Container = Container;
UnauthenticatedLayout.Form = Form;
UnauthenticatedLayout.FormField = FormField;
UnauthenticatedLayout.SubmitButton = SubmitButton;
