/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { InferType, object, string } from 'yup';

import type { IIntegrationEntity } from '@/features/integrations/domain';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';

import { useAsyncExecutor } from '@/hooks';

import { useFormWithSchema } from '@/utils/validation';

import { useIntegrationsUseCase } from '../../../../hooks';

const IntegrationRequestFormSchema = object({
  message: string().required().min(1),
  title: string().required(),
  id: string().required(),
});

type IntegrationRequestFormType = InferType<typeof IntegrationRequestFormSchema>;

export const useIntegrationRequestDialogViewModel = (integration: IIntegrationEntity) => {
  const analytics = useAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const integrationsUseCase = useIntegrationsUseCase();
  const { t } = useTranslation(['integrations', 'common']);

  const form = useFormWithSchema(IntegrationRequestFormSchema, {
    defaultValues: {
      message: '',
      title: integration.name,
      id: integration.id,
    },
  });

  const sendRequestAsyncExecutor = useAsyncExecutor(
    async () => {
      await form.handleSubmit(
        async ({ message, title, id }: IntegrationRequestFormType) => {
          await integrationsUseCase.sendIntegrationRequest({
            title,
            message,
          });
          analytics.trackEvent(ANALYTICS_EVENTS.INTEGRATIONS_REQUEST_SENT, {
            name: id === 'custom' ? 'Custom' : title,
          });
        },
      )();
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: 'success',
          message: t('requestSentToastTitle'),
          description: t('requestSentToastSubtitle'),
          autoHideDuration: 5000,
        });
      },
      onError: () => {
        enqueueSnackbar({
          variant: 'error',
          message: t('errors.errorOccurred', {
            ns: 'common',
          }),
        });
      },
    },
  );

  return {
    form,
    sendRequestAsyncExecutor,
  };
};
