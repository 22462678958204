import { FC } from 'react';
import { Box } from '@mui/material';

import { useCompanyById } from '@/features/common/contact';

import styles from './styles.module.scss';

export const IndustryCell: FC<{ companyId: string }> = ({ companyId }) => {
  const { data } = useCompanyById(companyId);
  const itemsInArray = data?.industry ? data.industry.split(',').length : 0;

  if (itemsInArray > 2) {
    return (
      <Box className={styles.industryCell}>
        {data?.industry
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item !== '')
          .slice(0, 2)
          .join(', ')}
      </Box>
    );
  }
  if (itemsInArray === 0) {
    return <Box className={styles.industryCell}>-</Box>;
  }

  return <Box className={styles.industryCell}>{data?.industry}</Box>;
};
