import { FC } from 'react';

import { ErrorPage404 } from '@/router/error';
import { ROUTES } from '@/router/routes';

import { SubHead } from '@/components';
import { SplashScreen } from '@/components/SplashScreen';

import {
  ClearFiltersAction,
  ContactsTable,
  ContactsTabs,
  ExportAction,
  FilterByContactInfo,
  FilterByDateAction,
  FilterByExport,
  FilterByTagAction,
  FilterByUserAction,
  ListMoveAction,
  SearchAction,
  TagApplyAction,
} from './components';
import { ContactsProvider } from './context';
import { useContactsViewModel } from './useContactsViewModel';

export const Contacts: FC = () => {
  const { title, contactListEntities, currentContactList, defaultContactList } =
    useContactsViewModel();

  if (!currentContactList.isLoaded || !defaultContactList.isLoaded) {
    return <SplashScreen />;
  }

  if (!currentContactList.data || !defaultContactList.data) {
    return <ErrorPage404 />;
  }

  return (
    <ContactsProvider>
      <SubHead>
        <SubHead.TitleWithNavigation goBackPath={ROUTES.CONTACTS_ENTRY}>
          {title}
        </SubHead.TitleWithNavigation>
        <SubHead.Action>
          <SearchAction />
          <FilterByUserAction />
          <FilterByDateAction />
          <FilterByTagAction />
          <FilterByContactInfo />
          <FilterByExport />
          <ClearFiltersAction />
          <TagApplyAction />
          <ListMoveAction />
          <ExportAction />
        </SubHead.Action>
        <SubHead.Tabs>
          <ContactsTabs
            contactLists={contactListEntities}
            currentContactList={currentContactList.data}
          />
        </SubHead.Tabs>
      </SubHead>
      <ContactsTable
        currentContactList={currentContactList.data}
        defaultContactList={defaultContactList.data}
      />
    </ContactsProvider>
  );
};
