import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import {
  IContactListEntity,
  useContactListById,
  useContactListEntities,
  useDefaultContactList,
} from '@/features/common/contactList';

export const useContactsViewModel = () => {
  const { t } = useTranslation('contacts');
  const snapshot = useRef<IContactListEntity | null>(null);
  const { id: contactListId = '' } = useParams();
  const defaultContactList = useDefaultContactList();
  const contactListById = useContactListById(contactListId);
  const contactListEntities = useContactListEntities();
  const { enqueueSnackbar } = useSnackbar();

  const title = `${contactListById.data?.name} (${contactListById?.data?.contactsAmount})`;

  useEffect(() => {
    if (contactListById.data !== undefined || !snapshot.current) return;

    enqueueSnackbar(t('errors.listNotFound.title', { name: snapshot.current?.name }), {
      variant: 'error',
      persist: true,
    });
  }, [contactListById.data]);

  useEffect(() => {
    if (!contactListById.data) return;

    snapshot.current = contactListById.data;
  }, [contactListById.data]);

  return {
    title,
    contactListEntities,
    currentContactList: contactListById,
    defaultContactList,
  };
};
