import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { LaunchIcon } from '@/components/Icons';

import { OverlayLogoutButton } from '../OverlayLogoutButton';
import { OverlayLayout } from '..';

import styles from './styles.module.scss';

type AccountLockedAdminOverlayProps = {
  onIssueFix: () => void;
  onContactsExport: () => void;
};

export const AccountLockedAdminOverlay: FC<AccountLockedAdminOverlayProps> = ({
  onIssueFix,
  onContactsExport,
}) => {
  const { t } = useTranslation('billing', {
    keyPrefix: 'overlays.accountLocked',
  });

  return (
    <OverlayLayout
      title={t('title')}
      description={t('description')}
      footer={
        <Box className={styles.footer}>
          <Box>
            <Button
              variant="text"
              className={styles.link}
              endIcon={<LaunchIcon className={styles.icon} />}
              onClick={onContactsExport}
            >
              {t('export')}
            </Button>
            <Button variant="contained" className={styles.fix} onClick={onIssueFix}>
              {t('fix')}
            </Button>
          </Box>
          <OverlayLogoutButton />
        </Box>
      }
    />
  );
};
