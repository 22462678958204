import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { unix } from 'dayjs';

import {
  CompanyCell,
  ContactInfoCell,
  ContactOwnerCell,
  HeaderCell,
  type IContactEntity,
  JobTitleCell,
  ListLinkCell,
} from '@/features/common/contact';

export const useTableColumns = (): GridColDef[] => {
  const { t } = useTranslation('contactsTable');

  return [
    {
      headerName: t('columns.fullName'),
      field: 'name',
      sortable: true,
      minWidth: 170,
      display: 'flex',
      flex: 1,
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell: ({ row }: GridRenderCellParams<IContactEntity>) => row.name,
    },
    {
      headerName: t('columns.contactInfo'),
      field: 'contactInfo',
      sortable: false,
      minWidth: 270,
      display: 'flex',
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell({ row }: GridRenderCellParams<IContactEntity>): ReactElement {
        return <ContactInfoCell contact={row} />;
      },
    },
    {
      headerName: t('columns.jobTitle'),
      field: 'jobTitle',
      sortable: true,
      minWidth: 170,
      display: 'flex',
      flex: 1,
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell({ row }: GridRenderCellParams<IContactEntity>): ReactElement {
        return <JobTitleCell value={row.jobTitle} />;
      },
    },
    {
      headerName: t('columns.company'),
      field: 'company',
      sortable: true,
      minWidth: 150,
      display: 'flex',
      flex: 1,
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell({ row }: GridRenderCellParams<IContactEntity>): ReactElement {
        return <CompanyCell companyId={row.companyId} />;
      },
    },
    {
      headerName: t('columns.date'),
      field: 'date',
      sortable: true,
      minWidth: 180,
      display: 'flex',
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell(params): ReactElement {
        return <>{unix(params.row?.createdAt).format('MMM DD, YYYY')}</>;
      },
    },
    {
      headerName: t('columns.contactOwner'),
      field: 'contactOwner',
      sortable: true,
      minWidth: 180,
      display: 'flex',
      flex: 1,
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell({ row }: GridRenderCellParams<IContactEntity>): ReactElement {
        return <ContactOwnerCell assignedTo={row.assignedTo} contactId={row.uuid} />;
      },
    },
    {
      headerName: t('columns.list'),
      field: 'contactListId',
      sortable: false,
      minWidth: 180,
      display: 'flex',
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <HeaderCell title={colDef.headerName} />
      ),
      renderCell({ row }: GridRenderCellParams<IContactEntity>): ReactElement {
        return <ListLinkCell contactListId={row.contactListId} />;
      },
    },
  ];
};
