import { FC, ReactNode, useState } from 'react';
import { Box, Button, ButtonProps, Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import { Modal, ModalProps, Preloader } from '@/components';

import styles from './styles.module.scss';

export type ConformationModalData = {
  title: string;
  description: string | ReactNode;
  buttonText: string;
  acceptButtonProps?: ButtonProps;
  handleAccept: () => any;
};

export type ConformationModalProps = Omit<ModalProps, 'children'> & ConformationModalData;

export const ConfirmationModal: FC<ConformationModalProps> = ({
  open,
  onClose,
  title,
  description,
  buttonText,
  handleAccept,
  acceptButtonProps = {},
}) => {
  const [isAcceptProcessing, setIsAcceptProcessing] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      onClose={isAcceptProcessing ? undefined : onClose}
      className={styles.wrapper}
    >
      <Stack className={styles.stackContainer}>
        <Typography color="common" variant="h1">
          {title}
        </Typography>
        {typeof description === 'string' ? (
          <Typography color="common" variant="h3">
            {description}
          </Typography>
        ) : (
          description
        )}
        <Box className={styles.buttonContainer}>
          <Button
            variant="text"
            color="info"
            onClick={(e): void => onClose?.(e, 'escapeKeyDown')}
          >
            Cancel
          </Button>{' '}
          <Button
            className={clsx(styles.acceptButton, acceptButtonProps?.className)}
            variant="contained"
            disabled={isAcceptProcessing}
            {...acceptButtonProps}
            onClick={async (e): Promise<void> => {
              setIsAcceptProcessing(true);
              await handleAccept();
              setIsAcceptProcessing(false);
              onClose?.(e, 'escapeKeyDown');
            }}
          >
            {isAcceptProcessing ? <Preloader /> : buttonText}
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};
