import { DependencyList, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedCancellableCallback = (
  callback: () => void,
  wait: number,
  deps: DependencyList,
) => {
  const mountRef = useRef(false);

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }

    const debouncedCallback = debounce(callback, wait);

    debouncedCallback();

    return () => {
      // Cancel callback when dependency list changed
      debouncedCallback.cancel();
    };
  }, deps);
};
