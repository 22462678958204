import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';

import { TagSettingsDialog } from '../TagSettingsDialog';

import styles from './styles.module.scss';

export const CreateTag: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation('tag');

  const onOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <ActionDisableGuard>
        <Button
          className={styles.Button}
          variant="contained"
          size="small"
          onClick={onOpen}
        >
          {t('createTag.buttonText')}
        </Button>
      </ActionDisableGuard>
      <TagSettingsDialog anchorEl={anchorEl} onClose={onClose} />
    </Box>
  );
};
