import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu } from '@mui/material';
import { clsx } from 'clsx';

import { ContactExportProgress } from '@/features/common/contact';
import { ActionDisableGuard } from '@/features/common/permissions';
import { OnlineRequiredAction } from '@/features/system/OnlineTracker';

import { MenuItem } from '@/components';
import { ArrowDownIcon } from '@/components/Icons';

import ExcelIcon from '@/assets/icons/excel.svg?react';

import { useExportActionViewModel } from './useExportActionViewModel';

import styles from './styles.module.scss';

export const ExportAction: FC = () => {
  const { t } = useTranslation('contacts');
  const {
    onOpenMenu,
    anchorEl,
    options,
    selectedContactsCount,
    onCloseMenu,
    menuOpen,
    exportProgress,
    onRetry,
    onExportToCsv,
    onExportToIntegration,
  } = useExportActionViewModel();

  return (
    <Box className={styles.export}>
      <OnlineRequiredAction>
        <Button
          className={styles.button}
          onClick={onOpenMenu}
          variant="contained"
          disabled={!selectedContactsCount}
        >
          {t('subhead.export.button', {
            selectedCount: `${selectedContactsCount || ''}`,
          })}
          <Box
            component="span"
            className={clsx(styles.exportButtonIcon, {
              [styles.exportButtonIconTransformed]: menuOpen,
            })}
          >
            <ArrowDownIcon />
          </Box>
        </Button>
      </OnlineRequiredAction>
      <Menu
        classes={{ list: styles.exportMenuList }}
        open={menuOpen}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <MenuItem key="exportCsv" onClick={onExportToCsv} className={styles.menuItem}>
          <Box className={styles.menuItemImg}>
            <ExcelIcon />
          </Box>
          <Box component="span">{t('subhead.export.menu.csv')}</Box>
        </MenuItem>
        {options.map((option) => {
          const { label, icon } = option;
          return (
            <ActionDisableGuard key={label}>
              <MenuItem
                onClick={(): void => {
                  onExportToIntegration(option);
                }}
                className={styles.menuItem}
              >
                <Box className={styles.menuItemImg}>
                  <img src={icon} alt={label} />
                </Box>
                <Box component="span">{label}</Box>
              </MenuItem>
            </ActionDisableGuard>
          );
        })}
      </Menu>
      <ContactExportProgress
        open={exportProgress.open}
        status={exportProgress.status}
        anchorEl={anchorEl}
        onRetry={onRetry}
        onTransitionExited={(): void => {
          exportProgress.setConfig((prev) => ({
            ...prev,
            status: 'pending',
            provider: null,
          }));
        }}
        onClose={(): void => {
          if (exportProgress.status === 'pending') return;
          exportProgress.setConfig((prev) => ({ ...prev, open: false }));
          onCloseMenu();
        }}
        {...exportProgress.getMetadata({ selectedCount: selectedContactsCount })}
      />
    </Box>
  );
};
