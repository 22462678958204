import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { ViewLayout } from '../ViewLayout';

import { useConfigViewResultViewModel } from './useConfigViewResultViewModel';

import styles from './styles.module.scss';

export const ConfigViewResult: FC = () => {
  const { t } = useTranslation('enrichment');
  const { handleDoneClick, handleUploadClick, inputRef, handleInputChange } =
    useConfigViewResultViewModel();

  return (
    <ViewLayout>
      <ViewLayout.Header title={t('uploadingResultModal.title')} />
      <ViewLayout.Container>
        <Box className={styles.Container}>
          <Box className={styles.Icon}></Box>
          <Typography variant="h3" className={styles.Title}>
            {t('uploadingResultModal.content.title')}
          </Typography>
          <Typography variant="subtitle1" className={styles.SubTitle}>
            {t('uploadingResultModal.content.subTitle')}
          </Typography>
          <input
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv, .xls, .xlsx"
            hidden
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            onClick={handleUploadClick}
            className={styles.Button}
          >
            {t('uploadingResultModal.content.uploadButton')}
          </Button>
        </Box>
      </ViewLayout.Container>
      <ViewLayout.Actions
        rightButton={
          <Button variant="contained" color="primary" onClick={handleDoneClick}>
            {t('uploadingResultModal.actions.done')}
          </Button>
        }
      />
    </ViewLayout>
  );
};
