import Intercom, {
  hide,
  onHide,
  onShow,
  show,
  showNewMessage,
  shutdown,
  update,
} from '@intercom/messenger-js-sdk';
import type { UserArgs } from '@intercom/messenger-js-sdk/dist/types';
import { inject, injectable, postConstruct } from 'inversify';

import { APP_CONFIG_TYPES } from '@/ioc/types';

import type { IHelpCenterRepository } from './abstractions/IHelpCenterRepository';

import type { AppConfig } from '@/config';

@injectable()
export class HelpCenterRepository implements IHelpCenterRepository {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  private get appId(): string {
    return this.appConfig.intercom.appId;
  }

  @postConstruct()
  init(): void {
    Intercom({
      app_id: this.appId,
    });
  }

  public update(settings: UserArgs): void {
    update({
      app_id: this.appId,
      ...settings,
    });
  }

  public hide(): void {
    hide();
  }

  public show(): void {
    show();
  }

  public showNewMessage(prePopulatedContent: string): void {
    showNewMessage(prePopulatedContent);
  }

  public onShow(callback: () => void): void {
    onShow(callback);
  }

  public onHide(callback: () => void): void {
    onHide(callback);
  }

  public shutdown(): void {
    shutdown();
  }
}
