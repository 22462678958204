import { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { useContactListById } from '@/features/common/contactList';

import styles from './styles.module.scss';

export const ListLinkCell: FC<{ contactListId: string }> = ({ contactListId }) => {
  const navigate = useNavigate();
  const { data, isLoading } = useContactListById(contactListId);

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(ROUTES.CONTACTS_INNER.replace(':id', contactListId));
  };

  if (!contactListId || isLoading) {
    return null;
  }

  return (
    <Button variant="text" onClick={handleClick} className={styles.button}>
      {data?.name || ''}
    </Button>
  );
};
