import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { SnackbarKey, useSnackbar } from 'notistack';

import { WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useGlobalErrorUseCase } from '@/features/system/globalError';

import { useDocumentMeta } from '@/hooks';

import { TeamMemberInviteFreePlanError } from '../domain/errors/TeamMemberInviteFreePlanError';
import { TeamMemberInvitePermissionDeniedError } from '../domain/errors/TeamMemberInvitePermissionDeniedError';

import { ErrorReason } from './components/ErrorModal/types';
import { useTeamMembersUseCase } from './hooks/useTeamMembersUseCase';

type CurrentModalState =
  | {
      variant: 'invite';
    }
  | {
      variant: 'error';
      reason: ErrorReason;
    };

export type TeamManagementViewModel = {
  errorModal: {
    open: boolean;
    reason: Nullable<ErrorReason>;
    onClose(): void;
  };
  inviteModal: {
    open: boolean;
    onClose(): void;
  };
  handleInvite: () => Promise<void>;
};

export const useTeamManagementViewModel = (): TeamManagementViewModel => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = useAnalytics();
  const teamMemberUseCase = useTeamMembersUseCase();
  const globalErrorUseCase = useGlobalErrorUseCase();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isInvitationDialogShouldBeShownAfterRedirect =
    location.state?.isInviteTeamMemberModalOpen;

  const [currentModalState, setCurrentModalState] =
    useState<Nullable<CurrentModalState>>(null);

  useDocumentMeta({
    title: 'teamManagement.title',
    description: 'teamManagement.description',
  });

  const closeModal = (): void => {
    setCurrentModalState(null);
    navigate(location.pathname, { state: { isInviteTeamMemberModalOpen: false } });
  };

  const handleInvite = async (): Promise<void> => {
    analytics.trackEvent(ANALYTICS_EVENTS.ADD_TEAM_MEMBER_BUTTON_CLICK);

    try {
      await teamMemberUseCase.iniviteAccessGuard();
      setCurrentModalState({
        variant: 'invite',
      });
    } catch (error) {
      if (
        error instanceof TeamMemberInviteFreePlanError ||
        error instanceof TeamMemberInvitePermissionDeniedError
      ) {
        setCurrentModalState({
          variant: 'error',
          reason: 'upgradeAccount',
        });
      }
    }
  };

  useEffect(() => {
    let snackbarKey: SnackbarKey | null = null;
    const sub = globalErrorUseCase.listenErrorSubject().subscribe((error) => {
      if (error instanceof WorkspaceSeatsLimitError) {
        snackbarKey = enqueueSnackbar({
          variant: 'error',
          message: t('teamManagement.worspaceSeatsLimitationError.title'),
          autoHideDuration: 3000,
          preventDuplicate: true,
        });
      }
    });

    return () => {
      sub.unsubscribe();
      !!snackbarKey && closeSnackbar();
    };
  }, []);

  useEffect(() => {
    if (isInvitationDialogShouldBeShownAfterRedirect) {
      handleInvite();
    }
  }, [isInvitationDialogShouldBeShownAfterRedirect]);

  useEffect(() => {
    analytics.trackEvent(ANALYTICS_EVENTS.VIEW_TEAM_MANAGEMENT_PAGE);
  }, []);

  return {
    handleInvite,
    errorModal: {
      open: currentModalState?.variant === 'error',
      reason: currentModalState?.variant === 'error' ? currentModalState.reason : null,
      onClose: closeModal,
    },
    inviteModal: {
      open: currentModalState?.variant === 'invite',
      onClose: closeModal,
    },
  };
};
