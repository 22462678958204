import { FC, PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { Box, BoxProps, Typography } from '@mui/material';
import { clsx } from 'clsx';
import history from 'history/browser';

import { Container, ContainerVariant } from '@/components';
import { ArrowLeftIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type SubHeadTitleProps = {
  children: string | ReactNode;
};

type SubHeadTitleWithNavigationProps = SubHeadTitleProps & {
  goBackPath?: string;
};

const SubHeadTitle: FC<SubHeadTitleProps> = ({ children }) => {
  return <Typography variant="h1">{children}</Typography>;
};

const SubHeadTitleWithNavigation: FC<SubHeadTitleWithNavigationProps> = ({
  children,
  goBackPath,
}) => {
  const navigate = useNavigate();
  const onGoBack = (): void => {
    if (goBackPath) {
      navigate(goBackPath);
    } else {
      history.back();
    }
  };

  return (
    <Box className={styles.goBack} onClick={onGoBack}>
      <ArrowLeftIcon />
      <Typography variant="h1" className={styles.title}>
        {children}
      </Typography>
    </Box>
  );
};

const SubHeadAction: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box {...props} className={clsx(styles.actions, props.className)}>
      {children}
    </Box>
  );
};

const SubHeadTabs: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box {...props} className={clsx(styles.tabs, props.className)}>
      {children}
    </Box>
  );
};

type SubHeadComposition = {
  Title: FC<SubHeadTitleProps>;
  TitleWithNavigation: FC<SubHeadTitleWithNavigationProps>;
  Action: FC<PropsWithChildren<BoxProps>>;
  Tabs: FC<PropsWithChildren<BoxProps>>;
};

export const SubHead: SubHeadComposition & FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box {...props} className={clsx(styles.wrapper, props.className)}>
      <Container variant={ContainerVariant.XXXL}>{children}</Container>
    </Box>
  );
};

SubHead.Title = SubHeadTitle;
SubHead.TitleWithNavigation = SubHeadTitleWithNavigation;
SubHead.Action = SubHeadAction;
SubHead.Tabs = SubHeadTabs;
