import { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router';

import { useWidgetManager } from '@/hooks';

import { useHelpCenterUseCase } from '../hooks';

export const HelpCenterWidget: FC = () => {
  const { isOpen, isVisible, close, open } = useWidgetManager('HelpCenter');
  const isOpenRef = useRef(isOpen);
  isOpenRef.current = isOpen;
  const helpCenterUseCase = useHelpCenterUseCase();
  const [searchParams] = useSearchParams();
  const initiallyOpen = searchParams.get('showIntercom') === 'true';

  useEffect(() => {
    helpCenterUseCase.onHide(() => {
      if (!isOpenRef.current) return;
      close();
    });
    helpCenterUseCase.onShow(() => {
      if (isOpenRef.current) return;
      open({ closeRest: true });
    });
    const sub = helpCenterUseCase.init().subscribe();

    return () => {
      sub.unsubscribe();
      helpCenterUseCase.shutdown();
    };
  }, []);

  useEffect(() => {
    if (initiallyOpen) {
      open({ closeRest: true });
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      helpCenterUseCase.show();
    } else {
      helpCenterUseCase.hide();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isVisible) {
      helpCenterUseCase.update({ hide_default_launcher: false });
    } else {
      helpCenterUseCase.update({ hide_default_launcher: true });
    }
  }, [isVisible]);

  return null;
};
