import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import {
  ActionDisableGuard,
  HasPermission,
  Permission,
} from '@/features/common/permissions';
import { OnlineRequiredAction } from '@/features/system/OnlineTracker';

import { Paper } from '@/components';
import { CheckIcon } from '@/components/Icons';

import { IIntegrationEntity } from '../../../domain/entities';

import { IntegrationCardPreview } from './components/CardPreview';
import { useIntegrationCardViewModel } from './useIntegrationCardViewModel';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  onConnect(integration: IIntegrationEntity): void;
};

export const IntegrationCard: FC<Props> & {
  Preview: FC;
} = ({ integration, onConnect }) => {
  const { t } = useTranslation('integrations');
  const { shouldContactSales, handleManage, trackAnalyticConnect } =
    useIntegrationCardViewModel(integration);

  return (
    <Paper className={styles.Card}>
      <Box className={styles.CardInfoWrapper}>
        <Box
          className={styles.CardIcon}
          style={{
            backgroundImage: `url(${integration.icon})`,
          }}
        />
        <Box className={styles.CardInfo}>
          <Typography className={styles.CardTitle}>{t(integration.name)}</Typography>
          <Typography className={styles.CardDescription}>
            {t(`cards.${integration.id}Description`)}
          </Typography>
          {integration.isExpired ? (
            <Box className={styles.reconnect}>
              <Typography className={styles.reconnectTitle}>
                {t('reconnect.description')}
              </Typography>
              <HasPermission permission={Permission.CanEditIntegrations}>
                <ActionDisableGuard>
                  <OnlineRequiredAction>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={styles.CardButton}
                      onClick={(): void => {
                        onConnect(integration);
                        trackAnalyticConnect();
                      }}
                    >
                      {t('reconnect.buttonTitle')}
                    </Button>
                  </OnlineRequiredAction>
                </ActionDisableGuard>
              </HasPermission>
            </Box>
          ) : integration.isConnected ? (
            <Box className={styles.connected}>
              <Typography className={styles.connectedTitle}>
                <CheckIcon />
                {t('accountConnectedText')}
              </Typography>
              <HasPermission
                permission={[
                  Permission.CanEditIntegrations,
                  Permission.CanAccessCrmIntegration,
                ]}
              >
                <ActionDisableGuard>
                  <OnlineRequiredAction>
                    <Button
                      color="primary"
                      variant="outlined"
                      className={styles.CardButton}
                      href={`${ROUTES.INTEGRATIONS}/${integration.id}`}
                      onClick={handleManage}
                    >
                      {t('manageButtonText')}
                    </Button>
                  </OnlineRequiredAction>
                </ActionDisableGuard>
              </HasPermission>
            </Box>
          ) : (
            <ActionDisableGuard>
              <OnlineRequiredAction>
                <Button
                  color="primary"
                  variant="contained"
                  className={styles.CardButton}
                  onClick={(): void => {
                    onConnect(integration);
                    trackAnalyticConnect();
                  }}
                >
                  {shouldContactSales
                    ? t('contactSalesButtonText')
                    : t('connectButtonText')}
                </Button>
              </OnlineRequiredAction>
            </ActionDisableGuard>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

IntegrationCard.Preview = IntegrationCardPreview;
