import { Children, cloneElement, FC, isValidElement, ReactElement } from 'react';
import { FormHelperText, FormLabel } from '@mui/material';

export type FormFieldProps = {
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  children: ReactElement;
  labelText?: string;
  errorText?: string;
};

export const FormField: FC<FormFieldProps> = ({
  children,
  labelText,
  errorText,
  required,
  error,
  disabled,
}) => {
  return (
    <>
      {labelText && (
        <FormLabel required={required} disabled={disabled} sx={{ display: 'block' }}>
          {labelText}
        </FormLabel>
      )}
      {Children.map(children, (child) => {
        if (
          isValidElement<
            Pick<FormFieldProps, 'required' | 'error' | 'disabled' | 'children'>
          >(child)
        ) {
          return cloneElement(
            child,
            { ...child.props, required, error, disabled },
            child.props.children,
          );
        }
        return child;
      })}
      {errorText && error && (
        <FormHelperText error={error} disabled={disabled}>
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};
