import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';
import type { IIntegrationEntity } from '@/features/integrations/domain';

import IconClose from '@/assets/icons/iconClose.svg?react';

import { useContactAdminDialogViewModel } from './useContactAdminDialogViewModel';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  onClose(): void;
};

export const IntegrationContactAdminDialog: FC<Props> = ({ integration, onClose }) => {
  const { t } = useTranslation('integrations');
  const { asyncExecute, isLoading } = useContactAdminDialogViewModel(integration);

  return (
    <Box className={styles.dialog}>
      <IconClose onClick={onClose} className={styles.closeIcon} />
      <Typography className={styles.dialogTitle}>
        {t('contactAdminDialog.title', { provider: t(integration.name) })}
      </Typography>
      <Typography className={styles.dialogSubtitle}>
        {t('contactAdminDialog.description', { provider: t(integration.name) })}
      </Typography>
      <ActionDisableGuard>
        <Button
          onClick={async (): Promise<void> => {
            await asyncExecute();
            onClose();
          }}
          className={styles.dialogButton}
          variant="contained"
          disabled={isLoading}
        >
          {t('contactAdminDialog.submitBtn')}
        </Button>
      </ActionDisableGuard>
    </Box>
  );
};
