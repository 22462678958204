import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { Permission, usePermissions } from '@/features/common/permissions';
import { useTeamMemberSelectOptions } from '@/features/settings';

import { CustomSelectV2, Preview } from '@/components';
import { LaunchIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type TeamMemberSelectProps = {
  isLoading?: boolean;
  value: Nullable<string>;
  onChange: (value: string) => void;
};

export const TeamMemberSelect: FC<TeamMemberSelectProps> = ({
  isLoading = false,
  onChange,
  value,
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const options = useTeamMemberSelectOptions();
  const { hasPermissions } = usePermissions();
  const canManageTeamMemebers = hasPermissions(Permission.CanManageMembers);

  return (
    <Box className={styles.Wrapper}>
      {isLoading ? (
        <Preview />
      ) : (
        <CustomSelectV2
          options={options}
          value={value}
          onChange={onChange}
          MenuProps={{
            ListProps: {
              className: styles.List,
            },
            ...(canManageTeamMemebers && {
              renderFooter: (): ReactElement => {
                return (
                  <Button
                    endIcon={<LaunchIcon fontSize="small" />}
                    className={styles.Button}
                    variant="text"
                    onClick={(): void => {
                      navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT);
                    }}
                  >
                    {t('filters.byUser.manageMembers')}
                  </Button>
                );
              },
            }),
          }}
          searchable
        />
      )}
    </Box>
  );
};
