import { FC } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';

import { Preview, PreviewVariant } from '@/components';

import styles from './styles.module.scss';

const HeaderCellPreview: FC = () => {
  return (
    <Box className={clsx(styles.cell, styles.preview)}>
      <Preview variant={PreviewVariant.DARK} />
    </Box>
  );
};

export const HeaderCell: FC<{ title?: string }> & {
  Preview: FC;
} = ({ title }) => {
  return <Box className={styles.cell}>{title}</Box>;
};

HeaderCell.Preview = HeaderCellPreview;
