import { FC } from 'react';
import { Button } from '@mui/material';

import {
  ANALYTICS_EVENTS,
  useAnalytics,
  WatchTutorialProperty,
} from '@/features/system/analytics';

import { Modal, Player } from '@/components';

import CloseIcon from '@/assets/icons/close-round.svg?react';

import styles from './styles.module.scss';

type VideoModal = {
  videoSrc: string;
  onClose: () => void;
  open: boolean;
};

export const VideoModal: FC<VideoModal> = ({ onClose, open, videoSrc }) => {
  const analytics = useAnalytics();
  const handlePlayVideo = (): void => {
    analytics.trackEvent(ANALYTICS_EVENTS.WATCH_TUTORIAL, {
      page: WatchTutorialProperty.Dashboard,
    });
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="video-modal"
      className={styles.wrapper}
      containerClass={styles.container}
      closeAfterTransition
    >
      <>
        <Button className={styles.close} onClick={onClose}>
          <CloseIcon />
        </Button>
        <Player url={videoSrc} onFirstStartPlay={handlePlayVideo} />
      </>
    </Modal>
  );
};
