export enum Permission {
  // workspace.permissions
  CanAccessAllContact = 'can_access_all_contacts',
  // workspace.permissions
  CanAddTags = 'can_add_tags',
  // workspace.permissions
  CanEditOtherTags = 'can_edit_others_tags',
  // workspace.permissions
  CanEditOwnTags = 'can_edit_own_tags',
  // virtual + workspace.permissions - wide permission for managing team members at all.
  // by defauld if the permssion does not exist in the workspace.permissions - using virtual permission by role, otherwise - using workspace.permissions
  CanManageMembers = 'can_manage_members',
  // virtual
  CanChangeUserPermissions = 'can_change_user_permissions',
  // workspace.permissions
  CanAccessBilling = 'can_access_billing',
  // workspace.permissions
  CanUseExtension = 'can_use_extension',
  // workspace.permissions
  CanEditIntegrations = 'can_edit_integrations',
  // workspace.permissions
  CanRunBulkEnrichment = 'can_run_bulk_enrichment',
  // virtual
  CanManageContactLists = 'can_manage_contact_lists',
  // virtual
  CanManageContactOwner = 'can_manage_contact_owner',
  // virtual
  CanViewProspectTaskProgress = 'can_view_prospect_task_progress',
  // virtual
  CanAccessRefferal = 'can_access_refferal',
  // workspace.subscription.planSelectedFeatures
  CanAccessCsvEnrichemnt = 'csv_enrichment',
  // virtual
  CanRunCsvEnrichemnt = 'can_run_csv_enrichment',
  // workspace.subscription.planSelectedFeatures
  CanAccessDataExport = 'data_export',
  // workspace.subscription.planSelectedFeatures
  CanAccessApi = 'api_access',
  // workspace.subscription.planSelectedFeatures
  CanAccessCrmIntegration = 'crm_integration',
  // virtual - narrower permission for invite only.
  CanInviteTeamMembers = 'can_invite_team_members',
}
