import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { useAuthUseCase } from '@/features/common/auth';

import { openInNewTab } from '@/utils/openInNewTab';

export const useUserMenuViewModel = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const authUseCase = useAuthUseCase();

  const close = (): void => setAnchorEl(null);
  const open = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);

  const onLogout = async (): Promise<void> => {
    await authUseCase.signOut();
  };

  const navigateToSettings = (): void => {
    navigate(ROUTES.SETTINGS.INDEX);
    close();
  };

  const navigateToTeamManagement = (): void => {
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT);
    close();
  };

  const navigateToSubscription = (): void => {
    navigate(ROUTES.SETTINGS.SUBSCRIPTION);
    close();
  };

  const navigateToTagManagement = (): void => {
    navigate(ROUTES.SETTINGS.TAG_MANAGEMENT);
    close();
  };

  const navigateToReferAFriend = (): void => {
    navigate(ROUTES.SETTINGS.REFERRAL);
    close();
  };

  const handleInviteTeamMember = (): void => {
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, {
      state: { isInviteTeamMemberModalOpen: true },
    });
    close();
  };

  const navigateToHelpCenter = (): void => {
    openInNewTab(EXTERNAL_ROUTES.HELP_CENTER);
    close();
  };

  useEffect(() => {
    if (!anchorEl) return;

    close();
  }, [location]);

  return {
    isOpen: Boolean(anchorEl),
    close,
    open,
    onLogout,
    anchorEl,
    handleInviteTeamMember,
    navigateToHelpCenter,
    navigateToSettings,
    navigateToTeamManagement,
    navigateToSubscription,
    navigateToTagManagement,
    navigateToReferAFriend,
  };
};
