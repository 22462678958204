import { useObservableResult } from '@/utils/rx';

import { AuthState } from '../../domain';

import { useAuthUseCase } from './useAuthUseCase';

export function useAuthState(): AuthState {
  const authUseCase = useAuthUseCase();

  const { data: authState } = useObservableResult(() => authUseCase.getAuthState(), {
    defaultData: AuthState.Identifying,
  });

  return authState;
}
