import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';

import { IIntegrationSettingsEntity } from '@/features/integration/domain';
import { OnlineRequiredAction } from '@/features/system/OnlineTracker';

import { IOSSwitch } from '@/components';

import { DisconnectDialog } from './components/DisconnectDialog';
import { useSettingsTabViewModel } from './useSettingsTabViewModel';

import styles from './styles.module.scss';

export const SettingsTab: FC<{
  settings: IIntegrationSettingsEntity;
}> = ({ settings }) => {
  const { t } = useTranslation('integrations', {
    keyPrefix: 'integration.tabs.settings',
  });

  const { options, providerName, handleOptionToogle, disconnectDialog } =
    useSettingsTabViewModel(settings);

  return (
    <>
      <Box className={styles.wrapper}>
        <Box className={clsx(styles.preferences, styles.card)}>
          <Typography variant="h2" className={styles.preferencesTitle}>
            {t('preferences.title')}
          </Typography>
          <Typography variant="h3" className={styles.preferencesDescription}>
            {t('preferences.description', { providerName })}
          </Typography>
          <Box className={styles.divider} />
          <Box className={styles.optionsWrapper}>
            {options.map((option) => {
              return (
                <Box className={styles.option} key={option.value}>
                  <Typography variant="subtitle1" className={styles.optionName}>
                    {t(`preferences.entities.${option.label}`)}
                  </Typography>
                  <OnlineRequiredAction>
                    <IOSSwitch
                      checked={option.checked}
                      onChange={(): void => handleOptionToogle(option)}
                    />
                  </OnlineRequiredAction>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className={clsx(styles.userInfo, styles.card)}>
          <Box className={styles.userInfoText}>
            <Typography variant="h2" className={styles.userInfoTitle}>
              {t('userInfo.title', { providerName })}
            </Typography>
            <Typography variant="subtitle1" className={styles.userInfoEmail}>
              {settings.userEmail}
            </Typography>
          </Box>
          <OnlineRequiredAction>
            <Button
              variant="outlined"
              color="info"
              className={styles.disconnect}
              onClick={disconnectDialog.onOpen}
            >
              {t('userInfo.actions.disconnect')}
            </Button>
          </OnlineRequiredAction>
        </Box>
      </Box>
      <DisconnectDialog
        isOpen={disconnectDialog.isOpen}
        onClose={disconnectDialog.onClose}
        provider={settings.provider}
      />
    </>
  );
};
