import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';

import { ActionDisableGuard } from '@/features/common/permissions';
import { IIntegrationEntity } from '@/features/integrations/domain';

import { LogoBase } from '@/components/Icons';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconArrows from '@/assets/icons/integrations/arrows.svg?react';

import { useIntegrationConnectDialogViewModel } from './useIntegrationConnectDialogViewModel';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  onClose(): void;
  openRequestDialog: (integration: IIntegrationEntity) => void;
};

export const IntegrationConnectDialog: FC<Props> = ({
  integration,
  onClose,
  openRequestDialog,
}) => {
  const { t } = useTranslation(['integrations', 'common']);
  const { asyncExecute, isLoading } = useIntegrationConnectDialogViewModel(integration, {
    openRequestDialog,
    closeDialog: onClose,
  });

  return (
    <Box className={styles.dialog}>
      <IconClose onClick={onClose} className={styles.closeIcon} />
      <Box className={styles.dialogIcons}>
        <Box className={styles.withBorder}>
          <LogoBase width="52" height="52" />
        </Box>
        <IconArrows />
        <Box className={styles.integrationIconWrapper}>
          <img
            src={integration.icon}
            alt={t(integration.name)}
            style={{ width: integration.id === 'salesforce' ? 65 : 52 }}
          />
        </Box>
      </Box>
      <Typography className={styles.dialogTitle}>
        {t('connectDialog.title', { title: t(integration.name) })}
      </Typography>
      <Typography className={styles.dialogSubtitle}>
        {t('connectDialog.description', { title: t(integration.name) })}
      </Typography>

      <ActionDisableGuard>
        <Button
          onClick={asyncExecute}
          className={styles.dialogButton}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {integration.id === 'custom' || !integration.isAvailable
            ? t('contactSalesButtonText')
            : t('connectButtonText')}
        </Button>
      </ActionDisableGuard>
    </Box>
  );
};
