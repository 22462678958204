import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Divider } from '@mui/material';

import { SalesforceApiDisabledError } from '@/features/integration/domain';
import type { IIntegrationEntity } from '@/features/integrations/domain';

import { LogoBase } from '@/components/Icons';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconError from '@/assets/icons/integrations/close-rount-red-icon.svg?react';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  error: Error;
  onClose(): void;
  onRetry(): void;
};

const IntegrationErrorDialogDescription: FC<{
  integration: IIntegrationEntity;
  error: Error;
}> = ({ integration, error }) => {
  const { t } = useTranslation('integrations');

  if (error instanceof SalesforceApiDisabledError) {
    return (
      <Typography className={styles.dialogSubtitle}>
        <Trans
          i18nKey={'errorDialog.descriptionSalesForce'}
          ns="integrations"
          components={{
            a: (
              <a
                className={styles.link}
                target={'_blank'}
                href="https://help.salesforce.com/s/articleView?id=000386981&type=1"
              ></a>
            ),
          }}
        />
      </Typography>
    );
  }

  return (
    <Typography className={styles.dialogSubtitle}>
      {t('errorDialog.description', {
        provider: t(integration.name),
      })}
    </Typography>
  );
};

export const IntegrationErrorDialog: FC<Props> = ({
  integration,
  error,
  onClose,
  onRetry,
}) => {
  const { t } = useTranslation('integrations');

  return (
    <Box className={styles.dialog}>
      <IconClose onClick={onClose} className={styles.closeIcon} />
      <Box className={styles.dialogIcons}>
        <Box className={styles.withBorder}>
          <LogoBase />
        </Box>
        <Divider className={styles.divider} />
        <IconError />
        <Box className={styles.integrationIconWrapper}>
          <img
            src={integration.icon}
            alt={t(integration.name)}
            style={{ width: integration.id === 'salesforce' ? 65 : 52 }}
          />
        </Box>
      </Box>
      <Typography className={styles.dialogTitle}>{t('errorDialog.title')}</Typography>
      <IntegrationErrorDialogDescription error={error} integration={integration} />
      <Button
        onClick={onRetry}
        className={styles.dialogButton}
        color="primary"
        variant="contained"
      >
        {t('errorDialog.submit')}
      </Button>
    </Box>
  );
};
