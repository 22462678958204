import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import type { MappedObjectType } from '@/features/integration/domain';
import { IIntegrationSettingsEntity } from '@/features/integration/domain';
import { useIntegrationUseCase, useProviderName } from '@/features/integration/ui/hooks';

import {
  ModalControler,
  useDebounceCallback,
  useModalController,
  useOptimisticState,
} from '@/hooks';

type SettingOption = { label: string; value: MappedObjectType; checked: boolean };

type UseSettingsTabViewModel = (integration: IIntegrationSettingsEntity) => {
  options: SettingOption[];
  handleOptionToogle: (option: SettingOption) => void;
  disconnectDialog: ModalControler;
  providerName: string;
};

const useSettingOptions = (settings: IIntegrationSettingsEntity): SettingOption[] => {
  return useMemo(() => {
    return settings.supportedObjects.map((object) => ({
      label: object,
      value: object,
      checked: settings.enabledObjects.includes(object),
    }));
  }, [settings]);
};

export const useSettingsTabViewModel: UseSettingsTabViewModel = (settings) => {
  const { t } = useTranslation('integrations');
  const integrationUseCase = useIntegrationUseCase();
  const providerName = useProviderName(settings.provider);
  const disconnectDialog = useModalController();
  const [optimisticSettings, setOptimisticSettings] = useOptimisticState(settings);
  const options = useSettingOptions(optimisticSettings);
  const { enqueueSnackbar } = useSnackbar();

  const updateSettings = useDebounceCallback(
    async (updatedSettings: IIntegrationSettingsEntity): Promise<void> => {
      try {
        await integrationUseCase.updateSettings(
          updatedSettings.provider,
          updatedSettings.enabledObjects,
        );
        enqueueSnackbar({
          variant: 'success',
          message: t('integration.settingsUpdated'),
          preventDuplicate: true,
        });
      } catch {
        enqueueSnackbar({
          variant: 'error',
          message: t('integration.settingsUpdateFailed'),
          preventDuplicate: true,
        });
      }
    },
    800,
    [],
  );

  const handleOptionToogle = async (option: SettingOption): Promise<void> => {
    const enabledSet = new Set(optimisticSettings.enabledObjects);

    if (enabledSet.has(option.value)) {
      enabledSet.delete(option.value);
    } else {
      enabledSet.add(option.value);
    }

    const enabledObjects = Array.from(enabledSet);

    const updatedIntegration = {
      ...optimisticSettings,
      enabledObjects,
    };

    setOptimisticSettings(updatedIntegration);

    updateSettings(updatedIntegration);
  };

  return {
    disconnectDialog,
    options,
    handleOptionToogle,
    providerName,
  };
};
