import { useMemo, useRef, useState } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserRole } from '@/features/common/account';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { WorkspaceSeatsLimitError } from '@/features/common/workspace';
import { ANALYTICS_EVENTS, useAnalytics } from '@/features/system/analytics';
import { useAppLogger } from '@/features/system/logger';

import { useObservableResult } from '@/utils/rx';

import { useTeamMembersUseCase } from '../../../hooks/useTeamMembersUseCase';

import { useInviteModalFormResolver } from './useInviteModalFormResolver';

type Form = UseFormReturn<{
  emails: string[];
  role: UserRole;
}>;

function useGetValidatedOnlyEmails(emails: string[], form: Form): string[] {
  // we need a validation cache as validating is async, and we want to show old valid emails while validation is happening
  const validEmailsCache = useRef<string[]>([]);

  const isValidating = form.formState.isValidating;
  const emailErrors = form.formState.errors.emails;

  return useMemo(() => {
    if (isValidating) return validEmailsCache.current;

    const validEmails = emails.filter(
      (_email, index) => !form.formState.errors.emails?.[index],
    );

    validEmailsCache.current = validEmails;
    return validEmails;
  }, [emails, emailErrors, isValidating]);
}

type InviteCharchableWarning =
  | { visible: true; props: { limit: number; type: 'ActionChargeable' } }
  | { visible: false };

type InviteGiftLimitationWarning =
  | { visible: true; props: { limit: number; type: 'ActionGiftLimitation' } }
  | { visible: false };

type InviteLimitReachError =
  | {
      visible: true;
      props: {
        error: WorkspaceSeatsLimitError;
      };
    }
  | {
      visible: false;
    };

export const useInviteModalViewModel = (): {
  form: Form;
  inviteCharcheableWarning: InviteCharchableWarning;
  inviteGiftLimitationWarning: InviteGiftLimitationWarning;
  inviteLimitReachError: InviteLimitReachError;
  sendActionDisabled: boolean;
  handleSubmitForm: (onSuccess?: () => void) => void;
} => {
  const analytics = useAnalytics();
  const teamMemberUseCase = useTeamMembersUseCase();
  const { t } = useTranslation('settings');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const resolver = useInviteModalFormResolver();
  const form = useForm({
    resolver,
    defaultValues: {
      emails: [],
      role: UserRole.Member,
    },
    reValidateMode: 'onBlur',
  });
  const { emails = [], role } = form.watch();

  const [isProcessing, setIsProcessing] = useState(false);
  const logger = useAppLogger();
  const onboardingUseCase = useOnboardingUseCase();

  const validEmails = useGetValidatedOnlyEmails(emails, form);
  const invitesAccessability = useObservableResult(
    () =>
      teamMemberUseCase.validateInvitesAccessability(
        validEmails.map((email) => ({ email, role })),
      ),
    { deps: [validEmails, role] },
  );

  function handleSubmitForm(onSuccess?: () => void): void {
    form.handleSubmit(async ({ emails }) => {
      try {
        setIsProcessing(true);
        await teamMemberUseCase.inviteTeamMembers(
          emails.map((email) => ({
            email,
            role,
          })),
        );
        emails.forEach(() => {
          analytics.trackEvent(ANALYTICS_EVENTS.ADD_TEAM_MEMBER, {
            role,
          });
        });

        onboardingUseCase.completeInviteTeamMemberStep();
        enqueueSnackbar(t('teamManagement.inviteDialog.successToast.title'), {
          variant: 'success',
          preventDuplicate: true,
          description: t('teamManagement.inviteDialog.successToast.subtitle'),
        });

        onSuccess && onSuccess();
      } catch (error) {
        enqueueSnackbar(tCommon('errors.errorOccurred'), {
          variant: 'error',
          preventDuplicate: true,
        });
        logger.error(error);
      } finally {
        setIsProcessing(false);
      }
    })();
  }

  const inviteCharcheableWarning =
    invitesAccessability.data?.warning === 'ActionChargeable'
      ? ({
          visible: true,
          props: {
            limit: invitesAccessability.data?.limit || 1,
            type: 'ActionChargeable',
          },
        } satisfies InviteCharchableWarning)
      : ({
          visible: false,
        } satisfies InviteCharchableWarning);

  const inviteGiftLimitationWarning =
    invitesAccessability.data?.warning === 'ActionGiftLimitation'
      ? ({
          visible: true,
          props: {
            limit: invitesAccessability.data?.limit || 1,
            type: 'ActionGiftLimitation',
          },
        } satisfies InviteGiftLimitationWarning)
      : ({
          visible: false,
        } satisfies InviteGiftLimitationWarning);

  const inviteLimitReachError =
    invitesAccessability.error instanceof WorkspaceSeatsLimitError && !isProcessing
      ? ({
          visible: true,
          props: {
            error: invitesAccessability.error,
          },
        } satisfies InviteLimitReachError)
      : ({
          visible: false,
        } satisfies InviteLimitReachError);

  const sendActionDisabled =
    !form.formState.isValid ||
    !emails.length ||
    isProcessing ||
    invitesAccessability.error instanceof WorkspaceSeatsLimitError;

  return {
    form,
    inviteLimitReachError,
    inviteCharcheableWarning,
    inviteGiftLimitationWarning,
    sendActionDisabled,
    handleSubmitForm,
  };
};
