import React from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';

import { BillingBanner } from '@/features/common/billing';
import useSettingsViewModel from '@/features/settings/ui/SettingsViewModel';

import SettingsHeader from './components/SettingsHeader';

import styles from './styles.module.scss';

const Settings: React.FC = () => {
  const { headerData } = useSettingsViewModel();

  return (
    <div className={styles.settings}>
      <SettingsHeader data={headerData} />
      <BillingBanner />
      <Box className={styles.settingsContentWrapper}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Settings;
