import { injectable } from 'inversify';
import { Observable } from 'rxjs';

import { DbState, IDbState } from '@/features/system/db/data/DbState';

import type { IIntegrationMapperDC, IIntegrationSettingsDC } from '../dataContracts';

type Provider = IIntegrationSettingsDC['provider'];

export interface IIntegrationStateDC {
  [key: Provider]: Nullable<{
    integration: IIntegrationSettingsDC;
    mappings: {
      [key: string]: Nullable<IIntegrationMapperDC>;
    };
  }>;
}

export type IIntegrationState = IDbState<IIntegrationStateDC> & {
  getSettings: (provider: Provider) => Observable<Nullable<IIntegrationSettingsDC>>;
  setSettings: (provider: Provider, integration: IIntegrationSettingsDC) => Promise<void>;

  getMapping: (
    provider: Provider,
    mappingObjectType: string,
  ) => Observable<Nullable<IIntegrationMapperDC>>;
  setMapping: (
    provider: Provider,
    mappingObjectType: string,
    mapping: IIntegrationMapperDC,
  ) => Promise<void>;
};

@injectable()
export class IntegrationState
  extends DbState<IIntegrationStateDC>
  implements IIntegrationState
{
  constructor() {
    super({ stateName: 'integration' });
  }

  private getIntegrationKey(provider: IIntegrationSettingsDC['provider']): string {
    return `${provider}.integration`;
  }

  private getMappingKey(
    provider: IIntegrationSettingsDC['provider'],
    mappingObjectType: string,
  ): string {
    return `${provider}.mappings.${mappingObjectType}`;
  }

  getSettings(
    provider: IIntegrationSettingsDC['provider'],
  ): Observable<Nullable<IIntegrationSettingsDC>> {
    return this.get$(this.getIntegrationKey(provider)) as Observable<
      Nullable<IIntegrationSettingsDC>
    >;
  }

  setSettings(provider: Provider, integration: IIntegrationSettingsDC): Promise<void> {
    return this.set(this.getIntegrationKey(provider), () => integration);
  }

  getMapping(
    provider: Provider,
    mappingObjectType: string,
  ): Observable<Nullable<IIntegrationMapperDC>> {
    return this.get$(this.getMappingKey(provider, mappingObjectType)) as Observable<
      Nullable<IIntegrationMapperDC>
    >;
  }

  setMapping(
    provider: Provider,
    mappingObjectType: string,
    mapping: IIntegrationMapperDC,
  ): Promise<void> {
    return this.set(this.getMappingKey(provider, mappingObjectType), () => mapping);
  }
}
