import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { Tabs } from '@/components';
import { ArrowBackIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type SubHeadTitleProps = {
  providerName: string;
};

type SubHeadTabsProps = {
  handleTabChange: (value: string) => void;
  currentTab: string;
};

export enum TabName {
  Settings = 'settings',
  FieldMapping = 'field_mapping',
}

type SubHeadIntegration = SubHeadTabsProps & SubHeadTitleProps;

const SubheadIntegrationTitleWithNavigation: FC<SubHeadTitleProps> = ({
  providerName,
}) => {
  const navigate = useNavigate();
  const goBack = (): void => {
    navigate(ROUTES.INTEGRATIONS);
  };
  const { t } = useTranslation('integrations', { keyPrefix: 'integration' });
  return (
    <Box className={styles.titleWrapper} onClick={goBack}>
      <ArrowBackIcon className={styles.icon} />
      <Box className={styles.titleContainer}>
        <Typography variant="h1" className={styles.title}>
          {t('title', { integrationName: providerName })}
        </Typography>
        <Typography variant="subtitle1" className={styles.description}>
          {t('description')}
        </Typography>
      </Box>
    </Box>
  );
};

const SubHeadTabs: FC<SubHeadTabsProps> = ({ handleTabChange, currentTab }) => {
  const { t } = useTranslation('integrations', { keyPrefix: 'integration.tabs' });

  return (
    <Box className={styles.tabsWrapper}>
      <Tabs
        options={[
          { label: t('settings.tabName'), value: TabName.Settings },
          { label: t('fieldMapping.tabName'), value: TabName.FieldMapping },
        ]}
        value={currentTab}
        onChange={(_, value): void => handleTabChange(value)}
      />
    </Box>
  );
};
export const SubHeadIntegration: FC<SubHeadIntegration> = ({
  children,
  ...props
}: PropsWithChildren<SubHeadIntegration>) => {
  return (
    <Box className={styles.SubHead}>
      <SubheadIntegrationTitleWithNavigation providerName={props.providerName} />
      <SubHeadTabs
        handleTabChange={props.handleTabChange}
        currentTab={props.currentTab}
      />
      {children}
    </Box>
  );
};
