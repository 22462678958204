import { initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { inject, injectable } from 'inversify';
import { firstValueFrom, from, Observable } from 'rxjs';

import { APP_CONFIG_TYPES } from '@/ioc/types';

import type { IGetFreeCreditsRepository } from '@/features/getFreeCredits';

import type { AppConfig } from '@/config';

@injectable()
export class GetFreeCreditsRepository implements IGetFreeCreditsRepository {
  private _auth: Auth;

  constructor(@inject(APP_CONFIG_TYPES.AppConfig) appConfig: AppConfig) {
    this._auth = getAuth(initializeApp(appConfig.firebase, 'secondary'));
  }

  public buildGoogleProvider(): GoogleAuthProvider {
    const provider = new GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.addScope('https://www.googleapis.com/auth/contacts.other.readonly');
    provider.addScope('https://www.googleapis.com/auth/directory.readonly');

    return provider;
  }

  public buildMicrosoftProvider(): OAuthProvider {
    const provider = new OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      prompt: 'consent',
    });
    provider.addScope('contacts.read');
    provider.addScope('contacts.read.shared');
    provider.addScope('people.read');
    provider.addScope('people.read.all');

    return provider;
  }

  public requestScopes = async (
    providerInstance: OAuthProvider | GoogleAuthProvider,
    providerType: typeof OAuthProvider | typeof GoogleAuthProvider,
  ): Promise<{ accessToken: string; refreshToken: string }> => {
    const result = await firstValueFrom(this.signInWithProvider(providerInstance));

    // @ts-ignore
    const refreshToken: string = result._tokenResponse.refreshToken;
    const credential = providerType.credentialFromResult(result);

    if (!credential) throw new Error('Credential not found');
    if (!credential.accessToken) throw new Error('Access token not found');

    return { accessToken: credential.accessToken, refreshToken };
  };

  private signInWithProvider(
    provider: OAuthProvider | GoogleAuthProvider,
  ): Observable<UserCredential> {
    return from(signInWithPopup(this._auth, provider));
  }
}
