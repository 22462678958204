import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';

import { EXTERNAL_ROUTES } from '@/router/routes';

import { PlanAction } from '@/features/plans/ui/types';

import styles from './styles.module.scss';

type PlanActionProps = {
  children: ReactNode;
  className?: string;
  color?: ButtonProps['color'];
  onClick?: () => void;
  variant: ButtonProps['variant'];
  href?: string;
};

const PlanActionButton: FC<PlanActionProps> = ({
  children,
  className,
  onClick,
  variant,
  color,
  href,
}) => {
  return (
    <Button
      color={color ?? 'gradientOrangeToRed'}
      className={clsx(styles.planAction, className)}
      variant={variant}
      onClick={onClick}
      size="medium"
      fullWidth
      href={href}
    >
      {children}
    </Button>
  );
};

type ClickableActionButton = FC<{ onClick?: () => void }>;

const UpgradePlanActiobButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  return (
    <PlanActionButton variant="contained" onClick={onClick}>
      {t('upgradePlan')}
    </PlanActionButton>
  );
};

const UpdatePlanActiobButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  return (
    <PlanActionButton variant="contained" onClick={onClick}>
      {t('updatePlan')}
    </PlanActionButton>
  );
};

// const CommingSoonActionButton: FC = () => {
//   const { t } = useTranslation('plans', { keyPrefix: 'actions' });

//   return (
//     <PlanActionButton variant="text" className={styles.soon}>
//       {t('commingSoon')}
//     </PlanActionButton>
//   );
// };

const CurrentActionButton: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  return (
    <PlanActionButton variant="text" className={styles.current}>
      {t('current')}
    </PlanActionButton>
  );
};

const ChangeBillingCycleActionButton: ClickableActionButton = ({ onClick }) => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  return (
    <PlanActionButton variant="contained" onClick={onClick}>
      {t('changeBillingCycle')}
    </PlanActionButton>
  );
};

const TalkToSalesActionButton: FC = () => {
  const { t } = useTranslation('plans', { keyPrefix: 'actions' });

  return (
    <PlanActionButton
      variant="contained"
      href={`${EXTERNAL_ROUTES.REQUEST_DEMO}?source=expand`}
    >
      {t('contactUs')}
    </PlanActionButton>
  );
};

// const GetStartedActionButton: ClickableActionButton = ({ onClick }) => {
//   const { t } = useTranslation('plans', { keyPrefix: 'actions' });

//   return (
//     <PlanActionButton variant="contained" onClick={onClick}>
//       {t('getStarted')}
//     </PlanActionButton>
//   );
// };

const PlanActionFabric: FC<{ action: PlanAction; onClick?: () => void }> = ({
  action,
  onClick,
}) => {
  switch (action) {
    case PlanAction.UpgradePlan:
      return <UpgradePlanActiobButton onClick={onClick} />;
    case PlanAction.ChangeBillingCycle:
      return <ChangeBillingCycleActionButton onClick={onClick} />;
    case PlanAction.TalkToSales:
      return <TalkToSalesActionButton />;
    case PlanAction.UpdatePlan:
      return <UpdatePlanActiobButton onClick={onClick} />;
    case PlanAction.CurrentPlan:
      return <CurrentActionButton />;
    default:
      return null;
  }
};

type PlanActionsProps = {
  actions: Array<{ type: PlanAction; onClick?: () => void }>;
};

export const PlanActions: FC<PlanActionsProps> = ({ actions }) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <div className={styles.planActions}>
      {actions.map((action) => (
        <PlanActionFabric
          key={action.type}
          action={action.type}
          onClick={action.onClick}
        />
      ))}
    </div>
  );
};
