import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ROUTES } from '../routes';

import { ErrorLayout } from './components';

/**
 * NOTE!
 * This temporary component is used to display the 403 error page.
 * we should ask designers to create a new 403 page and replace this component
 * with the new one.
 */
export const ErrorPage403: React.FC = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const onHomeButtonClick = (): void => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <ErrorLayout
      icon={null}
      title={'403'}
      description={t('403PageDescription')}
      buttonText={t('403PageButton')}
      onButtonClick={onHomeButtonClick}
    />
  );
};
