import { injectable } from 'inversify';

import { DbStateCreator } from '@/features/system/db/data/DbStateCreator';

import { IIntegrationsConfigDC } from '../dataContracts';

@injectable()
export class IntegrationsStateCreator extends DbStateCreator<IIntegrationsConfigDC> {
  constructor() {
    super({
      stateName: 'integrations',
    });
  }
}
