import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { useUploadEnrichmentFile } from '../../hooks';

import styles from './styles.module.scss';

export const EnrichmentUploadAction: FC = () => {
  const { t } = useTranslation('enrichment');
  const { inputRef, onChange, onClick } = useUploadEnrichmentFile();

  return (
    <Box className={styles.Wrapper}>
      <input
        ref={inputRef}
        type="file"
        name="file"
        accept=".csv, .xls, .xlsx"
        hidden
        onChange={onChange}
      />
      <Button variant="contained" onClick={onClick}>
        {t('subhead.upload.button')}
      </Button>
    </Box>
  );
};
