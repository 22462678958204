import { FC } from 'react';
import { Box, Grid, IconButton, Popover, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { ArrowDownIcon } from '@/components/Icons';

import { UserData } from '../../types';

import { UserMenuPopover } from './UserMenuPopover';
import { useUserMenuViewModel } from './UserMenuViewModel';

import styles from './styles.module.scss';

type UserIconContainerProps = {
  userData: UserData;
};

export const UserMenuContainer: FC<UserIconContainerProps> = ({ userData }) => {
  const {
    fullName,
    email,
    planType,
    userLogo,
    creditsUsed,
    creditsLimit,
    hasUserReceivedFreeCredits,
    role,
  } = userData;
  const {
    isOpen,
    open,
    close,
    onLogout,
    anchorEl,
    handleInviteTeamMember,
    navigateToHelpCenter,
    navigateToSettings,
    navigateToTeamManagement,
    navigateToSubscription,
    navigateToTagManagement,
    navigateToReferAFriend,
  } = useUserMenuViewModel();
  const initials = fullName
    .split(' ')
    .map((i) => i.slice(0, 1))
    .splice(0, 2)
    .join('');

  return (
    <>
      <Grid
        alignItems="center"
        flexWrap="nowrap"
        onClick={open}
        className={styles.container}
      >
        <Box className={styles.avatarWrapper}>
          <Avatar alt={fullName} src={userLogo} className={styles.avatar}>
            {initials}
          </Avatar>
        </Box>
        <Box className={styles.nameContainer}>
          <Box mr={1.25} />
          <Typography variant="h3">
            {fullName.length > 15 ? fullName.substring(0, 15).concat('...') : fullName}
          </Typography>
          <Box mr={2} />
          <IconButton size="small">
            <ArrowDownIcon className={styles.arrowDown} />
          </IconButton>
        </Box>
      </Grid>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: -15,
          horizontal: 'left',
        }}
      >
        <UserMenuPopover
          role={role}
          fullName={fullName}
          initials={initials}
          email={email}
          planType={planType}
          userLogo={userLogo}
          creditsUsed={creditsUsed}
          creditsLimit={creditsLimit}
          navigateToSettings={navigateToSettings}
          navigateToTeamManagement={navigateToTeamManagement}
          navigateToSubscription={navigateToSubscription}
          navigateToTagManagement={navigateToTagManagement}
          navigateToHelpCenter={navigateToHelpCenter}
          onReferFriendClick={navigateToReferAFriend}
          onLogout={onLogout}
          hasUserReceivedFreeCredits={hasUserReceivedFreeCredits}
          handleInviteTeamMember={handleInviteTeamMember}
        />
      </Popover>
    </>
  );
};
