import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import type { IIntegrationEntity } from '@/features/integrations/domain';
import { useAppLogger } from '@/features/system/logger';

import { useAsyncExecutor } from '@/hooks';

import { useIntegrationsUseCase } from '../../../../hooks';

export const useContactAdminDialogViewModel = (integration: IIntegrationEntity) => {
  const { t } = useTranslation('integrations');
  const integrationsUseCases = useIntegrationsUseCase();
  const appLogger = useAppLogger();

  return useAsyncExecutor(
    () => integrationsUseCases.sendIntegrationRequestToAdmin(integration.id),
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: `success`,
          message: t('contactAdminDialog.success', {
            provider: integration.name,
          }),
        });
      },
      onError: (error) => {
        appLogger.error(error);
        enqueueSnackbar({
          variant: `error`,
          message: t('contactAdminDialog.failed', {
            provider: integration.name,
          }),
        });
      },
    },
  );
};
