import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { SettingsHeaderData } from '@/features/settings/ui/Settings.types';

import styles from './styles.module.scss';

type Props = {
  data: SettingsHeaderData;
};

const SettingsHeader: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation('settings');

  return (
    <div className={styles.Header}>
      <Typography variant="h1" className={styles.Title}>
        {t('title')}
      </Typography>
      <div className={styles.Links}>
        {data.map(({ title, isActive, to }, index) => {
          return (
            <NavLink key={index} to={to} className={clsx({ [styles.Active]: isActive })}>
              <Typography variant="subtitle1" fontWeight={500}>
                {title}
              </Typography>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsHeader;
