import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import {
  useAggregatedCreditsProgress,
  useFullAggregatedCreditsInfo,
} from '@/features/common/workspace';

import { LinearProgress } from '@/components/LinearProgress';

import styles from './styles.module.scss';

export const CreditBasedView: FC = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'header.userMenu.creditUsage',
  });
  const { data: fullAggregatedCreditsInfo } = useFullAggregatedCreditsInfo();
  const { data: aggregatedCreditsProgress } = useAggregatedCreditsProgress();

  if (!fullAggregatedCreditsInfo) {
    return null;
  }

  return (
    <Stack alignItems="flex-start" className={styles.wrapper}>
      <Stack
        direction="row"
        className={styles.item}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box component="span" className={styles.label}>
          {t('creditBased.label')}
        </Box>
        <Box component="span" className={styles.value}>
          {fullAggregatedCreditsInfo.isUnlimited
            ? t('creditBased.unlimited')
            : `${fullAggregatedCreditsInfo.used}/${fullAggregatedCreditsInfo.limit}`}
        </Box>
      </Stack>
      <LinearProgress value={aggregatedCreditsProgress} />
      {fullAggregatedCreditsInfo.isUnlimited ? null : (
        <CallToAction
          size="small"
          show={'getCredits'}
          variant="contained"
          color="gradientOrangeToRed"
          className={styles.button}
        />
      )}
    </Stack>
  );
};
