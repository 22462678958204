import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';

type TagBadgeProps = BoxProps & {
  bgColor?: string;
};

const Wrapper = styled(Box)(() => ({
  padding: '0 10px',
  borderRadius: '4px',
  display: 'inline-flex',
  alignItems: 'center',
  height: '24px',
  fontFamily: 'SofiaPro',
  color: '#fff',
  fontSize: '12px',
  lineHeight: 1,
}));

const Text = styled(Typography)(() => ({
  color: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
}));

export const TagBadge: FC<PropsWithChildren<TagBadgeProps>> = ({
  children,
  bgColor,
  ...props
}) => {
  return (
    <Wrapper {...props} sx={{ backgroundColor: bgColor }}>
      {typeof children === 'string' ? <Text>{children}</Text> : children}
    </Wrapper>
  );
};
