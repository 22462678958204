import { FC, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import clsx from 'clsx';

import {
  ProspectTaskNotificationWidgetItem,
  ProspectTaskNotificationWidgetItemType,
} from './ProspectTaskNotificationWidgetItem';
import { useProspectTaskNotificationWidgetViewModel } from './useProspectTaskNotificationWidgetViewModel';

import styles from './styles.module.scss';

const AnimatedItem: FC<{
  item: ProspectTaskNotificationWidgetItemType;
  isLast: boolean;
}> = ({ item, isLast }) => {
  const elementRef = useRef<HTMLElement | null>(null);

  return (
    <CSSTransition
      key={item.id}
      timeout={500}
      classNames="notificationBarItemFade"
      nodeRef={elementRef}
    >
      <Box ref={elementRef}>
        <Box>
          <ProspectTaskNotificationWidgetItem item={item} />
        </Box>
        <Box className={styles.itemDivider} />
        {!isLast && <Box className={styles.itemDivider} />}
      </Box>
    </CSSTransition>
  );
};

export const ProspectTaskNotificationWidgetBody: FC<{
  innerOpen: boolean;
  onClose(): void;
  onToggleInner(): void;
}> = ({ innerOpen, onClose, onToggleInner }) => {
  const { items, title } = useProspectTaskNotificationWidgetViewModel();
  const itemsLength = items.length;
  const contentRef = useRef<HTMLElement | null>(null);

  return (
    <Box>
      <Box className={styles.header}>
        <Box component="span" className={styles.headerTitle}>
          {title}
        </Box>
        <Box className={styles.headerActions}>
          <Box
            className={clsx(styles.headerButton, {
              [styles.isOpen]: innerOpen,
            })}
            onClick={onToggleInner}
          >
            <ExpandMoreIcon />
          </Box>
          <Box className={styles.headerButton} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
      </Box>
      <CSSTransition in={innerOpen} timeout={300} unmountOnExit nodeRef={contentRef}>
        <Box className={styles.content} ref={contentRef}>
          <TransitionGroup className="notificationBarList">
            {items.map((item, index) => {
              const isLastItem = index === itemsLength - 1;
              return <AnimatedItem key={item.id} item={item} isLast={isLastItem} />;
            })}
          </TransitionGroup>
        </Box>
      </CSSTransition>
    </Box>
  );
};
