import { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router';

import { ROUTES } from '@/router/routes';

export const EmailLinksLanderRoute: FC = () => {
  const [searchParams] = useSearchParams();

  const mode = searchParams.get('mode');

  const withSearchParams = (path: string): string => `${path}?${searchParams.toString()}`;

  switch (mode) {
    case 'resetPassword':
      return <Navigate to={withSearchParams(ROUTES.RESET_PASSWORD)} replace />;
    case 'verifyEmail':
      return (
        <Navigate
          to={withSearchParams(ROUTES.SIGN_UP_REDIRECT_SUCCESS_VERIFICATION)}
          replace
        />
      );
    default:
      return <Navigate to={ROUTES.DASHBOARD} replace />;
  }
};
