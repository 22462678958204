import { useSearchParams } from 'react-router';

export const useQuantityFromUrl = (
  defaultValue: number,
): [number, (seats: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const seatsAsString = searchParams.get('quantity');

  let seats = seatsAsString ? parseInt(seatsAsString, 10) : defaultValue;

  if (Number.isNaN(seats)) seats = defaultValue;

  const handleSeatsChange = (newSeats: number): void => {
    if (newSeats === seats) return;
    searchParams.set('quantity', newSeats.toString());
    setSearchParams(searchParams, { replace: true });
  };

  return [seats, handleSeatsChange];
};
