import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button, Divider, Typography } from '@mui/material';

import { ROUTES } from '@/router/routes';

import { useIntegrationsUseCase } from '@/features/integrations';
import { IIntegrationEntity } from '@/features/integrations/domain';

import { Modal } from '@/components';
import { LogoBase } from '@/components/Icons';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconError from '@/assets/icons/integrations/close-rount-red-icon.svg?react';

import styles from './styles.module.scss';

export const SalesforceApiDisabledErrorDialog: FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  const { t } = useTranslation('integrations');
  const navigate = useNavigate();
  const integrationsUseCase = useIntegrationsUseCase();
  const integration = integrationsUseCase
    .getIntegrations(true)
    .find((integration) => integration.id === 'salesforce') as IIntegrationEntity;

  const goToIntegrations = (): void => {
    navigate(ROUTES.INTEGRATIONS);
  };

  return (
    <Modal
      containerClass={styles.paper}
      open={isOpen}
      onClose={goToIntegrations}
      keepMounted={false}
    >
      <Box className={styles.dialog}>
        <IconClose onClick={goToIntegrations} className={styles.closeIcon} />
        <Box className={styles.dialogIcons}>
          <Box className={styles.withBorder}>
            <LogoBase />
          </Box>
          <Divider className={styles.divider} />
          <IconError />
          <Box className={styles.integrationIconWrapper}>
            <img src={integration.icon} alt={t(integration.name)} style={{ width: 65 }} />
          </Box>
        </Box>
        <Typography className={styles.dialogTitle}>{t('errorDialog.title')}</Typography>
        <Typography className={styles.dialogSubtitle}>
          <Trans
            i18nKey={'errorDialog.descriptionSalesForce'}
            ns="integrations"
            components={{
              a: (
                <a
                  className={styles.link}
                  target={'_blank'}
                  href="https://help.salesforce.com/s/articleView?id=000386981&type=1"
                ></a>
              ),
            }}
          />
        </Typography>
        <Button
          onClick={goToIntegrations}
          className={styles.dialogButton}
          color="primary"
          variant="contained"
        >
          {t('errorDialog.submit')}
        </Button>
      </Box>
    </Modal>
  );
};
