import { FC } from 'react';

import { Modal } from '@/components';

import { IntegrationConnectDialog } from './components/IntegrationConnectDialog';
import { IntegrationContactAdminDialog } from './components/IntegrationContactAdminDialog';
import { IntegrationErrorDialog } from './components/IntegrationErrorDialog';
import { IntegrationRequestDialog } from './components/IntegrationRequestDialog';
import { IntegrationUpgradeDialog } from './components/IntegrationUpgradeDialog';
import type { IntegrationDialogConfig } from './types';

import styles from './styles.module.scss';

const IntegrationDialogComponent: FC<{ config: IntegrationDialogConfig }> = ({
  config,
}) => {
  switch (config.component) {
    case 'ErrorDialog':
      return <IntegrationErrorDialog {...config} />;
    case 'ContactAdminDialog':
      return <IntegrationContactAdminDialog {...config} />;
    case 'RequestDialog':
      return <IntegrationRequestDialog {...config} />;
    case 'UpgradeDialog':
      return <IntegrationUpgradeDialog {...config} />;
    case 'ConnectDialog':
      return <IntegrationConnectDialog {...config} />;
    default:
      return <></>;
  }
};

export const IntegrationDialog: FC<{
  config: IntegrationDialogConfig | null;
  onClose: () => void;
}> = ({ config, onClose }) => {
  return (
    <Modal
      containerClass={styles.paper}
      open={!!config}
      onClose={onClose}
      keepMounted={false}
    >
      <IntegrationDialogComponent config={config as IntegrationDialogConfig} />
    </Modal>
  );
};
