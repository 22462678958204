import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, Button, Divider, Typography } from '@mui/material';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { Logo } from '@/components/Icons';

import ChromeIcon from '@/assets/icons/chromeIcon.svg?react';

import { useInstallExtensionPageViewModel } from './useInstallExtensionPageViewModel';

import styles from './styles.module.scss';

export const InstallExtensionPage: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'installExtensionPage' });
  const { name, onSkip, onInstall } = useInstallExtensionPageViewModel();

  return (
    <Box className={styles.page}>
      <Box className={styles.header}>
        <NavLink to={ROUTES.DASHBOARD}>
          <Box>
            <Logo />
          </Box>
        </NavLink>
      </Box>
      <Box className={styles.body}>
        <Typography variant="h1" fontSize="43px" fontWeight="900">
          {t('welcomeHeading', { name })}
        </Typography>
        <Typography className={styles.subtitle}>
          <Trans t={t} key="unlockSubtitle">
            Get started with <span>Powerlead</span> by installing our Chrome extension
          </Trans>
        </Typography>
        <Box className={styles.card}>
          <ChromeIcon />
          <Typography fontWeight="600" fontSize="25px" lineHeight="30px">
            {t('cardTitle')}
          </Typography>
          <Typography className={styles.cardSubtitle}>{t('cardSubtitle')}</Typography>
          <Button
            variant="contained"
            className={styles.cardButton}
            href={EXTERNAL_ROUTES.EXTENSION_URL}
            onClick={onInstall}
            target="_blank"
          >
            {t('cardButton')}
          </Button>
          <Divider className={styles.divider} />
          <Box className={styles.list}>
            {Array.from({ length: 6 }, (_, index) => {
              return (
                <Typography key={index}>
                  <CheckIcon />
                  {t(`cardListPoint${index}`)}
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Button className={styles.skipButton} onClick={onSkip}>
          {t('skipButton')}
          <TrendingFlatIcon fontSize="small" />
        </Button>
      </Box>
    </Box>
  );
};
