import { FC, memo } from 'react';
import { Box } from '@mui/material';

import { type IContactEntity, ContactsTable as Table } from '@/features/common/contact';
import type { IContactListEntity } from '@/features/common/contactList';

import { useContactsTableViewModel } from './useContactsTableViewModel';

import styles from './styles.module.scss';

type ContactsTableProps = {
  currentContactList: IContactListEntity;
  defaultContactList: IContactListEntity;
};

export const ContactsTable: FC<ContactsTableProps> = memo(
  ({ currentContactList, defaultContactList }) => {
    const {
      columns,
      rows,
      isLoading,
      handleSortModelChange,
      pageSize,
      pagesCount,
      page,
      contactEntitiesCount,
      handlePaginationChange,
      noResultsOverlay,
      handleRowSelectionModelChange,
    } = useContactsTableViewModel({ currentContactList, defaultContactList });

    return (
      <Box className={styles.wrapper}>
        <Box className={styles.table}>
          <Table
            columns={columns}
            rows={rows}
            loading={isLoading}
            getRowId={(row: IContactEntity) => row.uuid}
            pageSize={pageSize}
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={handleRowSelectionModelChange}
            checkboxSelection={true}
            slots={{
              noResultsOverlay,
            }}
            slotProps={{
              noResultsOverlay: {
                // @ts-ignore
                defaultContactListId: defaultContactList.uuid,
              },
            }}
            classes={{ main: styles.main }}
          />
        </Box>
        <Box className={styles.pagination}>
          <Table.Pagination
            showExtraInfo
            isLoading={isLoading}
            pageSize={pageSize}
            page={page}
            count={pagesCount}
            totalCount={contactEntitiesCount}
            onChange={handlePaginationChange}
          />
        </Box>
      </Box>
    );
  },
);
