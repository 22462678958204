import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { clsx } from 'clsx';

import { ROUTES } from '@/router/routes';

import { IContactListEntityUI } from '@/features/common/contactList';

import { Container, ContainerVariant } from '@/components';

import {
  useEditFieldAction,
  useListsTableColumns,
  useProcessCellEditStop,
} from './hooks';

import styles from './styles.module.scss';
export type ListsTableProps = {
  rows: IContactListEntityUI[];
  onSortSchemaChange: (sortSchema: GridSortModel) => void;
  isLoading?: boolean;
};
export interface GridSortItem {
  field: string;
  sort: GridSortDirection;
}
export type GridSortDirection = 'asc' | 'desc' | null | undefined;
export type GridSortModel = GridSortItem[];

export const ListsTable: FC<ListsTableProps> = ({
  rows = [],
  onSortSchemaChange,
  isLoading,
}) => {
  const columns = useListsTableColumns();
  const navigate = useNavigate();
  const { handleEditRow } = useEditFieldAction();
  const onCellEditStop = useProcessCellEditStop();

  const handleRowClick = (params): void => {
    const path = ROUTES.CONTACTS_INNER.replace(':id', params.id);
    navigate(path);
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    onSortSchemaChange(sortModel);
  }, []);

  return (
    <Container variant={ContainerVariant.XXL}>
      <DataGridPro
        columnHeaderHeight={40}
        variant="gapped"
        editMode="cell"
        isCellEditable={({ row }): boolean => {
          return !row.default;
        }}
        columns={columns}
        sortingMode={'server'}
        rows={rows}
        rowHeight={60}
        hideFooter
        disableColumnReorder
        disableColumnMenu
        disableColumnFilter
        rowSelection={false}
        onRowClick={handleRowClick}
        onSortModelChange={handleSortModelChange}
        classes={{
          root: clsx(styles.listTable),
          row: clsx(styles.listRow),
        }}
        processRowUpdate={handleEditRow}
        onCellEditStop={onCellEditStop}
        loading={isLoading}
      />
    </Container>
  );
};
