import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { UserRole } from '@/features/common/account';
import { PlanType } from '@/features/common/billing';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import {
  ActionDisableGuard,
  HasPermission,
  Permission,
} from '@/features/common/permissions';

// import ReferFriendIcon from '@/features/referral/ui/assets/referFriend.svg?react';
import {
  ChromeIcon,
  CopyIcon,
  FeatureIcon,
  FlashOutlinedIcon,
  GroupManagementIcon,
  HelpAndSupportIcon,
  LaunchIcon,
  LogOutIcon,
  SettingsIcon,
  TagManagementIcon,
} from '@/components/Icons';

import { useClipboardWithToast } from '@/hooks';

import { UserMenuCreditUsage } from './components/UserMenuCreditUsage';

import styles from './styles.module.scss';

import { useAppConfig } from '@/config';

type UserMenuProps = {
  fullName: string;
  email: string;
  planType: PlanType;
  creditsUsed: number;
  creditsLimit: number;
  userLogo: string;
  hasUserReceivedFreeCredits: boolean;
  role: UserRole;
  initials: string;
  navigateToSettings(): void;
  onLogout: () => void;
  navigateToTeamManagement(): void;
  navigateToSubscription(): void;
  navigateToTagManagement(): void;
  navigateToHelpCenter(): void;
  onReferFriendClick: () => void;
  handleInviteTeamMember: () => void;
};

const MenuButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const { onClick, ...otherProps } = props;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick && onClick(e);
  };

  return (
    <Button
      variant="text"
      size="small"
      className={styles.textButton}
      style={{ padding: 0 }}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const UserMenuPopover: FC<UserMenuProps> = ({
  fullName,
  initials,
  email,
  // planType,
  userLogo,
  navigateToSettings,
  navigateToTeamManagement,
  navigateToSubscription,
  navigateToTagManagement,
  navigateToHelpCenter,
  // onReferFriendClick,
  onLogout,
  handleInviteTeamMember,
}) => {
  const { t } = useTranslation();

  const copyToClipboard = useClipboardWithToast();
  const onboardingUseCase = useOnboardingUseCase();
  const appConfig = useAppConfig();

  return (
    <Stack p="10px" minWidth="280px" gap={1}>
      <Grid>
        <Box className={styles.avatarWrapper}>
          <Avatar alt={fullName} src={userLogo} className={styles.avatar}>
            {initials}
          </Avatar>
        </Box>

        <Box mr={1.25} />
        <Stack justifyContent="center">
          <Typography variant="h3">{fullName}</Typography>
          <Typography variant="subtitle2" color="info">
            {email}
          </Typography>
        </Stack>
      </Grid>

      <Box className={styles.divider} />

      <UserMenuCreditUsage />

      <Box className={styles.divider} />

      <Stack alignItems="flex-start">
        <MenuButton
          onClick={navigateToSettings}
          startIcon={<SettingsIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.account')}</Typography>
        </MenuButton>

        <MenuButton
          onClick={navigateToTeamManagement}
          startIcon={<GroupManagementIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.teamManagement')}</Typography>
        </MenuButton>

        <HasPermission permission={Permission.CanAccessBilling}>
          <MenuButton
            onClick={navigateToSubscription}
            startIcon={<FlashOutlinedIcon color="info" />}
          >
            <Typography variant="subtitle2">{t('header.subscription')}</Typography>
          </MenuButton>
        </HasPermission>

        <MenuButton
          onClick={navigateToTagManagement}
          startIcon={<TagManagementIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.tagManagement')}</Typography>
        </MenuButton>
      </Stack>

      <Box className={styles.divider} />

      <HasPermission permission={Permission.CanManageMembers}>
        <Stack alignItems="flex-start">
          <ActionDisableGuard>
            <MenuButton
              onClick={handleInviteTeamMember}
              startIcon={<GroupManagementIcon color="info" />}
              size="small"
            >
              <Typography variant="subtitle2">{t('header.inviteTeamMember')}</Typography>
            </MenuButton>
          </ActionDisableGuard>
        </Stack>
        <Box className={styles.divider} />
      </HasPermission>

      <Stack alignItems="flex-start">
        {/* {planType === PlanType.Free && (
          <MenuButton onClick={onReferFriendClick} startIcon={<ReferFriendIcon />}>
            <Typography variant="subtitle2">{t('header.referFriend')}</Typography>
          </MenuButton>
        )} */}
        <MenuButton startIcon={<FeatureIcon color="info" />}>
          <Typography variant="subtitle2">
            <Link
              href={appConfig.featureRequest.url}
              target="_blank"
              className={styles.externalLink}
            >
              {t('header.suggestFeature')}
            </Link>
          </Typography>
        </MenuButton>

        <Grid justifyContent="space-between" alignItems="center">
          <MenuButton startIcon={<ChromeIcon color="info" />}>
            <Typography variant="subtitle2">
              <Link
                href={appConfig.extension.url}
                target="_blank"
                className={styles.extensionLink}
                onClick={(): void => {
                  onboardingUseCase.completeClickDownloadExtensionStep();
                }}
              >
                {t('header.addExtension')}
              </Link>
            </Typography>
          </MenuButton>
          <IconButton
            className={styles.copyIcon}
            size="small"
            focusRipple
            onClick={(): void => {
              copyToClipboard(appConfig.extension.url);
              onboardingUseCase.completeClickDownloadExtensionStep();
            }}
          >
            <CopyIcon color="info" />
          </IconButton>
        </Grid>
        <Grid justifyContent="space-between" alignItems="center">
          <MenuButton
            startIcon={<HelpAndSupportIcon color="info" />}
            onClick={navigateToHelpCenter}
          >
            <Typography variant="subtitle2">{t('header.helpAndSupport')}</Typography>
          </MenuButton>
          <LaunchIcon color="info" className={styles.launchIcon} />
        </Grid>

        <MenuButton startIcon={<LogOutIcon color="info" />} onClick={onLogout}>
          <Typography variant="subtitle2">{t('header.logout')}</Typography>
        </MenuButton>
      </Stack>

      <Box className={styles.divider} />

      <Stack alignItems="flex-end">
        <Typography variant="subtitle3" color="info">
          {t('header.version', {
            version: appConfig.version,
          })}
        </Typography>
      </Stack>
    </Stack>
  );
};
