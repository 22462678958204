import { FC, useContext, useMemo } from 'react';
import { GridSortModel } from '@mui/x-data-grid';

import { Permission, usePermissions } from '@/features/common/permissions';

import { EnrichmentContext } from '../../EnrichmentContext';

import { NoResultsOverlay, NoRowsOverlay } from './components';
import { useEnrichmentTableColumns } from './useEnrichmentTableColumns';

export const useEnrichmentTableViewModel = () => {
  const { search, filterByUser, data, isLoading, isLoaded, setSort } =
    useContext(EnrichmentContext);
  const { hasPermissions } = usePermissions();
  const canAccessAllContacts = hasPermissions(Permission.CanAccessAllContact);
  const filtersInclude = canAccessAllContacts ? !!filterByUser || !!search : !!search;
  const columns = useEnrichmentTableColumns();

  const noRowsOverlay: FC | undefined = useMemo(() => {
    if (isLoaded && data.length === 0 && !filtersInclude) {
      return NoRowsOverlay;
    }

    return NoResultsOverlay;
  }, [isLoaded, data.length, filtersInclude]);

  const onSortModelChange = (model: GridSortModel): void => {
    setSort(model[0] || null);
  };

  return {
    noRowsOverlay,
    columns,
    data,
    isLoading,
    onSortModelChange,
  };
};
