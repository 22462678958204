import { FC, memo } from 'react';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { useEnrichmentTableViewModel } from './useEnrichmentTableViewModel';

import styles from './styles.module.scss';

export const EnrichmentTable: FC = memo(() => {
  const { noRowsOverlay, columns, data, isLoading, onSortModelChange } =
    useEnrichmentTableViewModel();

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.Container}>
        <DataGridPro
          columnHeaderHeight={60}
          variant="basic"
          editMode="cell"
          className={styles.Table}
          disableChildrenFiltering
          getRowId={(row) => row.uuid}
          classes={{
            columnHeaders: styles.ColumnHeader,
            row: styles.Row,
            cell: styles.Cell,
            root: styles.TableRoot,
          }}
          rowHeight={60}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableColumnFilter
          sortingMode="server"
          filterMode="server"
          rowsLoadingMode="server"
          hideFooterPagination
          disableColumnReorder
          loading={isLoading}
          rows={data}
          columns={columns}
          slots={{
            noRowsOverlay: noRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
          initialState={{
            pinnedColumns: {
              left: ['desk'],
            },
          }}
          onSortModelChange={onSortModelChange}
        />
      </Box>
    </Box>
  );
});
