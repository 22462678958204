import { inject, injectable } from 'inversify';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  firstValueFrom,
  Observable,
  retry,
  Subject,
  Subscription,
  switchMap,
  takeWhile,
  tap,
  throwError,
  timer,
} from 'rxjs';

import {
  APP_CONFIG_TYPES,
  LEADS_TRACKING_TYPES,
  NETWORK_TYPES,
  SYNC_TYPES,
} from '@/ioc/types';

import { ReferrerTokenKey } from '@/features/referral';
import type { ILeadsTrackingRepository } from '@/features/system/leadsTracking';
import { IHttpClient } from '@/features/system/network';
// @ts-ignore
import { HttpClientResponseObservable } from '@/features/system/network/HttpClient';

import {
  BaseSyncEntityType,
  IBaseSyncEvent,
  isBaseSyncErrorEvent,
  isBaseSyncInvalidateEvent,
  isDeleteBaseSyncEntity,
  SyncStatus,
} from '../../domain';
import { IResumeTokenManager } from '../ResumeTokenManager';

import type { ISseClient } from './SseClient';

import type { AppConfig } from '@/config';

interface BaseSyncServiceConfig {
  token: string;
}

export type IBaseSyncService = {
  start(config: BaseSyncServiceConfig): void;
  pause(): void;
  unpause(): void;
  stop(options?: { reset?: boolean }): void;
  events$: Observable<IBaseSyncEvent>;
  status$: Observable<SyncStatus>;
};

@injectable()
export class BaseSyncService implements IBaseSyncService {
  static RESUME_TOKEN_STORAGE_KEY = 'SSE_RESUME_TOKEN';

  @inject(SYNC_TYPES.ResumeTokenManager)
  private resumeTokenManager: IResumeTokenManager;

  @inject(SYNC_TYPES.SseClient)
  private sseClient: ISseClient;

  @inject(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
  private leadsTrackingRepository: ILeadsTrackingRepository;

  @inject(NETWORK_TYPES.HttpClient)
  private httpClient: IHttpClient;

  @inject(APP_CONFIG_TYPES.AppConfig)
  private appConfig: AppConfig;

  private isPaused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private config: BaseSyncServiceConfig | null = null;

  private currentStreamSubscription: Subscription | null = null;

  private _events$ = new Subject<IBaseSyncEvent>();

  get events$(): Observable<IBaseSyncEvent> {
    return this._events$;
  }

  private _status$: BehaviorSubject<SyncStatus> = new BehaviorSubject<SyncStatus>(
    SyncStatus.Inactive,
  );

  get status$(): Observable<SyncStatus> {
    return this._status$.pipe(distinctUntilChanged());
  }

  private set status(nextStatus: SyncStatus) {
    this._status$.next(nextStatus);
  }

  get status(): SyncStatus {
    return this._status$.getValue();
  }

  private connect(): void {
    if (!this.config) {
      throw new Error('Base sync config is not provided');
    }

    if (this.currentStreamSubscription) {
      this.disconnect();
    }

    const resumeToken = this.resumeTokenManager.getLastConfirmedToken();
    const referrerToken = localStorage.getItem(ReferrerTokenKey);
    const utm = this.leadsTrackingRepository.get();

    const url = new URL('/api/v1/stream', this.appConfig.api.url);

    url.searchParams.append('token', this.config.token);
    url.searchParams.append('app_type', 'dashboard');
    url.searchParams.append('app_version', this.appConfig.version);

    if (resumeToken) {
      url.searchParams.append('resume_token', resumeToken);
    }

    if (referrerToken) {
      url.searchParams.append('referrer_token', referrerToken);
    }

    if (utm) {
      url.searchParams.append('utm', JSON.stringify(utm));
    }

    this.status = resumeToken ? SyncStatus.Active : SyncStatus.Initializing;

    this.currentStreamSubscription = this.isPaused
      .pipe(
        filter((isPaused) => !isPaused),
        tap(() => {
          firstValueFrom(this.heartbeat());
        }),
        switchMap(() => this.sseClient.connect(url.toString())),
        takeWhile((e: IBaseSyncEvent) => !this.shouldStopStream(e), true),
        retry({
          delay: (error, retryCount) => {
            if (this.status === SyncStatus.Initializing) {
              return retryCount < 4
                ? timer(Math.pow(retryCount, retryCount) * 1_000)
                : throwError(() => error);
            }

            if (this.status !== SyncStatus.Inactive) {
              this.status = SyncStatus.Inactive;
            }

            if (retryCount <= 100) {
              return timer(retryCount * 2_000);
            }

            return throwError(() => error);
          },
          resetOnSuccess: true,
        }),
        tap(() => {
          // reset status on successful recovery
          if (this.status === SyncStatus.Inactive) {
            this.status = SyncStatus.Active;
          }
        }),
        catchError(() => {
          this.status = SyncStatus.Error;
          return EMPTY;
        }),
        finalize(() => {
          if (this.status !== SyncStatus.Error) {
            this.status = SyncStatus.Inactive;
          }
        }),
      )
      .subscribe((event) => {
        this._events$.next(event);

        if ('resume_token' in event && event.resume_token) {
          this.resumeTokenManager.draft({
            entityType: event.entity_type as string,
            token: event.resume_token,
            isConfirmed: false,
          });
          this.status = SyncStatus.Active;
        } else {
          this.resumeTokenManager.clear();
        }

        if (this.shouldStopStream(event)) {
          this.resumeTokenManager.clear();
        }
      });
  }

  private disconnect(): void {
    this.currentStreamSubscription?.unsubscribe();
  }

  private shouldStopStream(event: IBaseSyncEvent): boolean {
    return (
      isBaseSyncInvalidateEvent(event) ||
      (isDeleteBaseSyncEntity(event) &&
        event.entity_type === BaseSyncEntityType.Account) ||
      isBaseSyncErrorEvent(event)
    );
  }

  start(config: BaseSyncServiceConfig): void {
    this.config = config;
    this.connect();
  }

  pause(): void {
    this.disconnect();
    this.isPaused.next(true);
  }

  unpause(): void {
    this.isPaused.next(false);
    this.connect();
  }

  stop(options?: { reset?: boolean }): void {
    this.disconnect();

    if (options?.reset) {
      this.config = null;
      this.resumeTokenManager.clear();
    }
  }

  private heartbeat(): HttpClientResponseObservable<unknown> {
    return this.httpClient.get('/api/v1/account');
  }
}
