import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { Box } from '@mui/material';

import { useWidgetManager } from '@/hooks';

import { useProspectTaskProgressContext } from '../../hooks';

import { ProspectTaskNotificationWidgetBody } from './ProspectTaskNotificationWidgetBody';

import styles from './styles.module.scss';

export const ProspectTaskNotificationWidget: FC = () => {
  const containerRef = useRef<HTMLElement | null>(null);
  const { isVisible, show, hide, isAnotherOpened, isOpen, open, close } =
    useWidgetManager('ProspectTaskNotificationWidget', {
      isVisible: false,
      isOpen: false,
    });
  const { taskList, deleteAll: onDeleteAll } = useProspectTaskProgressContext();
  const { pathname } = useLocation();

  useEffect(() => {
    close();
  }, [pathname]);

  useEffect(() => {
    if (isAnotherOpened) return;

    const newOpen = !!taskList.length;
    if (newOpen) {
      open();
      show({ hideRest: true });
    } else {
      hide({ showPrevious: true });
    }
  }, [taskList.length, isAnotherOpened]);

  return (
    <CSSTransition
      in={isVisible}
      classNames="notificationBarOuterSlide"
      timeout={300}
      onExited={(): void => {
        onDeleteAll();
      }}
      unmountOnExit
      nodeRef={containerRef}
    >
      <CSSTransition
        in={isOpen}
        classNames="notificationBarInnerSlide"
        timeout={300}
        nodeRef={containerRef}
      >
        <Box className={styles.wrapper} ref={containerRef}>
          <ProspectTaskNotificationWidgetBody
            innerOpen={isOpen}
            onClose={(): void => {
              hide({ showPrevious: true });
            }}
            onToggleInner={(): void => {
              isOpen ? close() : open();
            }}
          />
        </Box>
      </CSSTransition>
    </CSSTransition>
  );
};
