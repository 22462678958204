import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, OutlinedInput, Typography } from '@mui/material';
import { clsx } from 'clsx';

import { ActionDisableGuard } from '@/features/common/permissions';
import type { IIntegrationEntity } from '@/features/integrations/domain';

import { useIntegrationRequestDialogViewModel } from './useIntegrationRequestDialogViewModel';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  integrationsList: IIntegrationEntity[];
  onClose(): void;
};

export const IntegrationRequestDialog: FC<Props> = ({
  integration,
  onClose,
  integrationsList,
}) => {
  const { t } = useTranslation(['integrations', 'common']);
  const { form, sendRequestAsyncExecutor } =
    useIntegrationRequestDialogViewModel(integration);
  const formIntegration = form.watch('title');

  return (
    <Box className={styles.dialog}>
      <Typography className={styles.dialogTitle}>{t('requestDialog.title')}</Typography>
      <Typography className={styles.dialogSubtitle}>
        {t('requestDialog.subtitle')}
      </Typography>
      <Grid className={styles.dialogIntegrationsList}>
        {integrationsList.map((item) => {
          return (
            <button
              key={item.id}
              onClick={(): void => {
                form.setValue('id', item.id);
                form.setValue('title', item.name);
              }}
              className={clsx(styles.dialogIntegrationButton, {
                [styles.selected]: item.name === formIntegration,
              })}
            >
              {t(item.name)}
            </button>
          );
        })}
      </Grid>
      <Typography className={styles.messageTitle}>
        {t('requestDialog.messageTitle')}{' '}
        <span>{t('requestDialog.messageTitleMandatority')}</span>
      </Typography>
      <OutlinedInput
        {...form.register('message')}
        multiline
        fullWidth
        minRows={1}
        maxRows={4}
        placeholder={t('requestDialog.messagePlaceholder')}
      />
      <Box className={styles.dialogButtons}>
        <Button color="info" onClick={onClose}>
          {t('requestDialog.cancelIntegrationButtonText')}
        </Button>
        <ActionDisableGuard>
          <Button
            onClick={async (): Promise<void> => {
              await sendRequestAsyncExecutor.asyncExecute();
              onClose();
            }}
            color="primary"
            variant="contained"
            disabled={sendRequestAsyncExecutor.isLoading}
          >
            {t('requestDialog.requestIntegrationButtonText')}
          </Button>
        </ActionDisableGuard>
      </Box>
    </Box>
  );
};
