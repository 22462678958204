import { inject, injectable } from 'inversify';

import { APP_CONFIG_TYPES } from '@/ioc/types';

import type { IHubspotFormSubmissionDC } from '../dataContracts';

import type { AppConfig } from '@/config';

export interface IHubspotApiService {
  sendClientSetupForm(formData: IHubspotFormSubmissionDC): Promise<Response>;
}

const HUBSPOT_FORM_SUBMISSION_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/';

@injectable()
export class HubspotApiService implements IHubspotApiService {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  sendClientSetupForm(data: IHubspotFormSubmissionDC): Promise<Response> {
    const { href } = new URL(
      this.appConfig.hubspot.accountRegisteredForm,
      HUBSPOT_FORM_SUBMISSION_URL,
    );

    return fetch(href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }
}
