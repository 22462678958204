import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { openInNewTab } from '@/utils/openInNewTab';

type useSuccessSubscriptionModalViewModelResult = {
  isOpened: boolean;
  onClose: () => void;
  onMembersInvite: () => void;
  onStartProspecting: () => void;
};

export function useSuccessSubscriptionModalViewModel(): useSuccessSubscriptionModalViewModelResult {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpened, setIsOpened] = useState(location.state?.justSubscribed);

  useEffect(() => {
    if (location.state?.justSubscribed) {
      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location.state, location.pathname, navigate]);

  function onClose(): void {
    setIsOpened(false);
  }

  function onMembersInvite(): void {
    onClose();
    navigate(ROUTES.SETTINGS.TEAM_MANAGEMENT, {
      state: { isInviteTeamMemberModalOpen: true },
    });
  }

  function onStartProspecting(): void {
    onClose();
    openInNewTab(EXTERNAL_ROUTES.LINKEDIN_URL);
  }

  return {
    isOpened,
    onClose,
    onMembersInvite,
    onStartProspecting,
  };
}
