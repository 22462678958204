import { inject, injectable } from 'inversify';
import { Userpilot } from 'userpilot';

import { APP_CONFIG_TYPES } from '@/ioc/types';

import type { IAccountEntity } from '@/features/common/account';

import type { IContextualOnboardingRepository } from '../domain';

import type { AppConfig } from '@/config';

@injectable()
export class ContextualOnboardingRepository implements IContextualOnboardingRepository {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  public init(): void {
    Userpilot.initialize(this.appConfig.userPilot.appToken);
  }

  public identify(account: IAccountEntity): void {
    Userpilot.identify(account.uuid, {
      name: account.fullName,
      email: account.email,
      created_at: account.createdAt,
      role: account.role,
    });
  }

  public reload(): void {
    Userpilot.reload();
  }

  public track(eventName: string, meta: Record<string, string>): void {
    Userpilot.track(eventName, meta);
  }
}
