import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Box } from '@mui/material';

import { EXTERNAL_ROUTES, ROUTES } from '@/router/routes';

import { Statistic } from '../Statistic';

import { usePlanOverviewViewModel } from './usePlanOverviewViewModel';

import styles from './styles.module.scss';

export const PlanOverview: FC = () => {
  const { t } = useTranslation('billingSettings', {
    keyPrefix: 'subscriptionOverview.statistics',
  });

  const { isLoading, name, isCustom, seats, lifecycle } = usePlanOverviewViewModel();

  return (
    <Box className={styles.wrapper}>
      {isLoading ? (
        <>
          <Box className={styles.item}>
            <Statistic.Preview />
          </Box>
          <Box className={styles.item}>
            <Statistic.Preview />
          </Box>
          <Box className={styles.item}>
            <Statistic.Preview />
          </Box>
        </>
      ) : (
        <>
          <Box className={styles.item}>
            <Statistic
              title={t(`currentPlan.title`)}
              value={name}
              link={
                isCustom ? (
                  <a href={EXTERNAL_ROUTES.CONTACT_US} target="_parent">
                    {t(`currentPlan.upggradePlan`)}
                  </a>
                ) : (
                  <Link to={ROUTES.BILLING.PLANS}>{t(`currentPlan.upggradePlan`)}</Link>
                )
              }
            />
          </Box>
          <Box className={styles.item}>
            <Statistic
              title={t(`seats.title`)}
              value={seats}
              link={
                isCustom ? (
                  <a href={EXTERNAL_ROUTES.CONTACT_US} target="_parent">
                    {t(`seats.addMoreSeats`)}
                  </a>
                ) : (
                  <Link to={ROUTES.BILLING.PLANS}>{t(`seats.addMoreSeats`)}</Link>
                )
              }
            />
          </Box>
          <Box className={styles.item}>
            <Statistic title={lifecycle.title} value={lifecycle.value} />
          </Box>
        </>
      )}
    </Box>
  );
};
