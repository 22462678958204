import { inject, injectable } from 'inversify';
import { map, Observable } from 'rxjs';

import { EXTENSION_TYPES } from '@/ioc/types';

import { IExtensionRepository } from '../data';

import { IExtensionUseCase } from './abstractions';
import { IExtensionMessageEntity } from './entities';

@injectable()
export class ExtensionUseCase implements IExtensionUseCase {
  @inject(EXTENSION_TYPES.ExtensionRepository)
  private extensionRepository: IExtensionRepository;

  isExtensionInstalled(): Observable<boolean> {
    return this.extensionRepository.isExtensionInstalled();
  }

  sendMessage(message: IExtensionMessageEntity, callback?: (res: unknown) => void): void {
    return this.extensionRepository.sendMessage(message, callback);
  }

  getExtensionInstallType(): Observable<
    Nullable<'development' | 'admin' | 'normal' | 'sideload'>
  > {
    return this.extensionRepository
      .getExtensionInfo()
      .pipe(
        map(
          (info) =>
            info?.installType as Nullable<
              'development' | 'admin' | 'normal' | 'sideload'
            >,
        ),
      );
  }
}
