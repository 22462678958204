import { inject, injectable } from 'inversify';
import Cookies, { type CookieSetOptions } from 'universal-cookie';

import { APP_CONFIG_TYPES } from '@/ioc/types';

import type { AppConfig } from '@/config';

export interface ICookiesManager {
  remove(name: string, options?: CookieSetOptions): void;
  get: <T>(name: string) => T;
  // TODO: we have to move this to a feature
  setSharableAuthIdentityCookie(): void;
  removeSharableAuthIdentityCookie(): void;
}

@injectable()
export class CookiesManager implements ICookiesManager {
  @inject(APP_CONFIG_TYPES.AppConfig)
  private readonly appConfig: AppConfig;

  private sharableAuthIdentityCookieName = 'pwAuthIdentity';

  private _cookies: Cookies;

  constructor() {
    this._cookies = new Cookies(null, {});
  }

  public remove(name: string, options?: CookieSetOptions): void {
    return this._cookies.remove(name, options);
  }

  public setSharableAuthIdentityCookie(): void {
    const expireDate = new Date();
    expireDate.setMonth(expireDate.getMonth() + 1);
    this._cookies.set(
      this.sharableAuthIdentityCookieName,
      JSON.stringify({
        domain: this.appConfig.marketingSite.url,
        auth: true,
      }),
      {
        domain: this.appConfig.domain,
        expires: expireDate,
      },
    );
  }

  public get<T>(name: string): T {
    return this._cookies.get(name);
  }

  public removeSharableAuthIdentityCookie(): void {
    this._cookies.remove(this.sharableAuthIdentityCookieName, {
      domain: this.appConfig.domain,
    });
  }
}
