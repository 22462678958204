import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Box } from '@mui/material';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { LazyLottie } from '@/components';

import imageRight from './assets/animationPreview.webp';
import avatarLGFG from './assets/LGFGAuthor.png';
import avatarMoblers from './assets/moblersAuthor.jpeg';
import QuoteIcon from './assets/quotes.svg?react';
import avatarRangeTeller from './assets/RangeTellerAuthor.png';
import avatarTalentege from './assets/talentedgeAuthor.webp';
import { Testimonial } from './components';

import 'swiper/css';
import styles from './styles.module.scss';

type CustomerFeedbackItem = {
  id: string;
  text: string;
  userAvatar: string;
  userName: string;
  userPosition: string;
};

const customerFeedbackItems: CustomerFeedbackItem[] = [
  {
    id: 'slide-0',
    text: 'the accuracy and affordability of <span>Powerlead</span> made it an indispensable part of our sales stack.',
    userAvatar: avatarRangeTeller,
    userName: 'Daniel Goldstone',
    userPosition: 'CEO RangeTeller',
  },
  {
    id: 'slide-1',
    text: '<span>Powerlead</span> has completely transformed our approach to global outreach',
    userAvatar: avatarMoblers,
    userName: 'Guy Doron',
    userPosition: 'CEO Moblers',
  },
  {
    id: 'slide-2',
    text: 'Integrating <span>Powerlead</span> into our data system has significantly enhanced our close rate',
    userAvatar: avatarTalentege,
    userName: 'Yael Barak Cohen',
    userPosition: 'CEO & Founder Talentedge',
  },
  {
    id: 'slide-3',
    text: 'Implementing <span>Powerlead</span> and the ability to access reliable and accurate data has been a game-changer for our client acquisition',
    userAvatar: avatarLGFG,
    userName: 'Dimitry Toukhcher',
    userPosition: 'CEO LGFG',
  },
];

const shuffleArray = (array: CustomerFeedbackItem[]): CustomerFeedbackItem[] => {
  const result = [...array];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};

export const RightContent: React.FC = () => {
  const swiperRef = useRef<SwiperClass | null>(null);
  const firstRender = useRef(true);

  const location = useLocation();

  const [slides] = useState(() => shuffleArray(customerFeedbackItems));

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    swiperRef.current?.slideNext();
  }, [location]);

  return (
    <Box className={styles.container}>
      <Box className={styles.imageWrapper}>
        <LazyLottie
          previewSrc={imageRight}
          dataSrc={'/animations/SignUp/animation.json'}
          autoplay={true}
          loop={false}
        />
      </Box>
      <Box className={styles.swiperWrapper}>
        <QuoteIcon />
        <Swiper
          onSwiper={(swiper: SwiperClass): void => {
            swiperRef.current = swiper;
          }}
          loop
        >
          {slides.map(({ id, ...item }) => {
            return (
              <SwiperSlide key={id}>
                <Testimonial {...item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};
