import { inject, injectable, postConstruct, unmanaged } from 'inversify';
import { clone, Paths, RxState, setProperty } from 'rxdb';
import { RxStateModifier, RxStateOperation } from 'rxdb/dist/types/plugins/state/types';
import { firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';

import { DB_TYPES } from '@/ioc/types';

import { IDbManager } from './DbManagerNew';

function mergeOperationsIntoState<T>(state: T, operations: RxStateOperation[]): void {
  for (let index = 0; index < operations.length; index++) {
    const operation = operations[index];
    setProperty(state, operation.k, clone(operation.v));
  }
}

type TypeAtPath<T, Path extends string> = Path extends `${infer First}.${infer Rest}`
  ? First extends keyof T
    ? TypeAtPath<T[First], Rest>
    : never
  : Path extends keyof T
    ? T[Path]
    : never;

export interface IDbState<State extends object> {
  get$<P extends Paths<State>>(key: P): Observable<TypeAtPath<State, P>>;
  set(key: Paths<State>, modifier: RxStateModifier): Promise<void>;
}

@injectable()
export class DbState<State extends object> implements IDbState<State> {
  private readonly _stateName: string;

  constructor(@unmanaged() params: { stateName: string }) {
    this._stateName = params.stateName;
  }

  @postConstruct()
  fixRxStateMultiTabSync(): void {
    this.state
      .pipe(
        switchMap((originalRxState) => {
          // to pass proxy set field protection
          const rxState = Object.assign({}, originalRxState);

          rxState.collection.$.pipe(
            tap((event) => {
              if (
                rxState._initDone &&
                event.operation === 'INSERT' &&
                event.documentData.sId !== rxState._instanceId
              ) {
                const updatedeState: typeof originalRxState._state = Object.assign(
                  {},
                  originalRxState._state,
                );
                mergeOperationsIntoState(updatedeState, event.documentData.ops);
                originalRxState._ownEmits$.next(updatedeState);
              }
            }),
          ).subscribe();

          return rxState.$;
        }),
      )
      .subscribe();
  }

  @inject(DB_TYPES.DbManager)
  private dbManager: IDbManager;

  protected get state(): Observable<RxState<State>> {
    return this.dbManager.getDb().pipe(
      map((db) => {
        if (!Reflect.has(db.states, this._stateName)) {
          throw new Error(`State ${this._stateName} not found`);
        }

        return db.states[this._stateName];
      }),
    );
  }

  get$<P extends Paths<State>>(key: P): Observable<TypeAtPath<State, P>> {
    return this.state.pipe(
      switchMap((state) => {
        return state.get$(key);
      }),
    );
  }

  set(key: Paths<State>, modifier: RxStateModifier): Promise<void> {
    return firstValueFrom(
      this.state.pipe(
        switchMap((state) => {
          return state.set(key, modifier);
        }),
      ),
    );
  }
}
