import { Children, cloneElement, FC, isValidElement, ReactElement } from 'react';
import { FormHelperText, FormLabel, InputAdornment } from '@mui/material';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const PaymentFormField: FC<{
  labelText?: string;
  errorText?: string;
  error?: boolean;
  errorPosition?: 'bottom' | 'end';
  required?: boolean;
  children: ReactElement;
  disabled?: boolean;
  name: string;
}> = ({
  labelText,
  children,
  required,
  error,
  errorText,
  disabled,
  name,
  errorPosition = 'end',
}) => {
  const errorElement =
    errorText && error && errorPosition === 'end' ? (
      <InputAdornment position="end">
        <FormHelperText error={error} disabled={disabled} sx={{ marginTop: 0 }}>
          {errorText}
        </FormHelperText>
      </InputAdornment>
    ) : null;

  return (
    <div className={styles.formField}>
      {labelText ? (
        <FormLabel required={required} className={styles.formFieldLabel} htmlFor={name}>
          {labelText}
        </FormLabel>
      ) : null}
      {Children.map(children, (child) => {
        if (
          isValidElement<{
            error?: boolean;
            required?: boolean;
            children: ReactElement;
            disabled?: boolean;
            name: string;
            className?: string;
            endAdornment?: ReactElement | null;
            size?: 'large';
          }>(child)
        ) {
          return cloneElement(
            child,
            {
              ...child.props,
              required,
              error,
              disabled,
              name,
              endAdornment: errorElement,
              className: clsx(styles.formFieldInput, child.props.className),
              size: 'large',
            },
            child.props.children,
          );
        }

        return child;
      })}
      {errorText && error && errorPosition === 'bottom' ? (
        <FormHelperText error={error} disabled={disabled} sx={{ marginTop: 0 }}>
          {errorText}
        </FormHelperText>
      ) : null}
    </div>
  );
};
