import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { CallToAction } from '@/features/callToAction';
import { ActionDisableGuard } from '@/features/common/permissions';
import type { IIntegrationEntity } from '@/features/integrations/domain';

import { LogoBase } from '@/components/Icons';

import IconClose from '@/assets/icons/iconClose.svg?react';
import IconArrows from '@/assets/icons/integrations/arrows.svg?react';

import styles from './styles.module.scss';

type Props = {
  integration: IIntegrationEntity;
  onClose(): void;
};

export const IntegrationUpgradeDialog: FC<Props> = ({ integration, onClose }) => {
  const { t } = useTranslation('integrations');

  return (
    <Box>
      <IconClose onClick={onClose} className={styles.closeIcon} />
      <Box className={styles.dialogIcons}>
        <Box className={styles.withBorder}>
          <LogoBase width="52" height="52" />
        </Box>
        <IconArrows />
        <Box className={styles.integrationIconWrapper}>
          <img
            src={integration.icon}
            alt={t(integration.name)}
            style={{ width: integration.id === 'salesforce' ? 65 : 52 }}
          />
        </Box>
      </Box>
      <Typography className={styles.dialogTitle}>{t('upgradeDialog.title')}</Typography>
      <Typography className={styles.dialogSubtitle}>
        {t('upgradeDialog.description')}
      </Typography>
      <ActionDisableGuard>
        <CallToAction
          withIcon={false}
          show="getCredits"
          textOverrides={{
            getCredits: t('upgradeDialog.submit'),
          }}
          variant="contained"
          className={styles.dialogButton}
        />
      </ActionDisableGuard>
    </Box>
  );
};
