import { inject, injectable } from 'inversify';
import {
  filter,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';

import { INTEGRATION_TYPES, SYNC_TYPES } from '@/ioc/types';

import type { IBaseSyncRepository } from '@/features/system/sync';

import {
  type IIntegrationApiService,
  type IIntegrationMapEntity,
  type IIntegrationRepository,
  type IIntegrationSettingsEntity,
  IntegrationExportEntitiesToProviderError,
} from '../domain';

import type { IIntegrationMapperDC, IIntegrationSettingsDC } from './dataContracts';
import type { IIntegrationExportState, IIntegrationState } from './db';
import {
  mapIntegrationMapDCtoEntity,
  mapIntegrationMapEntityToDC,
  mapIntegrationSettingsDCtoEntity,
} from './mappers';

@injectable()
export class IntegrationRepository implements IIntegrationRepository {
  @inject(INTEGRATION_TYPES.IntegrationApiService)
  private api: IIntegrationApiService;

  @inject(INTEGRATION_TYPES.IntegrationState)
  private state: IIntegrationState;

  @inject(INTEGRATION_TYPES.IntegrationExportState)
  private exportState: IIntegrationExportState;

  constructor(
    @inject(SYNC_TYPES.BaseSyncRepository)
    private readonly baseSyncRepository: IBaseSyncRepository,
  ) {
    this.baseSyncRepository.getIntegrationExportTaskEvents().subscribe((event) => {
      if (event.entity.status === 'pending') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'pending',
        });
      }

      if (event.entity.status === 'finished') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'finished',
          contactsNumber: Object.values(event.entity.result ?? {}).length,
        });
      }

      if (event.entity.status === 'failed') {
        this.exportState.setExportState(event.entity.uuid, {
          status: 'failed',
          reason: event.entity.error_desc ?? 'Unknown error',
        });
      }
    });
  }

  private async getRemoteMapping(
    provider: string,
    objectType: string,
  ): Promise<IIntegrationMapperDC> {
    const response = await firstValueFrom(this.api.getMapping(provider, objectType));
    await this.state.setMapping(provider, objectType, response);

    return response;
  }

  getMapping(provider: string, objectType: string): Observable<IIntegrationMapEntity> {
    return from(this.getRemoteMapping(provider, objectType)).pipe(
      switchMap(() => this.state.getMapping(provider, objectType)),
      filter((mapping): mapping is IIntegrationMapperDC => !!mapping),
      map(mapIntegrationMapDCtoEntity),
    );
  }

  async saveMapping(provider: string, objectType: string, data: []): Promise<void> {
    const dataDC = data.map(mapIntegrationMapEntityToDC);
    await firstValueFrom(this.api.saveMapping(provider, objectType, dataDC));
  }

  async updateSettings(provider: string, enabledObjects: string[]): Promise<void> {
    const settings = await firstValueFrom(
      this.api.updateSettings(provider, enabledObjects),
    );
    await this.state.setSettings(provider, settings);
  }

  async disconnectUser(provider: string): Promise<void> {
    await firstValueFrom(this.api.disconnectUser(provider));
  }

  async exportEntitiesToProvider(
    provider: string,
    ids: string[],
    entityType: 'contact',
  ): Promise<void> {
    return firstValueFrom(
      this.api.exportEntitiesToProvider(provider, ids, entityType).pipe(
        map(({ task_id }) => task_id),
        switchMap((taskId) => this.exportState.getExportState(taskId)),
        filter((state) => state.status !== 'pending'),
        switchMap((state) => {
          if (state.status === 'failed') {
            return throwError(
              () =>
                new IntegrationExportEntitiesToProviderError(
                  `Export ${entityType} entities to ${provider} error`,
                  {
                    cause: state.reason,
                  },
                ),
            );
          }

          return of(undefined);
        }),
      ),
    );
  }

  private async getRemoteSettings(provider: string): Promise<IIntegrationSettingsDC> {
    const response = await firstValueFrom(this.api.getSettings(provider));
    await this.state.setSettings(provider, response);

    return response;
  }

  private getLocalSettings(provider: string): Observable<IIntegrationSettingsEntity> {
    return this.state.getSettings(provider).pipe(
      filter((integration): integration is IIntegrationSettingsDC => !!integration),
      map(mapIntegrationSettingsDCtoEntity),
    );
  }

  getSettings(provider: string): Observable<IIntegrationSettingsEntity> {
    return from(this.getRemoteSettings(provider)).pipe(
      switchMap(() => this.getLocalSettings(provider)),
    );
  }
}
